import page from 'page';
import i18n from './i18n';
import * as hopscotch from 'hopscotch';

import { app } from './app';
import { session } from './session';

function waitForElementVisible(selector: string): Promise<void> {
  let limit = 5000;
  let interval = 100;

  return new Promise<void>((resolve, reject) => {
    let checkExist = setInterval(() => {
      limit -= interval;

      if (limit < 0) {
        clearInterval(checkExist);
        reject();
      }

      if ($(selector).is(':visible')) {
        clearInterval(checkExist);
        resolve();
      }
    }, interval);
  });
}

function nextAfter(tour: TourDefinition, promise: Promise<void>) {
  let currentStep = hopscotch.getCurrStepNum();

  promise.then(() => {
    hopscotch.startTour(tour, currentStep);
  });
}

function highlight(selector: string) {
  removeHighlight();
  $(selector).addClass('tour-highlight');
}

function removeHighlight() {
  $('.tour-highlight').removeClass('tour-highlight');
}

let tourId = 'onboarding-trials';

function makeTour() {
  let isMobile = $('#horizontal-nav-trials').is(':hidden');

  let tour = {
    id: tourId,
    showPrevButton: false,
    skipIfNoElement: true,
    onClose: () => {
      removeHighlight();
      app.showTourOverlay(false);
      localStorage.setItem(tour.id, 'closed');
    },
    onEnd: () => {
      removeHighlight();
      app.showTourOverlay(false);
      localStorage.setItem(tour.id, 'ended');
    },
    i18n: {
      skipBtn: i18n.t('Skip')(),
      nextBtn: i18n.t('Next')(),
      prevBtn: i18n.t('Back')(),
      doneBtn: i18n.t('Done')(),
    },
    bubbleWidth: isMobile ? 260 : 280,
    steps: [
      {
        title: i18n.t('Welcome to')() + ' ' + SERVER_INFO.PRODUCT_NAME + '!',
        content:
          i18n.t("Let's quickly go through some of the main features of the platform.")() +
          '<br/><br/>' +
          i18n.t(
            "Click Next to continue or close this dialog if you don't want to go through this introduction."
          )(),
        target: document.body,
        placement: 'bottom',
        xOffset: 'center',
        yOffset: 'center',
        onShow: () => {
          $('.hopscotch-bubble-arrow-container').hide();
        },
      },
      {
        title: i18n.t('Manage your trials')(),
        content:
          i18n.t('You can manage your trials by clicking on the trials menu.')() +
          '<br/><br/>' +
          i18n.t(
            'This is the main screen you will use as it allows you to setup a trial and see how many field observations have been collected.'
          )(),
        target: isMobile ? 'nav-menu' : 'horizontal-nav-trials',
        xOffset: isMobile ? -262 : 0,
        arrowOffset: isMobile ? 250 : 0,
        placement: 'bottom',
        onShow: () => {
          if (isMobile) {
            highlight('#nav-menu');
          } else {
            highlight('#horizontal-nav-trials');
          }
        },
      },
      {
        title: i18n.t('Existing trials')(),
        content:
          i18n.t('The list of trials appears here.')() +
          '<br/><br/>' +
          i18n.t(
            'There is one demo trial included to make it easier to see how things work. You can modify how a trial is defined by clicking on its name.'
          )(),
        target: 'first-trial-row-name',
        placement: 'top',
        onShow: () => {
          highlight('#first-trial-row-name');
        },
      },
      {
        title: i18n.t('Collected field observations')(),
        content: i18n.t(
          'These are the field observations already collected. Click on the number to view the built-in charts.'
        )(),
        target: 'first-trial-row-collected',
        placement: 'top',
        multipage: true,
        onShow: () => {
          highlight('#first-trial-row-collected');
        },
        onNext: () => {
          page($('#first-trial-row-collected a').attr('href'));

          nextAfter(tour, waitForElementVisible('#dashboard-title'));
        },
      },
      {
        title: i18n.t('Collected field observations')(),
        content: i18n.t(
          'The built-in charts can show the percentiles, the average and the standard deviation for the collected field observations.'
        )(),
        target: 'dashboard-title',
        placement: 'bottom',
        onShow: () => {
          highlight('.chart-group');
        },
        onNext: () => {
          localStorage.setItem(tour.id, 'ended');
          page(session.toTenantPath('/trials/'));
        },
      },
      {
        title: i18n.t('Main Menu')(),
        content: i18n.t(
          'Click here to access the main menu, where you can define crops, regions, units, etc.'
        )(),
        target: 'nav-menu',
        placement: isMobile ? 'bottom' : 'left',
        xOffset: isMobile ? -262 : 0,
        arrowOffset: isMobile ? 250 : 0,
        onShow: () => {
          highlight('#nav-menu');
        },
      },
      {
        title: i18n.t('Invite new users')(),
        content: i18n.t(
          'Here you can invite new persons to join your organization and conduct field trials.'
        )(),
        target: isMobile ? 'nav-menu' : 'horizontal-nav-invite',
        placement: 'bottom',
        xOffset: isMobile ? -262 : 0,
        arrowOffset: isMobile ? 250 : 0,
        onShow: () => {
          if (isMobile) {
            highlight('#nav-menu');
          } else {
            highlight('#horizontal-nav-invite');
          }
        },
      },
      {
        title: i18n.t('Congratulations!')(),
        content:
          i18n.t('You just completed the introduction.')() +
          '<br/>' +
          i18n.t('You can now define your first trial!')(),
        target: 'define-first-trial',
        placement: 'top',
        onShow: () => {
          highlight('#define-first-trial, #add-trial');
        },
      },
    ],
  };

  return tour;
}

export function startTour() {
  if (
    APP_CONFIG.SHOW_TOUR &&
    localStorage.getItem(tourId) === null &&
    session.tenant()?.role !== 'data_entry'
  ) {
    app.showTourOverlay(true);
    hopscotch.startTour(makeTour());
  }
}
