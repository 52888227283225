import * as ko from 'knockout';
import i18n from '../../i18n';

import * as dimensionMetasApi from '../../api/dimension_metas';
import { BaseTrialStep } from './base';
import { WizardController } from '../../screens/trial_wizard';
import { DatasetDimensionMeta } from '../../models/dataset_dimension_meta';
import { DimensionMeta } from '../../models/dimension_meta';
import { DatasetDimensionMetasEditConfiguration } from '../../components/dataset_dimension_metas_edit';
import { translate } from '../../i18n_text';
import { ImportEntitiesDelegate } from '../import_entities';
import { CustomLayoutData, downloadCustomLayoutImportTemplate } from '../../api/trials';
import { encodeArrayBufferToBase64 } from '../../utils';
import { confirmDialog } from '../confirm_dialog';
import { CUSTOM_LAYOUT_VALID_PLOT_DESIGNS } from '../../models/trial';
import { session } from '../../session';
import { allowViewLimitToDimensionNameAndAttributes, canReorderTrialLimitTo } from '../../permissions';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_test_subject.html').default;

class TrialTestSubject extends BaseTrialStep {
  title =
    SERVER_INFO.USE_FACTORS_NAMING ||
    session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
      ? i18n.t('Step 2 - Select the trial treatment factors')
      : i18n.t('Step 2 - Select the trial test subject');
  subtitle =
    SERVER_INFO.USE_FACTORS_NAMING ||
    session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
      ? i18n.t('Choose your treatment factors for this trial')
      : i18n.t('Choose your test subject for this trial');
  addMultipleText =
    SERVER_INFO.USE_FACTORS_NAMING ||
    session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
      ? i18n.t('Add multiple treatment factors.')
      : i18n.t('Add multiple test subjects.');
  removingControlNonControlText = i18n.t('Remove control:non-control combinations.');

  subjectTypes = [
    {
      name: i18n.t('Crop variety'),
      slug: dimensionMetasApi.CROP_VARIETY_SLUG,
      icon: require('../../../images/trial_wizard/crop_variety.png').default,
      question: i18n.t('Which crop varieties do you want to test?'),
    },
    {
      name: i18n.t('Fertilizer'),
      slug: dimensionMetasApi.FERTILIZER_SLUG,
      icon: require('../../../images/trial_wizard/fertilizer.png').default,
      question: i18n.t('Which fertilizers do you want to test?'),
    },
    {
      name: i18n.t('Chemical'),
      slug: dimensionMetasApi.CHEMICAL_SLUG,
      icon: require('../../../images/trial_wizard/chemical.png').default,
      question: i18n.t('Which chemicals do you want to test?'),
    },
    {
      name: i18n.t('Protocol / Treatment program'),
      slug: dimensionMetasApi.PROTOCOL_SLUG,
      icon: require('../../../images/trial_wizard/protocol.png').default,
      question: i18n.t('Which protocols do you want to test?'),
    },
    {
      name: i18n.t('Custom / Other'),
      slug: null,
      icon: require('../../../images/trial_wizard/custom.png').default,
      question: i18n.t('Which subjects do you want to test?'),
    },
  ];

  subjectTypeSlugs = [
    dimensionMetasApi.CROP_VARIETY_SLUG,
    dimensionMetasApi.FERTILIZER_SLUG,
    dimensionMetasApi.CHEMICAL_SLUG,
    dimensionMetasApi.PROTOCOL_SLUG,
  ];

  selectedSubjectTypes = ko.observableArray<KnockoutObservable<boolean>>(
    this.subjectTypes.map(() => ko.observable(false))
  );

  isTreatmentManagementEnabled = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial());

  // is multiple test subjects mode on?
  multipleSubjects = ko.observable<boolean>();

  // map of known dimension metas
  dimensionMetas = ko.observable<{ [key: string]: DimensionMeta }>({});

  customSubjectTypeBeingRemoved = ko.observable<boolean>(false);
  globalError = ko.observable('');
  stagesError = ko.observable('');

  testSubjectsConfig = ko.pureComputed(() => {
    let res: DatasetDimensionMetasEditConfiguration = {
      user: this.trialWizard().userData,

      addDMText: i18n.t('Add Subject Type')(),
      selectDMText: i18n.t('Select Subject Type')(),
      emptyDDMWarning: null,
      emptyDimensionsWarning: null,
      addDimensionText: (ddm: DatasetDimensionMeta) => {
        let dmName = ddm.dimensionMeta() ? translate(ddm.dimensionMeta().nameJson()) : i18n.t('Subject')();
        return i18n.t('Select a {{ dimension_meta }}', {
          dimension_meta: dmName.toLocaleLowerCase(),
        })();
      },

      canEditRequired: () => false,
      canEditDimensionMeta: (ddm: DatasetDimensionMeta) => !ddm.isPredefined() && this.allowEditAny(),
      trialWizard: this.trialWizard(),
      allowAnonymize: true,
      allowEditOptional: false,
      allowIncludeInFactorialCombinations: session.isTreatmentManagementEnabledForTrial(
        this.trialWizard().trial()
      ),
      multipleSubjectsOptionEnabled: this.multipleSubjects(),
      allowAdd: () => false,
      allowEdit: this.allowEdit,
      allowEditAny: this.allowEditAny,
      allowViewLimitToDimensionNameAndAttributes: allowViewLimitToDimensionNameAndAttributes(),
      allowRemove: (ddm: DatasetDimensionMeta) => {
        if (!this.allowEdit()) {
          return false;
        }

        return !ddm.isPredefined() && this.trialWizard().testSubjects().length > 1;
      },
      rankingDependsOnDDM: this.rankingDependsOnDDM,

      datasetDimensionMetas: this.trialWizard().testSubjects,
      createDatasetDimensionMeta: this.createDDM,

      confirmEditEntity: () => this.confirmTSChange(),
      confirmChangeEntities: () => this.confirmTSChange(),
      allowReorder: canReorderTrialLimitTo(this.trialWizard().userData, this.trialWizard().trial()),
    };

    return res;
  });

  showImportCustomLayout = ko.observable(false);
  importCustomLayoutDelegate: ImportEntitiesDelegate<CustomLayoutData> = {
    title: i18n.t('Import custom layout'),
    description: i18n.t(
      'Download the Excel template and upload the modified file containing your custom layout.'
    )(),
    backTitle: '',
    templateBaseName: 'layout',
    downloadTemplate: () => {
      const subjects = this.trialWizard().testSubjects();
      const controlIds: string[] = [];
      for (let ddm of subjects) {
        for (let dim of ddm.limitTo()) {
          if (dim.control()) {
            controlIds.push(dim.id());
          }
        }
      }

      return downloadCustomLayoutImportTemplate({
        dm_ids: subjects.map((ts) => ts.dimensionMeta()?.id()),
        control_dim_ids: controlIds,
        existing: this.trialWizard().plotGuides(),
        trial_id: this.trialWizard().trial().id(),
      });
    },
    importUrl: '/api/trials/import_custom_layout/',
    prepareFileContents: (fileContents) =>
      JSON.stringify({
        file_contents: encodeArrayBufferToBase64(fileContents),
        crop_id: this.trialWizard().trial().crop()?.id ?? null,
        dm_ids: this.trialWizard()
          .testSubjects()
          .map((ts) => ts.dimensionMeta()?.id()),
        trial_id: this.trialWizard().trial().id(),
      }),
    onSuccess: async (data) => {
      const errors = await this.trialWizard().applyCustomLayout(data);
      return errors.map((message) => ({ sheet: '', cell: '', message }));
    },
  };
  disableCustomLayoutActions = ko.pureComputed(() => {
    const subjects = this.trialWizard()?.testSubjects() ?? [];
    const plotDesign = this.trialWizard()?.trial()?.plotDesign();
    return !(
      this.allowEditAny() &&
      subjects.length > 0 &&
      subjects.every((ts) => !!ts.dimensionMeta()) &&
      CUSTOM_LAYOUT_VALID_PLOT_DESIGNS.indexOf(plotDesign) > -1
    );
  });

  private subscriptions: KnockoutSubscription[] = [];
  private isMultipleTestSubjectsChangedReentrant = false;

  constructor(params: { controller: WizardController }) {
    super(params);

    this.subscriptions.push(this.multipleSubjects.subscribe(this.onMultipleSubjectsChanged));
    this.subscriptions.push(this.trialWizard().testSubjects.subscribe(this.onTestSubjectsChanged));
    this.onTrialWizardChanged();
    this.subscriptions.push(this.trialWizard.subscribe(this.onTrialWizardChanged));

    // get predefined test subject dimension metas
    // OPT: possibly convert to a single API call
    Promise.all(this.subjectTypeSlugs.map((slug) => dimensionMetasApi.retrieve(slug))).then((dmDatas) => {
      dmDatas.forEach((d) => {
        this.dimensionMetas()[d.slug] = new DimensionMeta(d);
      });

      this.reload();
    });
  }

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
  }

  onTestSubjectsChanged = (newTestSubjects: any) => {
    if (newTestSubjects.length < 2) {
      this.trialWizard().excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects(false);
    }
  };

  reload() {
    let ddms = this.trialWizard().testSubjectsWithDimensionMeta();
    let slugs = ddms.map((ddm) => ddm.dimensionMeta().slug());

    if (ddms.length > 1) {
      this.multipleSubjects(true);
    }

    slugs.forEach((slug) => {
      let index = this.subjectTypeSlugs.indexOf(slug);
      index = index >= 0 ? index : this.subjectTypes.length - 1;

      this.selectedSubjectTypes()[index](true);
    });

    this.ready(true);
  }

  selectSubjectType = async (subjectType: { slug: string }) => {
    if (!this.allowEdit()) {
      return;
    }

    if (this.trialWizard().treatments().length > 0) {
      try {
        await confirmDialog(
          i18n.t('Changing test subjects')(),
          i18n.t(
            'You have already added treatments. Changing test subjects will remove all treatments. Are you sure you want to continue?'
          )()
        );
        this.trialWizard().treatments([]);
      } catch (e) {
        // The user rejected the dialog
        return;
      }
    }

    this.confirmTSChange().then(() => {
      this.doSelectSubjectType(subjectType);
    });
  };

  private async confirmTSChange(): Promise<{}> {
    let text =
      SERVER_INFO.USE_FACTORS_NAMING ||
      session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
        ? i18n.t(['treatment_factor_lowercase', 'treatment factors'])
        : i18n.t('test subjects');
    await this.trialWizard().confirmChangeAffectingPlots(text(), !!this.trialWizard().plotGuides());
    this.trialWizard().plotGuides(null);
    this.trialWizard().savePlotGuides = true;

    return null;
  }

  private doSelectSubjectType(subjectType: { slug: string }) {
    let curIdx = this.subjectTypes.map((s) => s.slug).indexOf(subjectType.slug);
    let curSlug = subjectType.slug;
    let curVal = this.selectedSubjectTypes()[curIdx]();
    let customCount = this.trialWizard()
      .testSubjects()
      .filter((ddm) => !ddm.isPredefined()).length;

    // removing multiple custom types?
    if (curIdx === this.subjectTypes.length - 1 && curVal && customCount > 1) {
      this.customSubjectTypeBeingRemoved(true);
      return;
    }

    let toRemove: number[] = [];

    if (!this.multipleSubjects()) {
      this.selectedSubjectTypes().forEach((obs, index) => {
        if (obs()) {
          toRemove.push(index);
        }
        obs(false);
      });
    } else if (curVal) {
      toRemove.push(curIdx);
    }

    this.selectedSubjectTypes()[curIdx](!curVal);

    toRemove.forEach((index) => {
      let slug = this.subjectTypes[index].slug;
      this.removeDDM(slug);
    });

    if (!curVal) {
      this.addDDM(curSlug);
    }
  }

  onMultipleSubjectsChanged = (newValue: boolean) => {
    if (!this.ready() || this.isMultipleTestSubjectsChangedReentrant) {
      return;
    }
    if (!newValue) {
      this.trialWizard().excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects(false);
    }
    // restore old value (which is equal to !newValue)
    this.isMultipleTestSubjectsChangedReentrant = true;
    this.multipleSubjects(!newValue);
    this.isMultipleTestSubjectsChangedReentrant = false;

    this.confirmTSChange().then(() => {
      // set new value again
      this.isMultipleTestSubjectsChangedReentrant = true;
      this.multipleSubjects(newValue);
      this.isMultipleTestSubjectsChangedReentrant = false;

      this.doChangeMultipleTestSubjects(newValue);
    });
  };

  private doChangeMultipleTestSubjects(newValue: boolean) {
    // remove all test subjects except one if multiple subjects switched off
    if (newValue) {
      return;
    }

    let selected: number[] = [];

    this.selectedSubjectTypes().forEach((obs, index) => {
      if (obs()) {
        selected.push(index);
      }
    });

    let toRemove = selected.slice(1);

    toRemove.forEach((index) => {
      let slug = this.subjectTypes[index].slug;

      this.selectedSubjectTypes()[index](false);
      this.removeDDM(slug);
    });
  }

  onTrialWizardChanged = () => {
    this.subscriptions.push(this.trialWizard().trial().crop.subscribe(this.onCropChanged));
  };

  onCropChanged = () => {
    for (let ddm of this.trialWizard().testSubjects()) {
      if (ddm.dimensionMeta() && ddm.dimensionMeta().slug() === dimensionMetasApi.CROP_VARIETY_SLUG) {
        ddm.limitTo([]);
      }
    }
    this.trialWizard().onTestSubjectsUpdated();
  };

  confirmCustomSubjectTypeRemove = () => {
    if (!this.customSubjectTypeBeingRemoved()) {
      return;
    }

    this.selectedSubjectTypes()[this.subjectTypes.length - 1](false);
    this.removeDDM(null);

    this.customSubjectTypeBeingRemoved(false);
  };

  cancelCustomSubjectTypeRemove = () => {
    this.customSubjectTypeBeingRemoved(false);
  };

  /**
   * Add another custom subject type.
   *
   * Handler for "Add another" custom subject type button.
   */
  addCustomSubjectType = () => {
    this.confirmTSChange().then(() => {
      this.selectedSubjectTypes()[this.subjectTypes.length - 1](true);
      this.addDDM(null);
    });
  };

  /**
   * Create empty dataset dimension meta to be used as a test subject.
   *
   * Low level method called from other components and higher level logic.
   *
   * @param slug  optional dimension meta slug to initialize ddm with one of
   *     known dimension metas
   * @param isPredefined  optional indicator for ddms that correspond to
   *     visual buttons outside of ddm table
   */
  private createDDM = (slug?: string, isPredefined?: boolean) => {
    let ddm = new DatasetDimensionMeta(this.trialWizard());
    ddm.useForPlot(true);

    if (slug) {
      ddm.dimensionMeta(this.dimensionMetas()[slug] || null);
    }
    if (isPredefined) {
      ddm.isPredefined(isPredefined);
    }

    return ddm;
  };

  /**
   * Add a test subject with given slug.
   *
   * @param slug  dimension meta slug for new DDM. null for custom/other subjects
   */
  private addDDM = (slug: string) => {
    let ddm = this.createDDM(slug, slug !== null);
    this.trialWizard().testSubjects.push(ddm);
    this.setOrder();
    this.globalError('');
  };

  /**
   * Remove test subjects with given slug.
   *
   * When dealing with custom subjects this will remove all of them
   *
   * @param slug  dimension meta slug for new DDM. null for custom/other subjects
   */
  private removeDDM = (slug: string) => {
    let removePredefined = slug !== null;

    this.trialWizard().testSubjects.remove((ddm) => {
      let ddmSlug = ddm.dimensionMeta() ? ddm.dimensionMeta().slug() : null;

      // removing by slug can only remove ddms added by the buttons
      if (removePredefined && ddmSlug === slug && ddm.isPredefined()) {
        return true;
      }

      // removing custom removes everything not added by specific buttons
      return !removePredefined && !ddm.isPredefined();
    });
    this.setOrder();
    this.trialWizard().onTestSubjectsUpdated();
  };

  private setOrder() {
    let testSubjects = this.trialWizard().testSubjects();
    for (let i = 0; i < testSubjects.length; i++) {
      testSubjects[i].order(i);
    }
  }

  /**
   * Check if given dataset dimension meta is used for ranking measurement
   * in master dataset.
   *
   * We never deal with measurements on this screen, so always return false
   */
  rankingDependsOnDDM = (ddm: DatasetDimensionMeta): boolean => {
    return false;
  };

  hasErrors() {
    for (let ddm of this.trialWizard().testSubjects()) {
      if (ddm.hasErrors()) {
        return true;
      }
    }

    return !!this.stagesError() || !this.isOneTestSubjectSelected();
  }

  applyLocalValidation(): boolean {
    this.globalError('');
    this.stagesError('');

    if (!this.isOneTestSubjectSelected()) {
      let text =
        SERVER_INFO.USE_FACTORS_NAMING ||
        session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
          ? i18n.t('Select one treatment factor to continue')
          : i18n.t('Select one test subject to continue');
      this.globalError(text());

      return false;
    }

    return true;
  }

  private isOneTestSubjectSelected() {
    return this.selectedSubjectTypes().filter((obs) => obs()).length > 0;
  }

  clearServerErrors() {
    for (let ddm of this.trialWizard().testSubjects()) {
      this.clearModelServerErrors(ddm);
    }
  }

  applyServerErrors(errors: any) {
    let stagesError = errors['test_subjects_stages'];
    if (stagesError) {
      this.stagesError(stagesError);
    }

    let testSubjectsErrors = errors['test_subjects'] || [];
    let testSubjects = this.trialWizard().testSubjects();

    for (let i = 0; i < testSubjectsErrors.length; i++) {
      let ddm = testSubjects[i];
      let dmError = testSubjectsErrors[i]['dimension_meta_id'];
      if (dmError) {
        this.setServerError(ddm.dimensionMeta, dmError);
      }
    }
  }

  openImportCustomLayout = async () => {
    if (this.disableCustomLayoutActions()) {
      return;
    }

    if (!this.trialWizard().canSafelyRegeneratePlots()) {
      let title = i18n.t('Changing plots')();
      let message = i18n.t(
        'You have customized the plots. After importing a new layout your customizations will be lost. Are you sure you want to continue?'
      )();

      await confirmDialog(title, message);
    }

    this.showImportCustomLayout(true);
  };

  closeImportCustomLayout = () => {
    this.showImportCustomLayout(false);
  };

  resetCustomLayout = async () => {
    if (this.disableCustomLayoutActions()) {
      return;
    }

    await this.confirmTSChange();
  };
}

ko.components.register('trial-test-subject', {
  viewModel: TrialTestSubject,
  template: template,
});
