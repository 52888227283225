import * as ko from 'knockout';

import { ListRequestParams, getContentRange } from '../api/request';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListLoaderDelegate, ListLoader, ItemSelectionTracker } from '../components/list_loader';
import { DimensionMeta, AttributeFilters } from '../models/dimension_meta';
import { CropVariety } from '../models/crop_variety';
import { CROP_VARIETY_SLUG } from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as cropVarietiesApi from '../api/crop_varieties';
import * as cropsApi from '../api/crops';
import * as usersApi from '../api/users';
import i18n from '../i18n';
import { downloadBlob, updateLocationWithQueryString, asArray } from '../utils';
import { serializeDateTime, deflateList } from '../api/serialization';
import { ListHeaderAction } from '../components/list_header';
import { FilterDelegate } from '../components/list_filters';
import { session } from '../session';
import { Action } from '../components/basic_widgets';
import { getArchiveAction } from './dimension_records';
import { canEditCropVariety } from '../permissions';
import { removeDialog } from '../components/remove_dialog';

let template = require('raw-loader!../../templates/crop_varieties.html').default;

const showBreedingInfoKey = 'show-breeding-info-v0';

interface DefaultFilters {
  name_prefix?: string;
  anonymized_code?: string;
  crop_ids?: string | string[];
}

type CropVarietiesFilters = DefaultFilters & AttributeFilters;

class CropVarietiesScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<cropVarietiesApi.CropVarietyData, CropVariety>
{
  canEdit = canEditCropVariety(session.tenant());
  canViewDetails = session.isFieldtrials();
  loader: ListLoader<cropVarietiesApi.CropVarietyData, CropVariety>;

  private nameFilter = ko.observable('');
  private anonymizedCodeFilter = ko.observable('').extend({ throttle: 300 });
  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>();
  private showBreedingInfoFilter = ko.observable(true);
  newFilters: FilterDelegate[] = [
    { title: i18n.t('Anonymized code')(), textValue: this.anonymizedCodeFilter },
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
  ];
  searchPlaceholder = i18n.t('Search varieties')();
  showBreedingInfo = ko.pureComputed(() => this.showBreedingInfoFilter());

  sortBys = ko.observableArray([ko.observable<string>('anonymized_code')]);
  sortingOptions = [
    { name: i18n.t('Anonymized code')(), value: 'anonymized_code' },
    { name: i18n.t('Name')(), value: 'name' },
  ];
  allowItemsPerLoadDisplay = true;
  allowEditFilters = true;

  dimensionMeta = ko.observable<DimensionMeta>(null);

  private canExport = ko.observable(false);
  exporting = ko.observable(false);

  listActions = ko.pureComputed<ListHeaderAction[]>(() => {
    let actions: ListHeaderAction[] = [];

    if (this.canEdit) {
      actions = [
        {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/crop_varieties/new/',
          tooltipTitle: i18n.t('Add crop variety')(),
        },
        {
          title: i18n.t('Customize')(),
          icon: 'settings',
          href: '/dimensions/crop_variety/',
        },
        {
          title: i18n.t('Import stages')(),
          icon: 'approval',
          href: '/dimensions/crop_variety/import_cv_stages/',
        },
        {
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: '/dimensions/crop_variety/import/',
          tooltipTitle: i18n.t('Import crop varieties')(),
        },
      ];
    }

    if (this.canExport()) {
      actions.push({
        title: i18n.t('Export')(),
        icon: 'file_download',
        onClick: this.downloadExport,
        loading: this.exporting,
      });
    }

    return actions;
  });

  selectionTracker: ItemSelectionTracker<CropVariety>;
  selectedIds = ko.pureComputed(() => this.selectionTracker.selectedItems().map((cv) => cv.id()));
  bulkRemoveTooltipTitle = i18n.t('Delete selected varieties')();

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: CropVarietiesFilters }) {
    super();

    const showBreedingInfoValue = localStorage.getItem(showBreedingInfoKey);
    this.showBreedingInfoFilter(showBreedingInfoValue === 'true' ? true : false);

    this.nameFilter(params.filters.name_prefix || '');
    // set afterwards, or we'd have to wait for throttle before updating initial value
    this.nameFilter = this.nameFilter.extend({ throttle: 300 });
    this.anonymizedCodeFilter(params.filters.anonymized_code || '');

    let cropIds = asArray(params.filters.crop_ids);
    let dmPromise = dimensionMetasApi.retrieve(CROP_VARIETY_SLUG);
    let cropPromise =
      cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : Promise.resolve<cropsApi.CropData[]>([]);
    let mePromise = usersApi.me();
    let promise = Promise.all([dmPromise, cropPromise, mePromise]).then(([dmData, cropData, me]) => {
      this.dimensionMeta(new DimensionMeta(dmData));
      this.newFilters.push(...this.dimensionMeta().getAttributeFilters(params.filters));

      if (cropData) {
        this.cropFilter(cropData);
      }

      this.canExport(me.role === 'admin');
    });
    this.loadedAfter(promise);

    this.subscriptions.push(this.showBreedingInfoFilter.subscribe(this.onShowBreedingInfoChanged));
  }

  onReady(loader: ListLoader<cropVarietiesApi.CropVarietyData, CropVariety>) {
    this.loader = loader;
    this.selectionTracker = new ItemSelectionTracker(this.loader.items);
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
    this.subscriptions.push(this.showBreedingInfoFilter.subscribe(() => this.loader.forceLoad()));
  }

  onRefresh(cropVarieties: CropVariety[]): void {
    const selectedIds = new Set(this.selectedIds());
    const updatedSelected = cropVarieties.filter((cv) => selectedIds.has(cv.id()));
    this.selectionTracker.selectedItems(updatedSelected);
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }

  private onShowBreedingInfoChanged = () => {
    localStorage.setItem(showBreedingInfoKey, this.showBreedingInfoFilter().toString());
  };

  fetch(params: ListRequestParams) {
    let filter = this.getFilters();
    updateLocationWithQueryString(filter);

    return cropVarietiesApi.list(
      {
        sort_by: this.sortBys()[0](),
        ...filter,
        ...params,
      },
      null,
      {
        injectTenant: true,
        onHeadersReceived: (headers) => {
          this.selectionTracker.total(getContentRange(headers).total);
        },
      }
    );
  }

  instantiate(cropVrietyData: cropVarietiesApi.CropVarietyData) {
    return new CropVariety(cropVrietyData, this.dimensionMeta());
  }

  remove(id: string) {
    return cropVarietiesApi.remove(id);
  }

  canRemove(cropVariety: CropVariety) {
    return this.canEdit;
  }

  confirmBulkRemove = () => {
    removeDialog(
      i18n.t(['crop_varieties_title', 'Crop Varieties'])().toLowerCase(),
      [],
      () =>
        cropVarietiesApi.bulkRemove({
          ...this.getFilters(),
          ...(this.selectionTracker.allSelected() ? {} : { ids: this.selectedIds() }),
          delete_limit: this.selectionTracker.selectedCount(),
        }),
      this.selectionTracker.selectedCount()
    )
      .then(() => {
        this.loader.refresh();
        this.selectionTracker.clear();
      })
      .catch(() => {});
  };

  downloadExport = () => {
    this.exporting(true);

    cropVarietiesApi
      .downloadExport(this.getFilters())
      .then((data) => {
        this.exporting(false);
        downloadBlob(data, 'crop-varieties-' + serializeDateTime(new Date()) + '.xlsx');
      })
      .catch(() => {
        this.exporting(false);
      });
  };

  private getFilters() {
    return {
      name_prefix: this.nameFilter(),
      anonymized_code: this.anonymizedCodeFilter(),
      crop_ids: deflateList(this.cropFilter),
      ...this.dimensionMeta().getAttributeFilterValuesBySlug(),
    };
  }

  getActions(cv: CropVariety): Action[] {
    return getArchiveAction(cv, this);
  }
}

export let cropVarieties = {
  name: 'crop-varieties',
  viewModel: CropVarietiesScreen,
  template: template,
};

ko.components.register(cropVarieties.name, cropVarieties);
