import i18n from './i18n';
import { session } from './session';
import { NavGroup, NavItem } from './components/navigation_bar';
import {
  canEditTraitCategory,
  canViewTPP,
  canViewCropVariety,
  canViewBreeder,
  canEditUsers,
  canViewOrganizations,
} from './permissions';

export function getMenu() {
  let tenant = session.tenant();
  let isDataEntry = tenant.role === 'data_entry';
  let groups: NavGroup[] = [];
  let addItem = (item: NavItem) => {
    groups.push(new NavGroup('', [item]));
  };

  if (canEditUsers() || session.isReadOnlyAdmin()) {
    addItem({
      title: i18n.t('Users')(),
      icon: 'person',
      href: '/users/',
      important: false,
    });
    if (tenant.tpp_enabled) {
      addItem({
        title: i18n.t('TPP')(),
        icon: 'center_focus_strong',
        href: '/tpps/',
        important: true,
      });
    }
  }
  if (isDataEntry) {
    addItem({
      title: i18n.t('Trials')(),
      icon: 'assignment',
      href: '/data_entry/trials/',
      important: true,
    });
  } else {
    if (session.isAdmin() || !SERVER_INFO.SIMPLE_PERMISSIONS) {
      addItem({
        title: i18n.t('Groups (Users)')(),
        icon: 'security',
        href: '/groups/',
      });
    }
    addItem({
      title: i18n.t('Trials')(),
      icon: 'assignment',
      href: '/trials/',
      important: true,
      htmlId: 'horizontal-nav-trials',
    });
    addItem({
      title: i18n.t('Templates')(),
      icon: 'space_dashboard',
      href: '/trial_templates/',
    });
    if (!session.isRestrictedManager()) {
      addItem({
        title: i18n.t('Document Tags')(),
        icon: 'article',
        href: '/document_tags/',
      });
    }
    const traitsLibraryItems = [
      {
        title: i18n.t('Scheduled visits')(),
        icon: 'schedule',
        href: '/scheduled_visit_library/',
      },
      {
        title: i18n.t('Traits')(),
        icon: 'library_books',
        href: '/traits_library/',
      },
      {
        title: i18n.t('Man. Traits')(),
        icon: 'library_books',
        href: '/management_traits_library/',
      },
    ];
    if (!tenant.prefer_embedded_mt) {
      traitsLibraryItems.push({
        title: i18n.t('Measurement Types')(),
        icon: 'list',
        href: '/measurement_types/',
      });
    }
    if (canEditTraitCategory(tenant)) {
      traitsLibraryItems.push({
        title: i18n.t('Trait Categories')(),
        icon: 'category',
        href: '/trait_categories/',
      });
    }
    traitsLibraryItems.push({
      title: i18n.t(['trait_tags_title', 'Trait Tags'])(),
      icon: 'tag',
      href: '/trait_tags/',
    });
    if (!session.isRestrictedManager()) {
      groups.push(new NavGroup(i18n.t(['traits_library_title', 'Traits Library'])(), traitsLibraryItems));
      groups.push(
        new NavGroup(i18n.t('Dimensions Library')(), [
          {
            title: i18n.t('Dimensions')(),
            icon: 'description',
            href: '/dimensions/',
          },
          {
            title: i18n.t('Attribute Choices')(),
            icon: 'list',
            href: '/attribute_choice_lists/',
          },
        ])
      );
    }

    if (!session.isRestrictedManager()) {
      groups.push(
        new NavGroup(i18n.t('Countries, Regions')(), [
          { title: i18n.t('Countries')(), icon: 'language', href: '/countries/' },
          {
            title: i18n.t(['administrative_regions_title', 'Administrative Regions'])(),
            icon: 'terrain',
            href: '/regions/',
          },
          {
            title: i18n.t(['agroclimatic_regions_title', 'Agroclimatic Regions'])(),
            icon: 'terrain',
            href: '/agro_regions/',
          },
          { title: i18n.t('Sites')(), icon: 'location_on', href: '/sites/' },
        ])
      );
      let cropItems: NavItem[] = [
        { title: i18n.t('Crops')(), icon: 'local_florist', href: '/crops/' },
        { title: i18n.t('Stages')(), icon: 'approval', href: '/stages/' },
      ];
      if (canViewCropVariety(tenant)) {
        cropItems = cropItems.concat([
          {
            title: i18n.t(['crop_varieties_title', 'Crop Varieties'])(),
            icon: 'grain',
            href: '/crop_varieties/',
          },
          {
            title: i18n.t('Crop Categories')(),
            icon: 'category',
            href: '/crop_categories/',
          },
          {
            title: i18n.t('OPV/Hybrid')(),
            icon: 'local_florist',
            href: '/crop_variety_types/',
          },
        ]);
      }
      groups.push(new NavGroup(i18n.t('Crop')(), cropItems));
      if (APP_CONFIG.CUSTOM_VARIETY_REQUEST_URL) {
        addItem({
          title: i18n.t('Variety Entry Request')(),
          icon: 'grain',
          href: '/crop_varieties_request/',
        });
      }
      addItem({
        title: i18n.t('Seasons')(),
        icon: 'calendar_today',
        href: '/seasons/',
      });
    }

    let orgItems = [
      { title: i18n.t('Customers')(), icon: 'receipt', href: '/customers/' },
      { title: i18n.t('Partners')(), icon: 'group_work', href: '/partners/' },
      {
        title: i18n.t('Organization Types')(),
        icon: 'business',
        href: '/organization_types/',
      },
    ];
    if (canViewBreeder(tenant)) {
      orgItems.splice(1, 0, {
        title: i18n.t('Breeders')(),
        icon: 'assignment',
        href: '/breeders/',
      });
    }
    if (canViewOrganizations()) {
      groups.push(new NavGroup(i18n.t('Organizations')(), orgItems));
    }
    if (APP_CONFIG.SHOW_REGISTRATIONS) {
      groups.push(
        new NavGroup(i18n.t('Registrations')(), [
          {
            title: i18n.t('Registration Regions')(),
            icon: 'location_city',
            href: '/registration_regions/',
          },
          {
            title: i18n.t('Registration Phases')(),
            icon: 'format_list_numbered',
            href: '/registration_phases/',
          },
          {
            title: i18n.t('Registrations')(),
            icon: 'card_membership',
            href: '/registrations/',
          },
        ])
      );
    }
    if (!session.isRestrictedManager()) {
      addItem({
        title: i18n.t('Dashboard')(),
        icon: 'show_chart',
        href: '/overview/',
      });
    }
    if (session.tenant().custom_reports_enabled) {
        addItem({
          title: i18n.t('Custom Reports')(),
          icon: 'insights',
          href: '/custom_reports/',
        });
    }

    if (canViewTPP()) {
      const tppItems = [
        { title: i18n.t('Programs')(), icon: 'event', href: '/projects/' },
        {
          title: i18n.t(['tpp_title', 'Target Product Profiles'])(),
          icon: 'center_focus_strong',
          href: '/tpps/',
        },
        {
          title: i18n.t('Market Segments')(),
          icon: 'shop_two',
          href: '/market_segments/',
        },
      ];

      if (canEditTraitCategory(tenant)) {
        tppItems.splice(
          1,
          0,
          {
            title: i18n.t('Client Types')(),
            icon: 'supervised_user_circle',
            href: '/client_types/',
          },
          {
            title: i18n.t('Drivers')(),
            icon: 'stars',
            href: '/drivers/',
          }
        );
      }

      groups.push(new NavGroup(i18n.t('Programs, TPP')(), tppItems));
    }
  }

  const settingsItems: NavItem[] = [];
  if (!isDataEntry && !session.isRestrictedManager()) {
    if (SERVER_INFO.ALLOW_SIGNUP) {
      settingsItems.push({
        title: i18n.t('Subscription')(),
        icon: 'payment',
        href: '/subscription/',
      });
    }
    settingsItems.push({
      title: i18n.t('Units')(),
      icon: 'straighten',
      href: '/units/',
    });
    settingsItems.push({
      title: i18n.t('Others')(),
      icon: 'settings',
      href: '/settings/',
    });
  } else {
    settingsItems.push({
      title: i18n.t('Settings')(),
      icon: 'settings',
      href: '/settings/',
    });
  }

  groups.push(new NavGroup(i18n.t('Settings')(), settingsItems));

  return groups;
}
