import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams, getContentRange } from '../api/request';
import { ListLoaderDelegate, ListLoader, ItemSelectionTracker } from '../components/list_loader';
import { BaseLoadingScreen } from './base_loading_screen';
import { Dimension } from '../models/dimension';
import { AttributeFilters, DimensionMeta } from '../models/dimension_meta';
import * as cropsApi from '../api/crops';
import * as dimensionsApi from '../api/dimensions';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as usersApi from '../api/users';
import { translate } from '../i18n_text';
import { canEditDimension } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import { Action } from '../components/basic_widgets';
import { confirmDialog } from '../components/confirm_dialog';
import { MaybeKO } from '../utils/ko_utils';
import { asArray, updateLocationWithQueryString } from '../utils';
import { FilterDelegate } from '../components/list_filters';
import { deflateList } from '../api/serialization';
import { removeDialog } from '../components/remove_dialog';

let template = require('raw-loader!../../templates/dimension_records.html').default;

interface DefaultFilters {
  name_search?: string;
  anonymized_code?: string;
  crop_ids?: string | string[];
}

type DimensionRecordsFilters = DefaultFilters & AttributeFilters;

class DimensionRecordsScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<dimensionsApi.DimensionData, Dimension>
{
  private dimensionMetaId: string;
  loader: ListLoader<dimensionsApi.DimensionData, Dimension>;

  dimensionMeta = ko.observable<DimensionMeta>(null);

  name = ko.pureComputed(() => {
    if (!this.dimensionMeta()) {
      return '';
    }

    return translate(this.dimensionMeta().nameJson());
  });
  title = ko.pureComputed(() => i18n.t('{{ element_name }} dimension elements', { element_name: this.name() })());

  dimensionMetaEditURL: string;

  canEdit = false;

  listActions = ko.observableArray<ListHeaderAction>([]);

  private nameFilter = ko.observable('').extend({ throttle: 300 });
  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>();
  private anonymizedCodeFilter = ko.observable('').extend({ throttle: 300 });
  searchPlaceholder = i18n.t('Search elements')();

  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Anonymized code')(),
      textValue: this.anonymizedCodeFilter,
    },
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
  ];

  allowItemsPerLoadDisplay = true;
  allowEditFilters = true;

  selectionTracker: ItemSelectionTracker<Dimension>;
  selectedIds = ko.pureComputed(() => this.selectionTracker.selectedItems().map((d) => d.id()));
  bulkRemoveTooltipTitle = i18n.t('Delete selected elements')();

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { dimensionMetaId: string; filters: DimensionRecordsFilters }) {
    super();

    this.dimensionMetaId = params.dimensionMetaId;
    this.nameFilter(params.filters.name_search || '');
    this.anonymizedCodeFilter(params.filters.anonymized_code || '');

    let promise = Promise.all([
      usersApi.me(),
      this.getCropsPromise(asArray(params.filters.crop_ids)),
      dimensionMetasApi.retrieve(params.dimensionMetaId),
    ]).then(([_, cropData, dimensionMetaData]) => {
      this.canEdit = canEditDimension();
      this.dimensionMeta(new DimensionMeta(dimensionMetaData));
      this.newFilters.push(...this.dimensionMeta().getAttributeFilters(params.filters));

      if (cropData) {
        this.cropFilter(cropData);
      }

      if (this.canEdit) {
        this.listActions([
          {
            title: i18n.t('Add')(),
            icon: 'add_circle',
            href: '/dimensions/' + this.dimensionMetaId + '/elements/new/',
            tooltipTitle: i18n.t('Add dimension element')(),
          },
          {
            title: i18n.t('Customize')(),
            icon: 'settings',
            href: '/dimensions/' + this.dimensionMetaId + '/',
          },
          {
            title: i18n.t('Import')(),
            icon: 'file_upload',
            href: '/dimensions/' + this.dimensionMetaId + '/import/',
            tooltipTitle: i18n.t('Import elements')(),
          },
        ]);
      }
    });
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<dimensionsApi.DimensionData, Dimension>) {
    this.loader = loader;
    this.selectionTracker = new ItemSelectionTracker(this.loader.items);
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
  }

  onRefresh(dimensions: Dimension[]): void {
    const selectedIds = new Set(this.selectedIds());
    const updatedSelected = dimensions.filter((d) => selectedIds.has(d.id()));
    this.selectionTracker.selectedItems(updatedSelected);
  }

  fetch(params: ListRequestParams) {
    const filters = this.getFilters();
    updateLocationWithQueryString(filters);
    return dimensionsApi.advancedSearch(
      this.dimensionMetaId,
      { ...params, ...filters },
      {
        injectTenant: true,
        onHeadersReceived: (headers) => {
          this.selectionTracker.total(getContentRange(headers).total);
        },
      }
    );
  }

  instantiate(dimensionData: dimensionsApi.DimensionData) {
    return new Dimension(dimensionData, this.dimensionMeta());
  }

  remove(id: string) {
    return dimensionsApi.remove(this.dimensionMetaId, id);
  }

  canRemove(dimension: Dimension) {
    return this.canEdit;
  }

  confirmBulkRemove = () => {
    removeDialog(
      this.title(),
      [],
      () =>
        dimensionsApi.bulkRemove(this.dimensionMetaId, {
          ...this.getFilters(),
          ...(this.selectionTracker.allSelected() ? {} : { ids: this.selectedIds() }),
          delete_limit: this.selectionTracker.selectedCount(),
        }),
      this.selectionTracker.selectedCount()
    )
      .then(() => {
        this.loader.refresh();
        this.selectionTracker.clear();
      })
      .catch(() => {});
  };

  getActions(dimension: Dimension): Action[] {
    return getArchiveAction(dimension, this);
  }

  private getFilters() {
    return {
      name_search: this.nameFilter(),
      crop_ids: deflateList(this.cropFilter),
      anonymized_code: this.anonymizedCodeFilter(),
      ...this.dimensionMeta().getAttributeFilterValuesBySlug(),
    };
  }

  private getCropsPromise = (cropIds: string[]) => {
    return cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : Promise.resolve<cropsApi.CropData[]>([]);
  };

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let dimensionRecords = {
  name: 'dimensions',
  viewModel: DimensionRecordsScreen,
  template: template,
};

ko.components.register(dimensionRecords.name, dimensionRecords);

export function getArchiveAction(
  record: { id: MaybeKO<string> },
  screen: ListLoaderDelegate<{}, {}> & { loader: ListLoader<{}, {}> }
): Action[] {
  if (!screen.canRemove(record)) {
    return [];
  }

  let archive = () => {
    dimensionsApi.archive(ko.unwrap(record.id)).then(() => {
      if (screen.loader) {
        screen.loader.refresh();
      }
    });
  };

  return [
    {
      icon: 'archive',
      title: i18n.t('Archive')(),
      cssClass: '',
      onClick: () => {
        confirmDialog(i18n.t('Archive record?')(), [
          i18n.t('Archived elements will no longer be visible or selectable for new trials.')(),
          i18n.t('Are you sure you want to continue?')(),
        ]).then(archive);
      },
    },
  ];
}
