import {
  request,
  requestAnyWithValidation,
  ValidationResult,
  ListRequestParams,
  listRequest,
  listParamsToQueryString,
  requestRaw,
  requestWithGetParams,
} from './request';
import { ValueMetaData } from './value_meta_data';
import { DimensionMetaData } from './dimension_metas';
import { DimensionData } from './dimensions';
import { MeasurementTypeData, MeasurementChoiceData } from './measurement_types';
import { UnitData } from './units';
import { WalkOrder, StartingCorner, TreatmentData, PlotNaming, PlotGuidesData, RangeDirection } from './trials';
import { I18nText } from '../i18n_text';
import { TraitCategoryData } from './trait_categories';
import { CropData } from './crops';
import { NameI18nData } from './names';
import { MeasurementMetaTagData } from './measurement_meta_tags';

export interface LimitToDimensionData extends DimensionData {
  control: boolean;
  disabled: boolean;
  optional: boolean;
  name_override: string;
  include_in_treatment_factorial_combinations: boolean;
}

export interface MMLimitToDimensionData extends DimensionData {
  order: number;
}

export interface DatasetDimensionMetaData {
  id?: string;
  dimension_meta_id: DimensionMetaData;
  order: number;
  limit_to: LimitToDimensionData[];
  visibility: string;
  use_for_plot: boolean;
  required: boolean;
  allow_create: boolean;
}

export interface CompressedPlotData {
  id: string[];
  name: string[];
  custom_name: boolean[];
  treatment_id: Array<number | null>;
  external_id: string[];
  number: number[];
  length: number[];
  internal_number: number[];
  excluded: boolean[];
  column_position: number[];
  range_position: number[];
  reference_dim_id: string[];
  dimensions_index: number[][];
}

export interface PlotListData {
  dms: { id: string; name: string }[];
  dims: { id: string; name: string }[][];
  treatments: { id: string; name: string}[];
  plots: CompressedPlotData;
}

export const EMPTY_PLOT_LIST_DATA: PlotListData = {
  dms: [],
  dims: [],
  treatments: [],
  plots: {
    id: [],
    name: [],
    custom_name: [],
    external_id: [],
    treatment_id: [],
    number: [],
    length: [],
    internal_number: [],
    excluded: [],
    reference_dim_id: [],
    dimensions_index: [],
    column_position: [],
    range_position: [],
  },
};

export interface MeasurementMetaData extends ValueMetaData {
  measurement_type: MeasurementTypeData;
  mt_rating: boolean;
  mt_choices: MeasurementChoiceData[];
  ranking_dimension_meta_id: DimensionMetaData;
  unit: UnitData;
  help_text_json: I18nText;
  formula: string;
  unit_formula: string;
  sync_to_mobile: boolean;
  calculate_on_mobile: boolean;
  dimension_meta_id: DimensionMetaData;
  limit_to: MMLimitToDimensionData[];
  allow_create_dimension: boolean;
  auto_fill: boolean;
  include_in_summary: boolean;
  use_for_planting_date: boolean;
  exclude_from_app: boolean;
  disable_gps_precision_check: boolean;
  pictures: MeasurementMetaPictureData[];
  trait_category: TraitCategoryData;
  template_id: string;
  trial_id?: string;
  // set on the client and write-only to the server,
  // used when copying from a template to map the mms referenced by charts
  // to newly saved mms (which have null id)
  copied_from_id: string;
  // only for templates:
  crops?: CropData[];
  description?: string;
  observation_name?: I18nText;
  aggregation?: string;
  normalize?: boolean;
  deleted?: boolean;
  normalization_mm?: MeasurementMetaData;
  management?: boolean;
  trait_unit_num?: UnitData;
  trait_unit_den?: UnitData;
  collect_for_site?: boolean;
  collect_for_replication?: boolean;
  collect_for_test_subjects?: boolean;
  optional?: boolean;
  mdms?: MeasurementMetaDimensionMetaData[];
  scheduled_visits?: NameI18nData[];
  data_collection_details?: string; // read-only, only for library
  tags?: MeasurementMetaTagData[];
  trial_limit_to: string[];
}

export interface MeasurementMetaPictureData {
  id?: string;
  description_json: I18nText;
  file_name: string;
  file_public_url?: string; // read-only
}

export interface MeasurementMetaDimensionMetaData {
  id: string;
  dimension_meta: DimensionMetaData;
  mdm_limit_to: { dimension: DimensionData; optional: boolean }[];
  order: number;
}

export interface DatasetAttribute {
  title: string;
  value: string;
  sortable: boolean;
  default_sort: number; // -1: don't sort, >= 0 sort in the order specified by the number
}

export interface DatasetSummaryData {
  id?: string;
  name_json: I18nText;
  image_resolution_width: number;
  image_resolution_height: number;
  order: number;
  skip_dimension_selection: boolean;
  force_site_selection: boolean;
  can_add_fact: boolean;
  contains_management_data: boolean;
  has_plots: boolean;
  has_visits: boolean;
  has_reference_dim: boolean;
  has_sites: boolean;
  has_crop_varieties: boolean;
  ts_dm_ids: string[];
  attributes: DatasetAttribute[];
}

export interface TrialDatasetData {
  id: string; // dataset id
  trial_id: string;
  name: string; // trial and dataset names combined
}

export function list(trialId: string, options?: { managementOnly: boolean }): Promise<DatasetSummaryData[]> {
  let reqOptions = { management_only: options && options.managementOnly };
  return request('GET', '/api/trials/' + trialId + '/datasets/?' + listParamsToQueryString(reqOptions));
}

export function retrieve(trialId: string, dsId: string): Promise<DatasetSummaryData> {
  return request('GET', `/api/trials/${trialId}/datasets/${dsId}/`);
}

export function forTrait(trialId: string, measurementMetaId: string): Promise<DatasetSummaryData> {
  return request(
    'GET',
    '/api/trials/' + trialId + '/datasets/for_trait/?measurement_meta_id=' + measurementMetaId
  );
}

export function listWithTrials(params: ListRequestParams & { trial?: string }): Promise<TrialDatasetData[]> {
  return listRequest('/api/trials/0/datasets/all/', params);
}

// the returned DimensionMetaData include attribute_metas data
export function listDimensionMetas(trialId: string, datasetId: string): Promise<DimensionMetaData[]> {
  return request(
    'GET',
    '/api/trials/' + (trialId || 0) + '/datasets/' + (datasetId || 0) + '/dimension_metas/'
  );
}

export function listDataEntryPermDms(trialId: string, datasetId: string): Promise<DimensionMetaData[]> {
  return request('GET', '/api/trials/' + trialId + '/datasets/' + datasetId + '/data_entry_perm_dms/');
}

export type RCBType = 'rcb_vertical' | 'rcb_horizontal' | 'rcb_rect';

export interface PlotGenerationRequestData {
  trial_id: string;
  preserve_existing: boolean;
  plot_naming: PlotNaming;
  range_direction: RangeDirection;
  plot_design: string;
  rcb_type: RCBType;
  row_length: number | string;
  rcb_block_width: number | string;
  rcb_blocks_per_row: number | string;
  al_block_size: number | string;
  al_replications_per_row: number | string;
  al_blocks_per_replication_row: number | string;
  al_plots_per_block_row: number | string;
  separate_test_subjects: boolean;
  starting_corner: StartingCorner;
  walk_order: WalkOrder;
  crop_id: string;
  randomization_seed: number;
  custom_plot_numbers: boolean;
  custom_plot_position: boolean;
  rcb_replications?: number;
  sp_main_dm_id: string;
  sp_rep_blocks_per_row: number;
  plot_guides: PlotGuidesData;
  treatments?: TreatmentData[];
  existing_plots: PlotListData;
  dataset_dimension_metas: DatasetDimensionMetaData[];
  sites: string[];
  exclude_from_generation_combinations_of_control_and_non_control_test_subjects: boolean;
}

export interface PlotGenerationData extends ValidationResult {
  custom_plot_numbers: boolean;
  custom_plot_position: boolean;
  plots: PlotListData;
  separate_timeout: boolean;
}

export function generatePlots(requestData: PlotGenerationRequestData): Promise<PlotGenerationData> {
  return requestAnyWithValidation('POST', '/api/trials/0/datasets/generate_plots/', requestData).then(
    (res) => {
      if (res.status == 200) {
        return { isValid: true, entityId: null, errors: {}, ...res.result };
      } else if (res.status == 400) {
        return { isValid: false, entityId: null, errors: res.result };
      } else {
        return Promise.reject(null);
      }
    }
  );
}

export interface PlotImportData {
  plot_design: string;
  replications: number;
  plots: PlotListData;
  dataset_dimension_metas: DatasetDimensionMetaData[];
  treatments: TreatmentData[];
  sites: string[];
  trial_id: number;
}

export function downloadPlotsLayoutImportTemplate(data: PlotImportData): Promise<Blob> {
  return requestRaw('POST', '/api/trials/0/datasets/plots_layout_import_template/', data);
}

export interface ImportedPlotLayout {
  [id: string]: {
    name: string;
    column_position: number;
    range_position: number;
  };
}

export interface GeoJSON {
  type: 'Feature';
  geometry: {
    type: 'Point' | 'Polygon';
    coordinates: number[] | number[][][];
  };
  properties: {
    area?: number;
    title?: string;
    info?: { title: string; value: string }[];
    lazy_load_url?: string;
    iconType?: 'staff' | 'site' | 'observation';
    fillColor?: string;
    id?: string;
  };
  metaData?: { [key: string]: any };
}

export interface DatasetFactSummaryData {
  id: string;
  is_ranking: boolean;
  location_lat: number;
  location_lon: number;
  location_horizontal_accuracy: number;
  location_valid_at: string;
  visit_name: string;
  visit_date: string;
  plot_name: string;
  reference_dim_name: string;
  values: DatasetFactValue[];
  extras: DatasetFileValue[];
  timestamp: string;
  user_name: string;
  measurement_meta_id?: string;
  scheduled_visit_id?: number;
  scheduled_visit_name?: string;
  user_id?: number;
}

export interface TrialSiteSummaryData {
  id: string;
  name: string;
  site_area: GeoJSON | null;
}

export interface TrialLocationsSummary {
  facts_summary: DatasetFactSummaryData[];
  sites_summary: TrialSiteSummaryData[];
}

export interface DatasetFactValue {
  measurement_meta_id: string;
  geo: boolean;
  title: string;
  value: string | number | GeoJSON;
}

export interface DatasetFileValue {
  measurement_meta_id: string;
  title: string;
  comment: string;
  file_url: string;
  mime_type: string;
  user_file_name: string;
}

interface ListFactSummaryParams extends ListRequestParams {
  sort_by: string[];
}

export interface FactSummaryFilter {
  site_ids: string[];
  cv_ids: string[];
  modified_after: string;
}

export function listFactSummaryData(
  datasetId: string,
  params: { include_empty: boolean } & FactSummaryFilter & ListFactSummaryParams
): Promise<DatasetFactSummaryData[]> {
  return listRequest('/api/trials/0/datasets/' + datasetId + '/fact_summary/', params);
}

export function countFactSummaryData(
  datasetId: string,
  filters: FactSummaryFilter
): Promise<{ count: number }> {
  return requestWithGetParams('/api/trials/0/datasets/' + datasetId + '/count_fact_summary/', filters);
}

export function bulkDeleteFacts(datasetId: string, filters: FactSummaryFilter): Promise<{ count: number }> {
  return request(
    'POST',
    '/api/trials/0/datasets/' + datasetId + '/bulk_delete_facts/?' + listParamsToQueryString(filters as {})
  );
}
