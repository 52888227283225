import * as ko from 'knockout';
import { NameI18nData } from '../api/names';
import { ScheduledVisitData } from '../api/scheduled_visits';
import { getMMLibrarySearchConfig, getMMTraitsSearchConfig } from '../components/configs/search_configs';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import i18n from '../i18n';
import { I18nText } from '../i18n_text';
import { SlugGenerator, slugValidation } from '../ko_bindings/slug_validation';
import { emptyToNull, INTEGER_VALIDATION_RULES } from '../utils';

export abstract class ScheduledVisitBase<T> {
  private slugGenerator: SlugGenerator;

  id: string | null = null;
  nameJson = ko.observable<I18nText>().extend({ i18nTextRequired: true });
  code = ko.observable('').extend({ ...slugValidation, required: false });
  days: ko.Observable<string>;
  traits = ko.observableArray<NameI18nData>([]).extend({ serverError: true });
  daysAfterPlanting = ko.observable('').extend(INTEGER_VALIDATION_RULES);
  oneMinVisit = ko.observable(true);
  nMaxVisits = ko.observable('').extend({
    digit: true,
    serverError: true,
    validation: {
      validator: (v) => {
        const nMax = parseInt(v, 10);
        return isNaN(nMax) || nMax >= 1;
      },
      message: i18n.t('Must be greater than or equal to 1')(),
    },
  });

  repetitionNumberOfDays = ko.observable();
  maxNumberOfRepetition = ko.observable();

  baseDateSearchConfig: FormSelectSearchConfiguration<T>;
  traitSearchConfig = getMMTraitsSearchConfig(this.traits);
  private subscriptions: KnockoutSubscription[] = [];

  finishSetup(baseDateSearchConfig: FormSelectSearchConfiguration<T>, data?: ScheduledVisitData) {
    this.baseDateSearchConfig = baseDateSearchConfig;

    this.repetitionNumberOfDays = ko.observable('').extend({
      required: { onlyIf: () => !!this.maxNumberOfRepetition() },
      min: 1,
      max: 365 * 5, // 5 Years
      ...INTEGER_VALIDATION_RULES,
    });

    this.maxNumberOfRepetition = ko.observable('').extend({
      required: { onlyIf: () => !!this.repetitionNumberOfDays() },
      min: 1,
      max: 100,
      ...INTEGER_VALIDATION_RULES,
    });

    this.days = ko.observable<string>('').extend({
      required: { onlyIf: () => !!this.baseDateSearchConfig.entity() },
      serverError: true,
      ...INTEGER_VALIDATION_RULES,
    });

    if (data) {
      this.id = data.id;
      this.nameJson(data.name_json);
      this.code(data.code);
      if (data.base_date_id || data.base_date) {
        this.days(data.days_offset?.toString() ?? '');
        this.daysAfterPlanting(data.days_after_planting?.toString() || '');
      } else {
        this.days(data.days_after_planting?.toString() ?? '');
        this.daysAfterPlanting('');
      }
      this.oneMinVisit(parseInt(data.n_min_visits.toString(), 10) > 0);
      this.nMaxVisits(data.n_max_visits?.toString() ?? '');
      this.traits(data.traits);
      this.repetitionNumberOfDays(data.repetition_number_of_days?.toString() ?? '');
      this.maxNumberOfRepetition(data.maximum_number_of_repetitions?.toString() ?? '');
    }

    this.subscriptions.push(
      this.baseDateSearchConfig.entity.subscribe((value) => {
        if (!value) {
          this.daysAfterPlanting('');
        }
      })
    );
    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.code, {
      canEdit: true,
      fillIfEmpty: false,
    });
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
    this.slugGenerator.dispose();
  }

  estDaysAfterPlanting() {
    return this.baseDateSearchConfig.entity() ? this.daysAfterPlanting() : this.days();
  }

  estDaysAfterDateTrait() {
    return this.days();
  }

  toData(order: number): ScheduledVisitData {
    return {
      id: this.id,
      name_json: this.nameJson(),
      code: this.code(),
      base_date_id: null,
      base_date_idx: null,
      days_offset: emptyToNull(this.days()),
      days_after_planting: emptyToNull(this.estDaysAfterPlanting()),
      order,
      n_min_visits: this.oneMinVisit() ? 1 : 0,
      n_max_visits: emptyToNull(this.nMaxVisits()),
      traits: this.traits(),
      repetition_number_of_days: emptyToNull(this.repetitionNumberOfDays() ?? ''),
      maximum_number_of_repetitions: emptyToNull(this.maxNumberOfRepetition() ?? ''),
    };
  }
}

export class ScheduledVisitLibrary extends ScheduledVisitBase<NameI18nData> {
  baseDate = ko.observable<NameI18nData>(null);

  constructor(data: ScheduledVisitData) {
    super();
    this.finishSetup(
      getMMLibrarySearchConfig(this.baseDate, null, null, null, {
        onlyFor: 'date',
        management: null,
      }),
      data
    );

    if (data) {
      this.baseDate(data.base_date);
    }
  }

  toData(): ScheduledVisitData {
    return {
      ...super.toData(0),
      base_date: this.baseDate(),
    };
  }
}
