import { RemoveResult, listParamsToQueryString, makeDefaultApi, requestBulkRemoveEntities } from './request';
import { DimensionData } from './dimensions';

export interface StageData extends DimensionData {
  count_for_stats: boolean;
  show_in_s2bim: boolean;
}

export const { list, retrieve, save, remove } = makeDefaultApi<StageData, { country_ids?: string[] }>(
  'stages'
);

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/stages/bulk_remove/${queryParameters}`);
}
