import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { DimensionMeta } from '../models/dimension_meta';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as sitesApi from '../api/sites';
import { CountryData, countriesApi } from '../api/countries';
import { Site } from '../models/site';
import { ListHeaderAction } from '../components/list_header';
import { FilterDelegate } from '../components/list_filters';
import { deflateList } from '../api/serialization';
import { asArray, updateLocationWithQueryString } from '../utils';
import { Action } from '../components/basic_widgets';
import { getArchiveAction } from './dimension_records';
import { canEditSites } from '../permissions';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { translate } from '../i18n_text';

let template = require('raw-loader!../../templates/sites.html').default;

class SitesScreen extends BaseLoadingScreen implements ListLoaderDelegate<sitesApi.SiteData, Site> {
  loader: ListLoader<sitesApi.SiteData, Site>;
  searchPlaceholder = i18n.t('Search sites by name')();
  search = ko.observable('');
  private countryFilter = ko.observableArray<CountryData>(null);
  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Country')(),
      entities: this.countryFilter,
      list: countriesApi.list,
    },
  ];

  canEdit = canEditSites();
  listActions = ko.observableArray<ListHeaderAction>([]);

  dimensionMeta = ko.observable<DimensionMeta>(null);
  isLoading = ko.observable<boolean>(true);

  constructor(params: { filters: { country_ids: string | string[]; search: string } }) {
    super();

    let countryIds = asArray(params.filters.country_ids);
    let countriesPromise =
      countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);

    if (params.filters.search) {
      this.search(params.filters.search);
    }

    let dmPromise = dimensionMetasApi.retrieve(dimensionMetasApi.SITE_SLUG);
    let promise = Promise.all([countriesPromise, dmPromise]).then(([countries, dmData]) => {
      if (countries) {
        this.countryFilter(countries);
      }
      this.dimensionMeta(new DimensionMeta(dmData));
    });
    this.loadedAfter(promise);

    if (this.canEdit) {
      this.listActions.push(
        {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/sites/new/',
          tooltipTitle: i18n.t('Add site')(),
        },
        {
          title: i18n.t('Customize')(),
          icon: 'settings',
          href: '/dimensions/site/',
        },
        {
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: '/dimensions/site/import/',
          tooltipTitle: i18n.t('Import sites')(),
        }
      );
    }
    this.listActions.push({
      title: i18n.t('Map')(),
      icon: 'map',
      loading: this.isLoading,
      onClick: () => this.openMap(),
      tooltipTitle: i18n.t('Show filtered sites on a map')(),
    });
  }

  openMap() {
    const locations = this.loader
      .items()
      .filter((site) => site.gpsLocation())
      .map((site) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [site.gpsLocation().lng, site.gpsLocation().lat],
          },
          properties: {
            info: [{ title: i18n.t('Site')(), value: translate(site.nameJson()) }],
            title: i18n.t('Site')(),
            iconType: 'site',
          },
        };
      });
    const shapes = this.loader
      .items()
      .filter((site) => site.siteArea())
      .map((site) => {
        return {
          type: 'Feature',
          geometry: site.siteArea().geometry,
          properties: {
            info: [{ title: i18n.t('Name')(), value: translate(site.nameJson()) }],
            title: i18n.t('Site shape')(),
            iconType: 'site',
          },
        };
      });

    app.formsStackController.push({
      title: i18n.t('Sites')(),
      name: 'map',
      isBig: true,
      params: {
        value: [...locations, ...shapes],
        result: new Deferred<{}>(),
      },
    });
  }

  onReady(loader: ListLoader<sitesApi.SiteData, Site>) {
    this.loader = loader;
    this.search.subscribe(() => this.loader.forceLoad());
    this.loader.loading.subscribe((loading) => {this.isLoading(loading)});
  }

  fetch(params: ListRequestParams) {
    let filters = { country_ids: deflateList(this.countryFilter), search: this.search() };
    updateLocationWithQueryString(filters);

    return sitesApi.list({ ...filters, ...params });
  }

  instantiate(siteData: sitesApi.SiteData) {
    return new Site(siteData, this.dimensionMeta());
  }

  remove(id: string) {
    return sitesApi.remove(id);
  }

  canRemove(site: Site) {
    return this.canEdit;
  }

  getActions(site: Site): Action[] {
    if (!this.canEdit) {
      return [];
    }
    return getArchiveAction(site, this);
  }
}

export let sites = {
  name: 'sites',
  viewModel: SitesScreen,
  template: template,
};

ko.components.register(sites.name, sites);
