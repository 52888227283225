import * as ko from 'knockout';
import i18n from '../i18n';

import { DimensionMeta } from '../models/dimension_meta';
import { Site } from '../models/site';
import { SITE_SLUG } from '../api/dimension_metas';
import * as countriesApi from '../api/countries';
import * as regionsApi from '../api/regions';
import * as sitesApi from '../api/sites';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { Deferred } from '../utils/deferred';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { asI18nText } from '../i18n_text';
import {
  getCountrySearchConfig,
  getAgroRegionSearchConfig,
  getPartnerSearchConfig,
  getCustomerSearchConfig,
} from '../components/configs/search_configs';
import { deflateSingle } from '../api/serialization';
import { session } from '../session';
import { canCreateCustomer, canCreatePartner, canEditSites } from '../permissions';

let template = require('raw-loader!../../templates/site_edit.html').default;

class SiteEditScreen implements BaseDimensionRecordEditDelegate<sitesApi.SiteData, Site> {
  disableApplications = true;
  allowAnonymize = false;
  allowLinkingCrops = false;
  anyoneCanEdit = false;

  dimensionMetaId = SITE_SLUG;
  dimensionId: string;
  result: Deferred<sitesApi.SiteData>;
  private initialName: string;
  private initialCountry: KnockoutObservable<countriesApi.CountryData>;
  private initialRegion: KnockoutObservable<regionsApi.RegionData>;
  warnPartner = session.tenant().tpp_enabled;

  dimension = ko.observable<Site>(null);
  locationWarningMessage = ko.pureComputed(() => {
    const km = this.dimension()?.LOCATION_WARNING_DISTANCE_THRESHOLD_KM;
    return i18n.t(
      `Location is more than ${km} kilometers away from the site area. Please check the location.`
    )();
  });

  canEdit = ko.observable(canEditSites());

  countrySearchConfig = ko.pureComputed(() =>
    getCountrySearchConfig(this.dimension() ? this.dimension().country : undefined)
  );

  shouldShowLocationWarning = ko.computed(() => {
    const site = this.dimension();
    if (!site) {
      return false;
    }

    return (
      site.shouldShowLocationWarning() && site.wasMapModalClosed() && !site.wasGpsLocationWarningClosed()
    );
  });

  regionSearchConfig = ko.pureComputed(() => {
    let res: FormSelectSearchConfiguration<regionsApi.RegionData> = {
      getSummaryName: (record) => {
        return record.name_json;
      },
      list: (params) => {
        return regionsApi.list({
          country_id: deflateSingle(this.dimension().country()),
          ...params,
        });
      },
      entity: this.dimension() ? this.dimension().region : undefined,
      create: {
        title: i18n.t('Region')(),
        componentName: 'region-edit',
        extraParams: {
          initialCountry: this.dimension().country(),
        },
      },
    };

    return res;
  });

  agroRegionSearchConfig = ko.pureComputed(() =>
    getAgroRegionSearchConfig(
      this.dimension() ? this.dimension().agroRegion : undefined,
      this.dimension() ? this.dimension().country() : undefined
    )
  );

  partnerSearchConfig = ko.pureComputed(() =>
    getPartnerSearchConfig(this.dimension()?.partner, { disableCreate: !canCreatePartner() })
  );
  customerSearchConfig = ko.pureComputed(() =>
    getCustomerSearchConfig(this.dimension()?.customer, { disableCreate: !canCreateCustomer() })
  );

  constructor(params: {
    id: string;
    initialName?: string;
    initialCountry?: KnockoutObservable<countriesApi.CountryData>;
    initialRegion?: KnockoutObservable<regionsApi.RegionData>;
    result?: Deferred<sitesApi.SiteData>;
  }) {
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
    this.initialCountry = params.initialCountry;
    this.initialRegion = params.initialRegion;
  }

  fetch(dimensionMetaId: string, id: string) {
    return sitesApi.retrieve(id);
  }

  instantiate(data: sitesApi.SiteData, dimensionMeta: DimensionMeta) {
    let site = new Site(data, dimensionMeta);
    if (!data) {
      if (this.initialName) {
        site.nameJson(asI18nText(this.initialName));
      }
      if (this.initialCountry && this.initialCountry()) {
        site.country(this.initialCountry());
      }
      if (this.initialRegion && this.initialRegion()) {
        site.region(this.initialRegion());
      }
    }

    return site;
  }

  saveRequest(data: sitesApi.SiteData) {
    return sitesApi.save(data);
  }

  closeGpsLocationWarning = () => {
    this.dimension().wasGpsLocationWarningClosed(true);
  };
}

export let siteEdit = {
  name: 'site-edit',
  viewModel: SiteEditScreen,
  template: template,
};

ko.components.register(siteEdit.name, siteEdit);
