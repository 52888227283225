import { RemoveResult, listParamsToQueryString, makeDefaultApi, requestBulkRemoveEntities } from './request';
import { DimensionData } from './dimensions';
import { NameI18nData } from './names';

export interface CropData extends DimensionData {
  category?: NameI18nData;
  traits?: NameI18nData[];
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  CropData,
  { ids?: string[]; withAdditionalData?: boolean }
>('crops');

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/crops/bulk_remove/${queryParameters}`);
}
