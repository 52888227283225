/// <reference path="type_definitions/globals.d.ts"/>

import page from 'page';

import i18n from './i18n';
import { app } from './app';
import { session } from './session';
import {
  requireAuthorized,
  requireLoggedIn,
  requireAuthorizedCanBeExpired,
  setLoginParams,
  requireGDMAuthorized,
} from './screens/login';
import { users } from './screens/users';
import { userEdit } from './screens/user_edit';
import { groups } from './screens/groups';
import { groupEdit } from './screens/group_edit';
import { trials } from './screens/trials';
import { trialCopy } from './screens/trial_copy';
import { trialExport } from './screens/trial_export';
import { trialWizard } from './screens/trial_wizard';
import { dimensionMetas } from './screens/dimension_metas';
import { dimensionMetaEdit } from './screens/dimension_meta_edit';
import { dimensionRecords } from './screens/dimension_records';
import { dimensionRecordEdit } from './screens/dimension_record_edit';
import { crops } from './screens/crops';
import { cropEdit } from './screens/crop_edit';
import { cropVarieties } from './screens/crop_varieties';
import { cropVarietyEdit } from './screens/crop_variety_edit';
import { sites } from './screens/sites';
import { siteEdit } from './screens/site_edit';
import { stages } from './screens/stages';
import { stageEdit } from './screens/stage_edit';
import { measurementTypes } from './screens/measurement_types';
import { measurementTypeEdit } from './screens/measurement_type_edit';
import { regions } from './screens/regions';
import { regionEdit } from './screens/region_edit';
import { agroRegions } from './screens/agro_regions';
import { agroRegionEdit } from './screens/agro_region_edit';
import { seasons } from './screens/seasons';
import { seasonEdit } from './screens/season_edit';
import { unitsEdit } from './screens/units_edit';
import { settings } from './screens/settings';
import { welcome } from './screens/welcome';
import { dashboard } from './screens/dashboard';
import { signUp } from './screens/sign_up';
import { subscription } from './screens/subscription';
import { notFound } from './screens/not_found';
import { releaseNotes } from './screens/release_notes';
import { trialPrintPlots } from './screens/trial_print_plots';
import { chartEdit } from './screens/chart_edit';
import { importFacts } from './screens/import_facts';
import { assignTrial } from './screens/assign_trial';
import { tenantsUsage } from './screens/internal/tenants_usage';
import { tenantsInTrial } from './screens/internal/tenants_in_trial';
import { measurementMetaLibrary } from './screens/measurement_meta_library';
import { measurementMetaLibraryEdit } from './screens/measurement_meta_library_edit';
import { overview } from './screens/overview';
import { mapActivities } from './screens/map_activities';
import { attributeChoiceLists } from './screens/attribute_choice_lists';
import { attributeChoiceListEdit } from './screens/attribute_choice_list_edit';
import { traitCategories } from './screens/trait_categories';
import { traitCategoryEdit } from './screens/trait_category_edit';
import { trialActivityStatus } from './screens/trial_activity_status';
import { cropVarietiesRequest } from './screens/crop_varieties_request';
import { trialFieldBook } from './screens/trial_field_book';
import { marketSegments } from './screens/market_segments';
import { marketSegmentEdit } from './screens/market_segment_edit';
import { customerProfiles } from './screens/customer_profiles';
import { customerProfileEdit } from './screens/customer_profile_edit';
import { tpps } from './screens/tpps';
import { tppEdit } from './screens/tpp_edit';
import { tppEvaluation, tppEvaluationCVDetail } from './screens/tpp_evaluation';
import { importDimensions } from './screens/import_dimensions';
import {
  parseQueryString,
  setupBaseRoutes,
  setupCreateUpdateRoutes,
  getFiltersParams,
  setupNameEntityRoutes,
} from './utils/routes';
import { countries } from './screens/countries';
import { countryEdit } from './screens/country_edit';
import { breeders, customers, partners } from './screens/organizations';
import { breederEdit, customerEdit, partnerEdit } from './screens/organization_edit';
import { organizationTypes } from './screens/organization_types';
import { organizationTypeEdit } from './screens/organization_type_edit';
import { registrationRegions } from './screens/registration_regions';
import { registrationRegionEdit } from './screens/registration_region_edit';
import { registrationPhases } from './screens/registration_phases';
import { registrationPhaseEdit } from './screens/registration_phase_edit';
import { registrationHistories } from './screens/registration_histories';
import { registrationHistoryEdit } from './screens/registration_history_edit';
import { registrations } from './screens/registrations';
import { registrationEdit } from './screens/registration_edit';
import { getCropVarietyTypesConfig, getCropCategoriesConfig } from './models/name';
import { projects } from './screens/projects';
import { projectEdit } from './screens/project_edit';
import { review } from './screens/review';
import { trialExportPlots } from './screens/trial_export_plots';
import { trialWeatherSiteStatus } from './screens/trial_weather_site_status';
import { trialTypes } from './screens/trial_types';
import { trialTypeEdit } from './screens/trial_type_edit';
import { assignStaff } from './screens/assign_staff/assign_staff';
import { clientTypes } from './screens/client_types';
import { clientTypeEdit } from './screens/client_type_edit';
import { drivers } from './screens/drivers';
import { driverEdit } from './screens/driver_edit';
import { documentCategories } from './screens/document_categories';
import { documentCategoryEdit } from './screens/document_category_edit';
import { trialDocuments } from './screens/trial_documents';
import { trialDocumentEdit } from './screens/trial_document_edit';
import { trialDocumentAddArchive } from './screens/trial_document_add_archive';
import { dataEntryTrials } from './data_entry/trials/data_entry_trials';
import { changePasswordScreen } from './screens/change_password';
import { dataEntryVisits } from './data_entry/visits/data_entry_visits';
import { dataEntryEdit } from './data_entry/edit/data_entry_edit';
import { importAgroRegions } from './screens/import_agro_regions';
import { importRegions } from './screens/import_regions';
import { importUpdateFacts } from './screens/import_update_facts';
import { importOrganizations } from './screens/import_organizations';
import { scheduledVisitLibrary } from './screens/scheduled_visit_library';
import { scheduledVisitLibraryEdit } from './screens/scheduled_visit_library_edit';
import { measurementMetaTags } from './screens/measurement_meta_tags';
import { measurementMetaTagEdit } from './screens/measurement_meta_tag_edit';
import { importCVStages } from './screens/import_cv_stages';
import { tenantSubscriptionEdit } from './screens/internal/tenant_subscription_edit';
import { dwExport } from './screens/dw_export';
import { requireSfsaAuthorization } from './screens/cobranded_login';
import { customReportsScreen } from './screens/custom_reports';
import { customReportEdit } from './screens/custom_report_edit';
import { emailVerifiedScreen } from './screens/email_verification';

// this function needs to be called after i18n is ready and translations are loaded
export function setupRoutes() {
  setupBaseRoutes();

  setLoginParams({
    motto: i18n.t('A faster, easier way to collect and analyse field trial data')(),
    showFooter: true,
  });

  page('*', (ctx, next) => {
    app.showNavigationBar(true); // default
    next();
    if (app.currentPage()?.title) {
      document.title = app.currentPage()?.title;
    }
  });

  page('/', requireAuthorized, (ctx, next) => {
    sendToTrialsOrWelcomePage();
  });

  page('/cobranded-login', requireSfsaAuthorization, (ctx, next) => {
    sendToTrialsOrWelcomePage();
  });

  page('/t/:tenantId/', requireAuthorized, (ctx, next) => {
    sendToTrialsOrWelcomePage();
  });

  page('/t/:tenantId/users/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: users.name,
      title: i18n.t('Users')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/users/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: userEdit.name,
      title: i18n.t('Invite User')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/users/:id/activities/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: mapActivities.name,
      title: i18n.t('User activities')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/users/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: userEdit.name,
      title: i18n.t('Edit User')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/groups/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: groups.name, title: i18n.t('Groups')() });
  });

  page('/t/:tenantId/groups/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: groupEdit.name,
      title: i18n.t('Create Group')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/groups/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: groupEdit.name,
      title: i18n.t('Edit Group')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trials.name,
      title: i18n.t('Trials')(),
      params: { template: false, filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/trial_templates/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trials.name,
      title: i18n.t('Trial template')(),
      params: { template: true, filters: parseQueryString(ctx.querystring) },
    });
  });

  let trialParams = (ctx: PageJS.Context) => {
    let params = parseQueryString(ctx.querystring);
    return {
      editMode: params.edit_mode === 'library' ? 'library' : 'manual',
      fromTemplateId: params.from_template,
      resetTemplate: params.reset_template === 'true',
      fromBaseTemplate: params.from_base_template === 'true',
      cropId: params.crop,
      gateParams: params.gate_params ? JSON.parse(params.gate_params?.toString()) : undefined,
    };
  };

  page('/t/:tenantId/trials/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialWizard.name,
      title: i18n.t('Create trial')(),
      params: { id: null, template: false, ...trialParams(ctx) },
    });
  });

  page('/t/:tenantId/trial_templates/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialWizard.name,
      title: i18n.t('Create trial template')(),
      params: { id: null, template: true, ...trialParams(ctx) },
    });
  });

  page('/t/:tenantId/trials/:id/assign/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: assignTrial.name,
      title: i18n.t('Assign data entry permissions to trial')(),
      params: { trialId: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/assign_staff/', requireAuthorized, (ctx) => {
    app.currentPage({
      name: assignStaff.name,
      title: i18n.t('Assign staff to trial sites')(),
      params: { trialId: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:trialId/documents/add_archive/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialDocumentAddArchive.name,
      title: i18n.t('Trial documents | Add archive')(),
      params: { trialId: ctx.params.trialId },
    });
  });

  page('/t/:tenantId/trials/:trialId/documents/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialDocumentEdit.name,
      title: i18n.t('Trial documents | Create')(),
      params: { trialId: ctx.params.trialId },
    });
  });

  page('/t/:tenantId/trials/:trialId/documents/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialDocumentEdit.name,
      title: i18n.t('Trial documents | Edit')(),
      params: { trialId: ctx.params.trialId, id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:trialId/documents/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialDocuments.name,
      title: i18n.t('Trials | Documents')(),
      params: {
        trialId: ctx.params.trialId,
        template: false,
        filters: parseQueryString(ctx.querystring),
      },
    });
  });

  page('/t/:tenantId/trial_templates/:trialId/documents/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialDocuments.name,
      title: i18n.t('Trials | Documents')(),
      params: {
        trialId: ctx.params.trialId,
        template: true,
        filters: parseQueryString(ctx.querystring),
      },
    });
  });

  page('/t/:tenantId/trials/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialWizard.name,
      title: i18n.t('Trials | Edit')(),
      params: { id: ctx.params.id, template: false },
    });
  });

  page('/t/:tenantId/trials/:id/copy/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialCopy.name,
      title: i18n.t('Trials | Copy trial')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/export/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialExport.name,
      title: i18n.t('Trials | Export trial')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/export_plots/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialExportPlots.name,
      title: i18n.t('Trials | Export plots')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/print_plots/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialPrintPlots.name,
      title: i18n.t('Trials | Print plots')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/field_book/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialFieldBook.name,
      title: i18n.t('Trials | Print field book')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/import_observations/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importFacts.name,
      title: i18n.t('Trials | Import observations')(),
      params: {
        id: ctx.params.id,
        importFilter: parseQueryString(ctx.querystring),
      },
    });
  });

  page('/t/:tenantId/trials/:id/import_update_observations/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importUpdateFacts.name,
      title: i18n.t('Trials | Bulk update observations')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trials/:id/weather_site_status/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialWeatherSiteStatus.name,
      title: i18n.t('Trials | Weather status')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/dimensions/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionMetas.name,
      title: i18n.t('Dimensions')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/dimensions/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionMetaEdit.name,
      title: i18n.t('Dimensions | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/dimensions/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionMetaEdit.name,
      title: i18n.t('Dimensions | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/dimensions/:dimensionMetaId/elements/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionRecords.name,
      title: i18n.t('Dimensions | Elements')(),
      params: { dimensionMetaId: ctx.params.dimensionMetaId, filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/dimensions/:dimensionMetaId/elements/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionRecordEdit.name,
      title: i18n.t('Dimensions | New elements')(),
      params: { dimensionMetaId: ctx.params.dimensionMetaId, id: null },
    });
  });

  page('/t/:tenantId/dimensions/:dimensionMetaId/elements/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dimensionRecordEdit.name,
      title: i18n.t('Dimensions | Edit elements')(),
      params: {
        dimensionMetaId: ctx.params.dimensionMetaId,
        id: ctx.params.id,
      },
    });
  });

  page('/t/:tenantId/dimensions/:dimensionMetaId/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importDimensions.name,
      title: i18n.t('Dimensions | Import')(),
      params: { dmIdOrSlug: ctx.params.dimensionMetaId },
    });
  });

  page('/t/:tenantId/dimensions/crop_variety/import_cv_stages/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: importCVStages.name });
  });

  page('/t/:tenantId/crops/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: crops.name,
      title: i18n.t('Crops')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/crops/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropEdit.name,
      title: i18n.t('Crops | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/crops/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropEdit.name,
      title: i18n.t('Crops | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/crop_varieties/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropVarieties.name,
      title: i18n.t('Crops varieties')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/crop_varieties/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropVarietyEdit.name,
      title: i18n.t('Crops varieties | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/crop_varieties/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropVarietyEdit.name,
      title: i18n.t('Crops varieties | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/crop_varieties_request/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: cropVarietiesRequest.name,
      title: i18n.t('Crops varieties | Entry request')(),
    });
  });

  page('/t/:tenantId/sites/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: sites.name,
      title: i18n.t('Sites')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/sites/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: siteEdit.name,
      title: i18n.t('Sites | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/sites/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: siteEdit.name,
      title: i18n.t('Sites | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/stages/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: stages.name,
      title: i18n.t('Stages')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/stages/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: stageEdit.name,
      title: i18n.t('Stages | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/stages/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: stageEdit.name,
      title: i18n.t('Stages | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/trial_types/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialTypes.name,
      title: i18n.t('Trial types')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/trial_types/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialTypeEdit.name,
      title: i18n.t('Trial types | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/trial_types/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialTypeEdit.name,
      title: i18n.t('Trial types | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/measurement_types/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: measurementTypes.name,
      title: i18n.t('Measurement Types')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/measurement_types/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: measurementTypeEdit.name,
      title: i18n.t('Measurement Types | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/measurement_types/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: measurementTypeEdit.name,
      title: i18n.t('Measurement Types | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/attribute_choice_lists/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: attributeChoiceLists.name,
      title: i18n.t('Attribute Choices')(),
    });
  });

  page('/t/:tenantId/attribute_choice_lists/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: attributeChoiceListEdit.name,
      title: i18n.t('Attribute Choices | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/attribute_choice_lists/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: attributeChoiceListEdit.name,
      title: i18n.t('Attribute Choices | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/regions/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: regions.name,
      title: i18n.t('Administrative regions')(),
    });
  });

  page('/t/:tenantId/regions/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importRegions.name,
      title: i18n.t('Administrative regions | Import')(),
    });
  });

  page('/t/:tenantId/regions/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: regionEdit.name,
      title: i18n.t('Administrative regions | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/regions/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: regionEdit.name,
      title: i18n.t('Administrative regions | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/agro_regions/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: agroRegions.name,
      title: i18n.t('Agroclimatic regions')(),
    });
  });

  page('/t/:tenantId/agro_regions/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importAgroRegions.name,
      title: i18n.t('Agroclimatic regions | Import')(),
    });
  });

  page('/t/:tenantId/agro_regions/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: agroRegionEdit.name,
      title: i18n.t('Agroclimatic regions | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/agro_regions/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: agroRegionEdit.name,
      title: i18n.t('Agroclimatic regions | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/customers/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: customers.name, title: i18n.t('Customers')() });
  });

  page('/t/:tenantId/customers/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importOrganizations.name,
      title: i18n.t('Customers | Import')(),
      params: { type: 'customers' },
    });
  });

  page('/t/:tenantId/customers/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: customerEdit.name,
      title: i18n.t('Customers | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/customers/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: customerEdit.name,
      title: i18n.t('Customers | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/breeders/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importOrganizations.name,
      title: i18n.t('Breeders | Import')(),
      params: { type: 'breeders' },
    });
  });

  page('/t/:tenantId/breeders/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: breeders.name, title: i18n.t('Breeders')() });
  });

  page('/t/:tenantId/breeders/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: breederEdit.name,
      title: i18n.t('Breeders | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/breeders/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: breederEdit.name,
      title: i18n.t('Breeders | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/partners/import/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: importOrganizations.name,
      title: i18n.t('Import partners')(),
      params: { type: 'partners' },
    });
  });

  page('/t/:tenantId/organization_types/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: organizationTypes.name,
      title: i18n.t('Organization Types')(),
    });
  });

  page('/t/:tenantId/organization_types/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: organizationTypeEdit.name,
      title: i18n.t('Organization Types | Create')(),
      params: { id: null },
    });
  });

  page('/t/:tenantId/organization_types/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: organizationTypeEdit.name,
      title: i18n.t('Organization Types | Edit')(),
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/units/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: unitsEdit.name, title: i18n.t('Units')() });
  });

  page('/t/:tenantId/release-notes/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: releaseNotes.name, title: i18n.t('Release notes')() });
  });

  page('/t/:tenantId/settings/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: settings.name, title: i18n.t('Settings')() });
  });

  page('/t/:tenantId/welcome/', requireAuthorized, (ctx, next) => {
    app.currentPage({ name: welcome.name, title: i18n.t('Welcome')() });
  });

  page('/t/:tenantId/dashboard/:trialId/chart/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: chartEdit.name,
      title: i18n.t('Chart | Create')(),
      params: { trialId: ctx.params.trialId },
    });
  });

  page('/t/:tenantId/dashboard/:trialId/chart/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: chartEdit.name,
      title: i18n.t('Chart | Edit')(),
      params: { trialId: ctx.params.trialId, id: ctx.params.id },
    });
  });

  page('/t/:tenantId/dashboard/:trialId/map/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dashboard.name,
      title: i18n.t('Trials | Map observation')(),
      params: { trialId: ctx.params.trialId, openOnMap: true },
    });
  });

  page('/t/:tenantId/dashboard/:trialId/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dashboard.name,
      title: i18n.t('Trials | Dashboard')(),
      params: { trialId: ctx.params.trialId, openOnMap: false },
    });
  });

  page('/t/:tenantId/tpp_evaluation/:id/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: tppEvaluation.name,
      params: { id: ctx.params.id },
    });
  });

  page('/t/:tenantId/tpp_evaluation/:id/cv/:cvId/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: tppEvaluationCVDetail.name,
      params: { id: ctx.params.id, cvId: ctx.params.cvId },
    });
  });

  page('/t/:tenantId/overview/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: overview.name,
      title: i18n.t('Dashboard')(),
      params: { filters: parseQueryString(ctx.querystring) },
    });
  });

  page('/t/:tenantId/custom_reports/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: customReportsScreen.name,
      title: i18n.t('Custom Reports')(),
      params: {},
    });
  });

  page('/t/:tenantId/custom_reports/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: customReportEdit.name,
      title: i18n.t('Create Custom Report')(),
      params: { id: null, trialId: null },
    });
  });

  page('/t/:tenantId/trials/:trialId/custom_reports/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: customReportEdit.name,
      title: i18n.t('Create Custom Report')(),
      params: { id: null, trialId: ctx.params.trialId },
    });
  });

  page('/t/:tenantId/trial_activity_status/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: trialActivityStatus.name,
      title: i18n.t('My Active Trials')(),
    });
  });

  page('/t/:tenantId/registration_histories/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: registrationHistories.name,
      title: i18n.t(['registrations_sep_history', 'Registrations | History'])(),
      params: getFiltersParams(ctx),
    });
  });

  page('/t/:tenantId/registrations/:registrationId/history/new/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: registrationHistoryEdit.name,
      params: { registrationId: ctx.params.registrationId },
    });
  });

  page('/t/:tenantId/review/', requireAuthorized, () => {
    app.currentPage({
      name: review.name,
      title: i18n.t('Review synchronized records')(),
    });
  });

  page('/auth/action/', (ctx, next) => {
    const mode = parseQueryString(ctx.querystring)?.mode;
    if (!mode) {
      page('/');
    }

    app.showNavigationBar(false);
    if (mode === 'resetPassword') {
      app.currentPage({
        name: changePasswordScreen.name,
        title: i18n.t('Reset password')(),
        params: parseQueryString(ctx.querystring),
      });
    } else if (mode === 'verifyEmail') {
      app.currentPage({
        name: emailVerifiedScreen.name,
        title: i18n.t('Email verification')(),
        params: parseQueryString(ctx.querystring),
      });
    }
  });

  page('/t/:tenantId/data_entry/trials/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dataEntryTrials.name,
      title: i18n.t('Data entry')(),
    });
  });

  page('/t/:tenantId/data_entry/visits/:trialId/', requireAuthorized, (ctx, next) => {
    app.currentPage({
      name: dataEntryVisits.name,
      title: i18n.t('Visits')(),
      params: { trialId: ctx.params.trialId },
    });
  });

  page('/t/:tenantId/dw_export/', requireGDMAuthorized, (ctx, next) => {
    app.currentPage({
      name: dwExport.name,
      title: i18n.t('GDM Export')(),
    });
  });

  page('/t/:tenantId/data_entry/edit/', requireAuthorized, (ctx, next) => {
    const params = parseQueryString(ctx.querystring);
    app.currentPage({
      name: dataEntryEdit.name,
      title: i18n.t('Data entry | Edit')(),
      params: {
        trialId: params.trial_id,
        svId: params.sv_id,
        siteId: params.site_id,
        visitId: params.visit_id,
        excludeMobile: params.exclude_mobile,
      },
    });
  });

  setupCreateUpdateRoutes([
    {
      baseName: 'traits_library',
      title: i18n.t('Traits library')(),
      listComponent: measurementMetaLibrary,
      editComponent: measurementMetaLibraryEdit,
      params: { management: false },
    },
    {
      baseName: 'management_traits_library',
      title: i18n.t('Management traits library')(),
      listComponent: measurementMetaLibrary,
      editComponent: measurementMetaLibraryEdit,
      params: { management: true },
    },
    {
      baseName: 'client_types',
      title: i18n.t('Client Types')(),
      listComponent: clientTypes,
      editComponent: clientTypeEdit,
    },
    {
      baseName: 'drivers',
      title: i18n.t('Drivers')(),
      listComponent: drivers,
      editComponent: driverEdit,
    },
    {
      baseName: 'trait_categories',
      title: i18n.t('Trait Categories')(),
      listComponent: traitCategories,
      editComponent: traitCategoryEdit,
    },
    {
      baseName: 'market_segments',
      title: i18n.t('Market Segments')(),
      listComponent: marketSegments,
      editComponent: marketSegmentEdit,
    },
    {
      baseName: 'customer_profiles',
      title: i18n.t('Customer profiles')(),
      listComponent: customerProfiles,
      editComponent: customerProfileEdit,
    },
    {
      baseName: 'tpps',
      title: i18n.t('Target product profiles')(),
      listComponent: tpps,
      editComponent: tppEdit,
    },
    {
      baseName: 'countries',
      title: i18n.t('Countries')(),
      listComponent: countries,
      editComponent: countryEdit,
    },
    {
      baseName: 'registration_regions',
      title: i18n.t('Registration Regions')(),
      listComponent: registrationRegions,
      editComponent: registrationRegionEdit,
    },
    {
      baseName: 'registration_phases',
      title: i18n.t('Registration Phases')(),
      listComponent: registrationPhases,
      editComponent: registrationPhaseEdit,
    },
    {
      baseName: 'registrations',
      title: i18n.t('Registrations')(),
      listComponent: registrations,
      editComponent: registrationEdit,
    },
    {
      baseName: 'projects',
      title: i18n.t('Programs')(),
      listComponent: projects,
      editComponent: projectEdit,
    },
    {
      baseName: 'seasons',
      title: i18n.t('Seasons')(),
      listComponent: seasons,
      editComponent: seasonEdit,
    },
    {
      baseName: 'document_tags',
      title: i18n.t('Document Tags')(),
      listComponent: documentCategories,
      editComponent: documentCategoryEdit,
    },
    {
      baseName: 'trait_tags',
      title: i18n.t('Trait tags')(),
      listComponent: measurementMetaTags,
      editComponent: measurementMetaTagEdit,
    },
    {
      baseName: 'partners',
      title: i18n.t('Partners')(),
      listComponent: partners,
      editComponent: partnerEdit,
    },
    {
      baseName: 'scheduled_visit_library',
      title: i18n.t('Scheduled visits library')(),
      listComponent: scheduledVisitLibrary,
      editComponent: scheduledVisitLibraryEdit,
    },
  ]);

  setupNameEntityRoutes([getCropCategoriesConfig(), getCropVarietyTypesConfig()]);

  if (SERVER_INFO.ALLOW_SIGNUP) {
    page('/sign-up/', (ctx, next) => {
      app.currentPage({
        name: signUp.name,
        title: i18n.t('SIGN UP')(),
        params: { planId: parseQueryString(ctx.querystring)['subscribe'] },
      });
    });

    page('/t/:tenantId/subscription/', requireAuthorizedCanBeExpired, (ctx, next) => {
      app.currentPage({
        name: subscription.name,
        title: i18n.t('Subscription')(),
        params: { planId: parseQueryString(ctx.querystring)['plan_id'] },
      });
    });
  }

  // internal pages

  page('/tenants-usage/', requireLoggedIn, (ctx, next) => {
    app.currentPage({
      name: tenantsUsage.name,
      title: 'Tenants subscriptions',
    });
  });

  page('/tenants-in-trial/', requireLoggedIn, (ctx, next) => {
    app.currentPage({
      name: tenantsInTrial.name,
      title: 'Tenants in trial mode',
    });
  });

  page('/tenants-usage/edit/:id/', requireLoggedIn, (ctx, next) => {
    app.currentPage({
      name: tenantSubscriptionEdit.name,
      title: 'Edit tenant subscription',
      params: { id: ctx.params.id },
    });
  });

  page('*', () => {
    app.currentPage({ name: notFound.name, title: i18n.t('Page not found')() });
  });
}

function sendToTrialsOrWelcomePage() {
  if (localStorage.getItem('welcomeTutorialShown') === '1') {
    if (session.tenant()?.role === 'data_entry') {
      page.redirect(session.toTenantPath('/data_entry/trials/'));
    } else {
      page.redirect(session.toTenantPath('/trials/'));
    }
  } else {
    page.redirect(session.toTenantPath('/welcome/'));
  }
}
