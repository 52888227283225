import * as ko from 'knockout';

import { FormLocationMapPoint } from '../../components/basic_widgets';
import { Point } from '../../ko_bindings/map';
import { GeoJSON } from '../../api/datasets';
import {
  DataEntryEditModel,
  DataEntryEditModelSubscriber,
  MultiPicturesData,
  MultiPicturesModel,
} from './data_entry_edit_model';
import { ImageUpload } from '../../image_upload';
import { FileUploadEndpoint } from '../../cloud_storage_upload';
import { parseDate, serializeDate } from '../../api/serialization';
import { getFactUploadEndpoint } from '../data_entry_api';
import { viewImages } from '../../components/view_image';
import { FileCloudStorageUploadDelegate } from '../../file_upload';
import { isEqual } from 'lodash';

let template = require('raw-loader!./data_entry_bar.html').default;

export interface DataEntryBarDelegate {
  onBarValue(value: {}, originator: string): void;
  onEditExtras(): void;
  onEditMultiPictures(): void;
  onPictureClick(url: string): void;
}

const BAR_ORIGINATOR = 'bar';

class DataEntryBar implements DataEntryEditModelSubscriber {
  title = ko.observable('');
  type = ko.observable('');
  textValue = ko.observable('');
  choiceValue = ko.observable('');
  options = ko.observableArray<{ id: string; name: string }>();
  dateValue = ko.observable<Date>(null);
  pointValue = ko.observable<Point>(null);
  posValue = FormLocationMapPoint.posObservable();
  shapeValue = ko.observable<GeoJSON>(null);
  validationError = ko.observable('');
  mpValue = ko.observableArray<string>();
  disabled = ko.observable<boolean>(false);

  imageUpload = new ImageUpload({
    getImageUploadEndpoint: (contentType: string): Promise<FileUploadEndpoint> => {
      return getFactUploadEndpoint(contentType);
    },

    onPictureClick: () => {
      const url = this.imageUpload.picturePublicURL();
      if (url) {
        viewImages([url]);
      }
    },
  });

  fileUpload = new FileCloudStorageUploadDelegate();

  picture1 = ko.observable('');
  picture2 = ko.observable('');
  morePictures = ko.observable(0);
  loadingExtra = ko.observable(false);

  private delegate: DataEntryBarDelegate;
  private subscriptions: KnockoutSubscription[] = [];
  private modelUnsubscribe: () => void;
  private reentrant = false;
  private loadExtraSequence = 0;

  constructor(params: { editModel: ko.Observable<DataEntryEditModel>; delegate: DataEntryBarDelegate }) {
    this.imageUpload.canReuseEndpoint = false;

    this.delegate = params.delegate;

    const editModel = params.editModel();
    editModel.subscribe(this);
    this.modelUnsubscribe = () => editModel.unsubscribe(this);
    params.editModel.subscribe((newValue) => {
      if (newValue == null) {
        return;
      }
      newValue.subscribe(this);

      this.modelUnsubscribe = () => newValue.unsubscribe(this);
    });
    for (let obs of [
      this.choiceValue,
      this.dateValue,
      this.pointValue,
      this.shapeValue,
      this.imageUpload.picturePublicURL,
      this.fileUpload.fileName,
    ]) {
      this.subscriptions.push((obs as KnockoutObservable).subscribe(this.onValueChanged));
    }
  }

  onBlur = (event: FocusEvent) => {
    this.onValueChanged();
  };

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.onValueChanged();
    }
  };

  dispose() {
    this.modelUnsubscribe();
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  updateAddPicturesButtonState(value: any) {
    this.disabled(value === null || value === undefined || value === '');
  }

  onDataEntryEditModelChanged(edit: DataEntryEditModel, originator: string): void {
    this.reentrant = true;

    try {
      this.validationError(edit.selectedValidationError());

      if (edit.selectedType() === 'multi_pictures') {
        const valueModel = new MultiPicturesModel(edit.selectedValue() as MultiPicturesData[]);
        this.mpValue(valueModel.urls());
      }

      // avoid circular changes
      if (originator === BAR_ORIGINATOR && isEqual(edit.selectedValue(), this.getRawValue())) {
        this.updateAddPicturesButtonState(edit.selectedValue());
        return;
      }

      this.title(edit.selectedTitle());
      this.type(edit.selectedType());
      this.setRawValue(edit.selectedValue());
      this.options(edit.selectedOptions());
      this.renderExtras(edit);
    } finally {
      this.reentrant = false;
    }
  }

  private async renderExtras(edit: DataEntryEditModel) {
    const seq = ++this.loadExtraSequence;
    this.loadingExtra(true);

    const extras = await edit.selectedExtras({ useCached: true });
    if (this.loadExtraSequence !== seq) {
      // invalid
      return;
    }
    const selectMeasurementMetaId = parseInt(edit.data.mm_ids[edit.selectedCol()]);

    const pics = extras
      .filter(
        (e) =>
          !!e.file_url &&
          e.mime_type.indexOf('image/') === 0 &&
          (e.measurement_meta_id == null || e.measurement_meta_id == selectMeasurementMetaId)
      )
      .map((e) => e.file_url);

    this.picture1(pics.length > 0 ? pics[0] : null);
    this.picture2(pics.length > 1 ? pics[1] : null);
    this.morePictures(Math.max(0, pics.length - 2));
    this.loadingExtra(false);
  }

  private setRawValue(value: any) {
    this.updateAddPicturesButtonState(value);

    // NOTE: don't handle multi_pictures here
    switch (this.type()) {
      case 'integer':
      case 'decimal':
      case 'string':
      case 'string_long':
      case 'barcode':
        this.textValue(value?.toString() ?? '');
        break;
      case 'choice':
        this.choiceValue(value?.toString() ?? '');
        break;
      case 'date':
        this.dateValue(parseDate(value));
        break;
      case 'picture':
        this.imageUpload.fileName = value?.name ?? '';
        this.imageUpload.picturePublicURL(value?.url ?? '');
        break;
      case 'location':
        if (value) {
          this.pointValue({
            lat: value.geometry.coordinates[1],
            lng: value.geometry.coordinates[0],
          });
        } else {
          this.pointValue(null);
        }
        break;
      case 'geo':
        this.shapeValue(value);
        break;
      case 'file':
        this.fileUpload.setRawValue(value);
        break;
    }
  }

  private getRawValue(): {} {
    // NOTE: doesn't need to handle multi_pictures
    switch (this.type()) {
      case 'integer':
      case 'decimal':
      case 'string':
      case 'string_long':
      case 'barcode':
        return this.textValue();
      case 'choice':
        return this.choiceValue();
      case 'date':
        return serializeDate(this.dateValue());
      case 'picture':
        if (this.imageUpload.picturePublicURL()) {
          return {
            name: this.imageUpload.fileName,
            url: this.imageUpload.picturePublicURL(),
          };
        } else {
          return null;
        }
      case 'location':
        const value = this.pointValue();
        if (!value) {
          return null;
        }

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [value.lng, value.lat],
          },
        };
      case 'geo':
        return this.shapeValue();
      case 'file':
        return this.fileUpload.getRawValue();
      default:
        return null;
    }
  }

  private onValueChanged = () => {
    if (this.reentrant) {
      return;
    }

    this.delegate.onBarValue(this.getRawValue(), BAR_ORIGINATOR);
  };

  onEditExtras = async () => {
    this.delegate.onEditExtras();
  };

  onMuliPictureClick = (url: string) => {
    this.delegate.onPictureClick(url);
  };

  onEditMultiPictures = () => {
    this.delegate.onEditMultiPictures();
  };
}

export const dataEntryBar = {
  name: 'data-entry-bar',
  viewModel: DataEntryBar,
  template,
};

ko.components.register(dataEntryBar.name, dataEntryBar);
