import * as ko from 'knockout';
import i18n from '../i18n';

import { ListRequestParams, RemoveResult } from '../api/request';
import { serializeDate } from '../api/serialization';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import * as sitesApi from '../api/sites';
import { ListLoaderDelegate, ListFilter, ListLoader } from './list_loader';
import { Dimension } from '../models/dimension';
import { DimensionMeta } from '../models/dimension_meta';
import { Deferred } from '../utils/deferred';
import {
  getCropSearchConfig,
  getCustomerSearchConfig,
  getPartnerSearchConfig,
} from './configs/search_configs';
import { defaultRateLimit, makeDynamicAttribute, AttributeMeta } from '../models/attribute_meta';
import { Site } from '../models/site';
import { findById, asArray } from '../utils';
import { CropData } from '../api/crops';
import { I18nText } from '../i18n_text';
import { KOMaybeArray } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/dimension_advanced_search.html').default;

interface AdvancedSearchModel<TData, TModel> extends ListLoaderDelegate<TData, TModel> {
  allowMultipleSelections: boolean;
  hasAnonymizedCode: ko.Subscribable<boolean>;
  isSite: ko.Subscribable<boolean>;
  attributesMetas: KnockoutObservableArray<AttributeMeta>;

  loading: KnockoutObservable<boolean>;

  getItemName: (item: TModel) => I18nText;

  cancel: () => void;
  selectAll: () => void;
  done: () => void;
  onItemSelected: (item: TModel) => void;
  isItemSelected: (item: TModel) => boolean;
}

class DimensionAdvancedSearch implements AdvancedSearchModel<dimensionsApi.DimensionData, Dimension> {
  filters: ListFilter[] = [];

  private listLoader: ListLoader<dimensionsApi.DimensionData, Dimension>;
  private initialCrop: KOMaybeArray<dimensionsApi.DimensionData>;
  private initialCountry: KnockoutObservable<dimensionsApi.DimensionData>;
  private result: Deferred<dimensionsApi.DimensionData | dimensionsApi.DimensionData[]>;
  private selections = ko.observableArray<dimensionsApi.DimensionData>();

  allowMultipleSelections: boolean;
  attributesMetas: KnockoutObservableArray<AttributeMeta>;
  loading = ko.observable(true);
  isEverythingSelected = ko.observable(false);
  dimensionMeta = ko.observable<DimensionMeta>();

  constructor(params: {
    dimensionMetaId: string;
    initialCrop: KOMaybeArray<dimensionsApi.DimensionData>;
    initialName: string;
    initialCountry: KnockoutObservable<dimensionsApi.DimensionData>;
    allowMultipleSelections: boolean;
    initialMultipleSelections: dimensionsApi.DimensionData[];
    result: Deferred<dimensionsApi.DimensionData | dimensionsApi.DimensionData[]>;
  }) {
    this.initialCrop = params.initialCrop;
    this.initialCountry = params.initialCountry;
    this.result = params.result;
    this.allowMultipleSelections = params.allowMultipleSelections;
    this.selections(params.initialMultipleSelections);

    dimensionMetasApi.retrieve(params.dimensionMetaId).then((dimensionMetaData) => {
      this.dimensionMeta(new DimensionMeta(dimensionMetaData));
      this.attributesMetas = this.dimensionMeta().attributeMetas;
      this.setupFilters(params.initialName);
      this.loading(false);
    });
  }

  onReady(loader: ListLoader<dimensionsApi.DimensionData, Dimension>) {
    this.listLoader = loader;
  }

  hasAnonymizedCode = ko.pureComputed(() => {
    return this.dimensionMeta().slug() !== dimensionMetasApi.SITE_SLUG;
  });

  isSite = ko.pureComputed(() => {
    return this.dimensionMeta().slug() === dimensionMetasApi.SITE_SLUG;
  });

  private setupFilters(initialName: string) {
    this.filters.push({
      name: i18n.t('Name')(),
      slug: 'name_prefix',
      type: 'text',
      value: ko.observable(initialName).extend({ rateLimit: defaultRateLimit }),
    });
    if (this.hasAnonymizedCode()) {
      this.filters.push({
        name: i18n.t('Anonymized code')(),
        slug: 'anonymized_code',
        type: 'text',
        value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
      });
    }
    if (this.dimensionMeta().slug() === dimensionMetasApi.CROP_VARIETY_SLUG && !this.initialCrop) {
      this.filters.push({
        name: i18n.t('Crop')(),
        slug: 'crop_ids',
        type: 'select',
        config: getCropSearchConfig(ko.observable<CropData>(null)),
      });
    }
    if (this.dimensionMeta().slug() === dimensionMetasApi.SITE_SLUG) {
      this.filters.push({
        name: i18n.t('Partner')(),
        slug: 'partner_ids',
        type: 'select',
        config: getPartnerSearchConfig(ko.observable(null)),
      });
      this.filters.push({
        name: i18n.t('Customer')(),
        slug: 'customer_ids',
        type: 'select',
        config: getCustomerSearchConfig(ko.observable(null)),
      });
    }

    for (let attributeMeta of this.dimensionMeta().attributeMetas()) {
      let filter = makeDynamicAttribute(attributeMeta, {}).asFilter();
      if (filter !== null) {
        this.filters.push(filter);
      }
    }
  }

  getItemName(item: Dimension): I18nText {
    return item.nameJson();
  }

  cancel = () => {
    this.result.reject();
  };

  selectAll = () => {
    if (!this.listLoader || this.isEverythingSelected()) {
      return;
    }

    this.isEverythingSelected(true);
    this.listLoader.forceLoad((items) => {
      this.selections(items.map((item) => item.toData()));
    });
  };

  deselectAll = () => {
    if (!this.listLoader || this.selections().length == 0) {
      return;
    }

    this.selections([]);
    this.isEverythingSelected(false);
  };

  done = () => {
    this.result.resolve(this.selections());
  };

  onItemSelected = (item: Dimension) => {
    if (this.allowMultipleSelections) {
      let selection = findById(this.selections(), item.id());
      if (selection) {
        this.selections.remove(selection);
        this.isEverythingSelected(false);
      } else {
        this.selections.push(item.toData());
      }
    } else {
      this.result.resolve(item.toData());
    }
  };

  isItemSelected = (item: Dimension) => {
    return this.allowMultipleSelections && !!findById(this.selections(), item.id());
  };

  fetch(params: ListRequestParams): Promise<dimensionsApi.DimensionData[]> {
    let filters: dimensionsApi.AdvancedSearchListRequestParams = { ...params };

    if (this.isEverythingSelected()) {
      // For now, use a high limit to get all items.
      // TODO: We can not possibly get a million items at once and keep transferring
      // them back and forth to the server. We need to save the "Select All" flag
      // along with the filters and send that to the backend instead. The backend
      // should then save all dimensions matching those filters when the "Select All"
      // flag is set.
      filters['limit'] = 10000000;
      filters['offset'] = 0;
    }

    if (this.initialCrop && this.initialCrop()) {
      filters['crop_ids'] = asArray(this.initialCrop()).map((crop) => crop.id);
    }

    if (this.initialCountry && this.initialCountry()) {
      filters['country_id'] = this.initialCountry().id;
    }

    for (let filter of this.filters) {
      if (filter.config && filter.config.entity && filter.config.entity()) {
        filters[filter.slug] = ko.unwrap(filter.config.entity().id);
      }
      if (filter.value) {
        filters[filter.slug] = this.serializeFilterValue(filter.value());
      }
      if (filter.minValue) {
        filters['min_' + filter.slug] = this.serializeFilterValue(filter.minValue());
      }
      if (filter.maxValue) {
        filters['max_' + filter.slug] = this.serializeFilterValue(filter.maxValue());
      }
    }

    if (this.dimensionMeta().slug() === dimensionMetasApi.SITE_SLUG) {
      return sitesApi.advancedSearch(filters);
    } else {
      return dimensionsApi.advancedSearch(this.dimensionMeta().id(), filters);
    }
  }

  instantiate(data: dimensionsApi.DimensionData | sitesApi.SiteData): Dimension {
    let dm = this.dimensionMeta();
    if (dm.slug() === dimensionMetasApi.SITE_SLUG) {
      return new Site(data as sitesApi.SiteData, dm);
    } else {
      return new Dimension(data, this.dimensionMeta());
    }
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: Dimension): boolean {
    return false;
  }

  private serializeFilterValue(value: string | Date): string {
    if (value instanceof Date) {
      return serializeDate(value);
    }

    return value;
  }
}

interface NameData {
  id: string;
  name_json: I18nText;
}

class NameAdvancedSearch implements AdvancedSearchModel<NameData, NameData> {
  filters: ListFilter[] = [
    {
      name: i18n.t('Name')(),
      slug: 'name_prefix',
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    },
  ];

  private list: (params: ListRequestParams) => Promise<NameData[]>;
  private result: Deferred<NameData>;
  private selection = ko.observable<NameData>();

  allowMultipleSelections = false;
  hasAnonymizedCode = ko.observable(false);
  isSite = ko.observable(false);
  attributesMetas = ko.observableArray();

  loading = ko.observable(false);

  constructor(params: {
    list: (params: ListRequestParams) => Promise<NameData[]>;
    selection: NameData;
    result: Deferred<NameData>;
  }) {
    this.list = params.list;
    this.selection(params.selection || null);
    this.result = params.result;
  }

  getItemName(item: NameData): I18nText {
    return item.name_json;
  }

  cancel = () => {
    this.result.reject();
  };

  selectAll = () => {};

  done = () => {
    this.result.resolve(this.selection());
  };

  onItemSelected = (item: NameData) => {
    this.result.resolve(item);
  };

  isItemSelected = (item: NameData) => {
    return this.selection() && item.id === this.selection().id;
  };

  fetch(params: ListRequestParams): Promise<NameData[]> {
    params.name_prefix = this.filters[0].value() as string;
    return this.list(params);
  }

  instantiate(data: NameData): NameData {
    return data;
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: NameData): boolean {
    return false;
  }
}

export let dimensionAdvancedSearch = {
  name: 'dimension-advanced-search',
  viewModel: DimensionAdvancedSearch,
  template: template,
};
export let nameAdvancedSearch = {
  name: 'name-advanced-search',
  viewModel: NameAdvancedSearch,
  template: template,
};

ko.components.register(dimensionAdvancedSearch.name, dimensionAdvancedSearch);
ko.components.register(nameAdvancedSearch.name, nameAdvancedSearch);
