import * as ko from 'knockout';
import i18n from '../i18n';

import { MaybeKO, asObservable } from '../utils/ko_utils';
import { I18nText, translate } from '../i18n_text';
import { GeoJSON } from '../api/datasets';
import { selectLocationPopup } from './map_edit';
import { Point } from '../ko_bindings/map';
import { selectLocationPointPopup } from './map_point';

let popupTemplate = require('raw-loader!../../templates/components/basic_widgets/popup.html').default;
let loadingIndicatorTemplate =
  require('raw-loader!../../templates/components/basic_widgets/loading_indicator.html').default;
let googlePlayTemplate =
  require('raw-loader!../../templates/components/basic_widgets/google_play.html').default;
let appStoreTemplate = require('raw-loader!../../templates/components/basic_widgets/app_store.html').default;
let infoTemplate = require('raw-loader!../../templates/components/basic_widgets/info.html').default;
let actionsMenuTemplate =
  require('raw-loader!../../templates/components/basic_widgets/actions_menu.html').default;

let primaryButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/primary_button.html').default;
let addButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/add_button.html').default;
let saveButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/save_button.html').default;
let secondaryButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/secondary_button.html').default;
let flatButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/flat_button.html').default;
let flatButtonSolidTemplate =
  require('raw-loader!../../templates/components/basic_widgets/flat_button_solid.html').default;
let appButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/app_button.html').default;
let outlinedButtonTemplate =
  require('raw-loader!../../templates/components/basic_widgets/outlined_button.html').default;

let formTextInputTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_text_input.html').default;
let formTextAreaTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_textarea.html').default;
let formCheckboxTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_checkbox.html').default;
let formSelectTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_select.html').default;
let formDateInputTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_date_input.html').default;
let fileUploadTemplate =
  require('raw-loader!../../templates/components/basic_widgets/file_upload.html').default;
let formLocationMapTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_location_map.html').default;
let formLocationMapPointTemplate =
  require('raw-loader!../../templates/components/basic_widgets/form_location_map_point.html').default;
let actualFormTextInputTemplate =
  require('raw-loader!../../templates/components/basic_widgets/actual_form_text_input.html').default;

class LoadingIndicator {
  text: KnockoutObservable<string>;

  constructor(params: { text: KnockoutObservable<string> }) {
    this.text = params.text || ko.observable(i18n.t('Loading…')());
  }
}

export interface Action {
  icon: string;
  title: string;
  cssClass: string;
  onClick: () => void;
}

class ActionsMenu {
  isOpen = ko.observable(false);
  actions: MaybeKO<Action[]>;

  constructor(params: { actions: MaybeKO<Action[]> }) {
    this.actions = params.actions;
  }

  onActionClick = (action: Action) => {
    this.isOpen(false);
    action.onClick();
  };

  // workaround for IE, which focuses child element instead of flat-button
  onFocusIn = () => {
    this.isOpen(true);
  };

  onFocusOut = () => {
    this.isOpen(false);
  };
}

export class FormValueInput<T = {}> {
  htmlId = ko.observable<string>('');
  value: KnockoutObservable<T>;
  enable: KnockoutObservable<boolean>;
  readonly: KnockoutObservable<boolean>;
  onBlurCallback?: (arg0: FocusEvent) => void;
  onKeyDownCallback?: (arg0: KeyboardEvent) => void;

  subscriptions: KnockoutSubscription[] = [];

  private onCaretEventParam: () => void;

  constructor(
    params: {
      value: KnockoutObservable<T>;
      enable?: MaybeKO<boolean>;
      readonly?: MaybeKO<boolean>;
      onCaretEvent?: () => void;
      onBlurCallback?: (arg0: FocusEvent) => void;
      onKeyDownCallback?: (arg0: KeyboardEvent) => void;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    let element = <Element>componentInfo.element;

    this.htmlId(element.getAttribute('input-id') || 'id-' + Math.floor(Math.random() * 1000000000));
    this.value = params.value;
    this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
    this.readonly = asObservable(params.readonly || undefined);
    this.onCaretEventParam = params.onCaretEvent;
    this.onBlurCallback = params.onBlurCallback;
    this.onKeyDownCallback = params.onKeyDownCallback;

    this.subscriptions.push(this.value.subscribe(this.onValueChanged));
  }

  onBlur(data: {}, event: FocusEvent)  {
    if(this.onBlurCallback) {
      this.onBlurCallback(event);
    }
    return true;
  }

  onKeyDown(data: {}, event: KeyboardEvent) {
    if(this.onKeyDownCallback) {
      this.onKeyDownCallback(event);
    }
    return true;
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  onValueChanged = () => {
    if (this.value.serverError) {
      this.value.serverError(null);
    }
  };

  onCaretEvent = (evt: Event) => {
    this.onCaretEventParam?.();
    return true;
  };

  static createViewModel(
    params: {
      value: KnockoutObservable<{}>;
      enable?: KnockoutObservable<boolean>;
      readonly?: MaybeKO<boolean>;
      onBlur?: () => void;
      onCaretEvent?: () => void;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    return new FormValueInput(params, componentInfo);
  }
}

interface Dict {
  [key: string]: string | number;
}

interface FormSelectInputParams {
  value: KnockoutObservable<string | number>;
  options: KnockoutObservableArray<Dict> | Dict[];
  enable?: KnockoutObservable<boolean>;
  placeholderText?: string;
  optionsText: MaybeKO<string>;
  optionsValue: MaybeKO<string>;
  optionsCaption: MaybeKO<string>;
}

class FormSelectInput extends FormValueInput {
  options: KnockoutObservableArray<Dict>;
  optionsText: string | ((item: {}) => string | I18nText);
  optionsValue: MaybeKO<string>;
  optionsCaption: MaybeKO<string>;
  placeholderText: string | undefined;

  constructor(params: FormSelectInputParams, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    super(params, componentInfo);

    if (ko.isObservable(params.options)) {
      this.options = <KnockoutObservableArray<Dict>>params.options;
    } else {
      this.options = ko.observableArray<Dict>(<Dict[]>params.options);
    }
    this.optionsText = params.optionsText;
    this.optionsValue = params.optionsValue;
    this.optionsCaption = params.optionsCaption;
    this.placeholderText = params.placeholderText;

    // sync model with UI
    this.subscriptions.push(
      this.options.subscribe((options) => {
        if (this.value() === null && options.length) {
          this.value(options[0][ko.unwrap(this.optionsValue)]);
        } else if (!options.length) {
          this.value(null);
        }
      })
    );
  }

  trOptionsText = (item: { [key: string]: MaybeKO<string | I18nText> }) => {
    if (!this.optionsText) {
      return item;
    }

    let value: string | I18nText;

    if (typeof this.optionsText === 'string') {
      value = ko.unwrap(item[this.optionsText]);
    } else {
      value = this.optionsText(item);
    }

    return typeof value === 'string' ? value : translate(value);
  };

  static createViewModel(
    params: FormSelectInputParams,
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    return new FormSelectInput(params, componentInfo);
  }
}

export interface FileUploadDelegate {
  fileUploadError?: KnockoutObservable<boolean>;
  picturePublicURL?: KnockoutObservable<string>;
  onPictureClick?(): void;
  onUploadStarted?(file: File): boolean;
  onFileContents(
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest,
    context?: any
  ): void;
}

export class FileUpload {
  icon: string;
  accept: string;
  delegate: FileUploadDelegate;
  enable: KnockoutObservable<boolean>;
  context: any | null;

  fileUploadPercentage = ko.observable<number>(null);
  fileUploadError = ko.observable('');

  subscriptions: KnockoutSubscription[] = [];

  constructor(params: {
    icon: string;
    accept: string;
    delegate: FileUploadDelegate;
    enable?: MaybeKO<boolean>;
    context?: MaybeKO<any>;
  }) {
    this.icon = params.icon;
    this.accept = this.acceptedContentTypes(params.accept);
    this.delegate = params.delegate;
    this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
    this.context = params.context;

    if (typeof this.delegate.fileUploadError !== 'undefined') {
      this.subscriptions.push(
        this.delegate.fileUploadError.subscribe((error) => {
          if (error) {
            this.fileUploadPercentage(null);
          }
        })
      );
    }
  }

  dispose() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }

  private acceptedContentTypes(accept: string): string {
    const allowed_content_types = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/heif',
      'text/plain',
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'pplication/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'application/json',
      'application/zip',
      'application/octet-stream',
    ];
    if (accept === '*/*') {
      return allowed_content_types.join(',');
    }
    if (accept === 'image/*') {
      return allowed_content_types.filter((contentType) => contentType.startsWith('image/')).join(',');
    }
    return accept;
  }

  private prepareXHR = () => {
    let xhr = $.ajaxSettings.xhr();
    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      let percent = Math.ceil((event.loaded / event.total) * 100);
      this.fileUploadPercentage(percent);
    });
    xhr.upload.addEventListener('load', () => {
      this.fileUploadPercentage(null);
    });
    xhr.upload.addEventListener('error', () => {
      this.fileUploadPercentage(null);
    });

    return xhr;
  };

  onPictureClick = () => this.delegate.onPictureClick?.();

  onFileSelected = (data: {}, evt: Event) => {
    let input = <HTMLInputElement>evt.target;
    let file = input.files[0];
    if (!file) {
      return;
    }
    let contentType = file.type;

    this.fileUploadError(null);

    input.value = null; // force change event to trigger again if user re-selectes the same file

    if (this.delegate.onUploadStarted && !this.delegate.onUploadStarted(file)) {
      return;
    }

    let fileContentsPromise = new Promise<ArrayBuffer>((accept, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (evt: ProgressEvent) => {
        accept(<ArrayBuffer>(<any>evt).target.result);
      };

      reader.onabort = reader.onerror = () => {
        reject();
      };
    });

    fileContentsPromise.then((fileContents) => {
      // Show the progress bar as soon as the upload starts.
      this.fileUploadPercentage(0);
      this.delegate.onFileContents(file.name, fileContents, contentType, this.prepareXHR, this.context);
    });
  };
}

class FormLocationMap extends FormValueInput<GeoJSON> {
  private defaultLocation: KnockoutObservable<Point>;
  isMapModalClosed?: KnockoutObservable<boolean>;

  onSelectLocation = (data: {}, event: Event) => {
    selectLocationPopup(this.value, this.defaultLocation, this.isMapModalClosed);
    (event.target as HTMLElement).blur();
  };

  formattedLocationPos = ko.pureComputed(() => (this.value() ? i18n.t('Region selected')() : ''));

  constructor(
    params: {
      value: KnockoutObservable<GeoJSON>;
      defaultLocation: KnockoutObservable<Point>;
      enable?: MaybeKO<boolean>;
      readonly?: MaybeKO<boolean>;
      isMapModalClosed?: KnockoutObservable<boolean>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params, componentInfo);
    this.defaultLocation = params.defaultLocation;
    this.isMapModalClosed = params.isMapModalClosed;
  }

  clearMap() {
    this.value(null);
  }

  static createViewModel(
    params: {
      value: KnockoutObservable<GeoJSON>;
      defaultLocation: KnockoutObservable<Point>;
      enable?: KnockoutObservable<boolean>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    return new FormLocationMap(params, componentInfo);
  }
}

export class FormLocationMapPoint extends FormValueInput<Point> {
  isMapModalClosed?: KnockoutObservable<boolean>;
  mapCenterLocation?: Point;
  onSelectLocation = () => {
    selectLocationPointPopup(this.value, this.enable(), this.isMapModalClosed, this.mapCenterLocation);
  };

  locationPos: ko.Observable<string>;

  constructor(
    params: {
      value: ko.Observable<Point>;
      locationPos?: ko.Observable<string>;
      enable?: MaybeKO<boolean>;
      isMapModalClosed?: KnockoutObservable<boolean>;
      mapCenterLocation?: KnockoutObservable<Point>;
    },
    componentInfo: ko.components.ComponentInfo
  ) {
    super(params, componentInfo);
    this.isMapModalClosed = params.isMapModalClosed;
    this.locationPos = params.locationPos || FormLocationMapPoint.posObservable();

    let changing = false;
    let onValueChanged = (value: Point | null) => {
      if (changing) {
        return;
      }

      changing = true;
      if (value) {
        this.locationPos(value.lat + ', ' + value.lng);
      } else {
        this.locationPos('');
      }
      changing = false;
    };

    this.subscriptions.push(this.value.subscribe(onValueChanged));
    this.subscriptions.push(
      this.locationPos.subscribe((value) => {
        if (changing) {
          return;
        }

        changing = true;
        if (this.locationPos.isValid()) {
          this.value(FormLocationMapPoint.asPoint(this.locationPos));
        }
        changing = false;
      })
    );
    if (params.mapCenterLocation !== undefined) {
      this.mapCenterLocation = params.mapCenterLocation();
      this.subscriptions.push(
        params.mapCenterLocation.subscribe((value) => {
          this.mapCenterLocation = value
        })
      );
    }

    onValueChanged(this.value());
  }

  static createViewModel(
    params: { value: ko.Observable<Point>; enable?: ko.Observable<boolean>, isMapModalClosed?: ko.Observable<boolean> },
    componentInfo: ko.components.ComponentInfo
  ) {
    return new FormLocationMapPoint(params, componentInfo);
  }

  static posObservable(): ko.Observable<string> {
    return ko.observable('').extend({ geoPt: true });
  }

  static asPoint(locationPos: ko.Observable<string>): Point {
    if (locationPos.isValid()) {
      let value = locationPos().trim();
      if (value) {
        let [lat, lng] = value.split(',', 2);
        return { lat: parseFloat(lat), lng: parseFloat(lng) };
      }
    }

    return null;
  }
}

class PrimaryButton {
  disabled: KnockoutObservable<boolean>;

  constructor(params: { disabled: KnockoutObservable<boolean> }) {
    this.disabled = params.disabled || ko.observable(false);
  }
}

class AddButton extends PrimaryButton {}

class OutlinedButton extends PrimaryButton {}

class FlatButton {
  icon: MaybeKO<string>;
  enabled: KnockoutObservable<boolean>;

  constructor(params: { icon: MaybeKO<string>; enabled?: KnockoutObservable<boolean> }) {
    this.icon = params.icon;
    this.enabled = params.enabled === undefined ? ko.observable(true) : asObservable(params.enabled);
  }
}

class SaveButton {
  saving: KnockoutObservable<boolean>;

  constructor(params: { saving: KnockoutObservable<boolean> }) {
    this.saving = params.saving;
  }
}

class AppButton extends PrimaryButton {}

class GooglePlay {
  playStoreURL = SERVER_INFO.PLAY_STORE_URL;
}

class AppStore {
  appStoreURL = SERVER_INFO.APP_STORE_URL;
}

ko.components.register('qt-popup', { template: popupTemplate });
ko.components.register('loading-indicator', {
  viewModel: LoadingIndicator,
  template: loadingIndicatorTemplate,
});
ko.components.register('google-play', {
  viewModel: GooglePlay,
  template: googlePlayTemplate,
});
ko.components.register('app-store', {
  viewModel: AppStore,
  template: appStoreTemplate,
});
ko.components.register('info', { template: infoTemplate });
ko.components.register('actions-menu', {
  viewModel: ActionsMenu,
  template: actionsMenuTemplate,
});

ko.components.register('primary-button', {
  viewModel: PrimaryButton,
  template: primaryButtonTemplate,
});
ko.components.register('add-button', {
  viewModel: AddButton,
  template: addButtonTemplate,
});
ko.components.register('secondary-button', {
  template: secondaryButtonTemplate,
});
ko.components.register('flat-button', {
  viewModel: FlatButton,
  template: flatButtonTemplate,
});
ko.components.register('flat-button-solid', {
  viewModel: FlatButton,
  template: flatButtonSolidTemplate,
});
ko.components.register('save-button', {
  viewModel: SaveButton,
  template: saveButtonTemplate,
});
ko.components.register('app-button', {
  viewModel: AppButton,
  template: appButtonTemplate,
});
ko.components.register('outlined-button', {
  viewModel: OutlinedButton,
  template: outlinedButtonTemplate,
});

ko.components.register('form-text-input', {
  viewModel: { createViewModel: FormValueInput.createViewModel },
  template: formTextInputTemplate,
});
ko.components.register('form-textarea', {
  viewModel: { createViewModel: FormValueInput.createViewModel },
  template: formTextAreaTemplate,
});
ko.components.register('form-checkbox', {
  viewModel: { createViewModel: FormValueInput.createViewModel },
  template: formCheckboxTemplate,
});
ko.components.register('form-select', {
  viewModel: { createViewModel: FormSelectInput.createViewModel },
  template: formSelectTemplate,
});
ko.components.register('form-date-input', {
  viewModel: { createViewModel: FormValueInput.createViewModel },
  template: formDateInputTemplate,
});
ko.components.register('actual-form-text-input', {
  viewModel: { createViewModel: FormValueInput.createViewModel },
  template: actualFormTextInputTemplate,
});
ko.components.register('file-upload', {
  viewModel: FileUpload,
  template: fileUploadTemplate,
});
ko.components.register('form-location-map', {
  viewModel: { createViewModel: FormLocationMap.createViewModel },
  template: formLocationMapTemplate,
});
ko.components.register('form-location-map-point', {
  viewModel: { createViewModel: FormLocationMapPoint.createViewModel },
  template: formLocationMapPointTemplate,
});
