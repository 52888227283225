import * as ko from 'knockout';
import page from 'page';
import { viewImages } from '../../components/view_image';
import { LANGUAGES } from '../../i18n_text';

import i18n from '../../i18n';
import { OverviewTableView, OverviewTableViewDelegate } from '../../screens/trial_facts';
import { session } from '../../session';
import { clearConfirmNavigation, confirmNavigation } from '../../utils/routes';
import { fetchDataEntryOverview } from '../data_entry_api';
import { list as listUnits, UnitData } from '../../api/units';
import { DataEntryBarDelegate } from './data_entry_bar';
import { openDataEntryEditPictures } from './data_entry_edit_pictures';
import {
  DataEntryEditModel,
  DataEntryEditModelSubscriber,
  DataEntryMoveDirection,
  MultiPicturesData,
  MultiPicturesModel,
  OverviewSparseData,
} from './data_entry_edit_model';
import { showSelectOptional, showSelectOptionalTraits } from './data_entry_select_optional';
import { deepEquals } from '../../utils/deep_equals';
import { scrollToFirstEmptyChild } from '../../utils/data_entry';
import { openQRCodeScanner } from '../../components/qr_code_scanner';
import { app } from '../../app';
import { openPlotNotFoundRedirect } from './plot_not_found_redirect';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { FormSelectSearchConfiguration } from '../../components/form_select_search';

const template = require('raw-loader!./data_entry_edit.html').default;

interface DataLanguage {
  id?: string | KnockoutObservable<string>;
  code: string;
  name: string;
  translated_name: string;
}

class DataEntryEdit
  implements OverviewTableViewDelegate, DataEntryBarDelegate, DataEntryEditModelSubscriber
{
  readOnly: boolean = false;
  trialName = ko.observable('');
  loading = ko.observable(true);
  overviewTableView = new OverviewTableView(this, true);
  units: UnitData[];
  measurementMetasUnits: Record<number, number>;
  applyLanguageSettingsBtnEnable = ko.observable(true);

  editModel: ko.Observable<DataEntryEditModel | null> = ko.observable(null);
  mobileHidden = ko.observable(false);
  private selectedOptional = new Set<string>();
  private selectedOptionalTraits = new Set<string>();

  selectedDataLanguage = ko.observable<DataLanguage>(null);

  canEditDataLanguage = ko.pureComputed(() => true);

  private getDataLanguageByCode(languageCode: string) {
    let foundLanguage = LANGUAGES.find((language) => language[0] === languageCode);

    return foundLanguage
      ? {
          id: languageCode,
          code: languageCode,
          name: foundLanguage[1],
          translated_name: foundLanguage[2],
        }
      : null;
  }

  dataLanguageSearchConfig = ko.pureComputed(() => {
    let res: FormSelectSearchConfiguration<DataLanguage> = {
      getSummaryName: (dataLanguage) => dataLanguage.name,
      list: () => {
        return new Promise((resolve, reject) => {
          resolve(LANGUAGES.map((el) => ({ id: el[0], code: el[0], name: el[1], translated_name: el[2] })));
        });
      },
      entity: this.selectedDataLanguage,
    };

    return res;
  });

  constructor(
    private params: {
      trialId: string;
      svId: string;
      siteId: string;
      visitId: string;
      excludeMobile?: string;
    }
  ) {
    this.reload(async () => {
      const unitData = await listUnits({});
      this.units = unitData;
    });
    this.mobileHidden(params.excludeMobile === 'true' ? true : false);
    this.measurementMetasUnits = {};

    confirmNavigation(
      i18n.t(
        'There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?'
      )(),
      () => this.editModel()?.hasUnsavedChanges() ?? false
    );
  }

  dispose() {
    clearConfirmNavigation();
    this.overviewTableView.dispose();
    this.editModel()?.unsubscribe(this);
    this.editModel()?.dispose();
    this.editModel(null);
  }

  async reload(onDone?: () => Promise<void>) {
    this.loading(true);

    this.editModel()?.unsubscribe(this);
    this.editModel()?.dispose();
    this.editModel(null);

    const optionalDimIds: string[] = [];
    const optionalTraitIds: string[] = [];
    this.selectedOptional.forEach((v) => optionalDimIds.push(v));
    this.selectedOptionalTraits.forEach((v) => optionalTraitIds.push(v));

    const unitFilters: Record<string, number> = {};

    for (let [key, value] of Object.entries(this.overviewTableView.delegate.measurementMetasUnits || {})) {
      unitFilters[`${key}_unit`] = value;
    }

    this.selectedDataLanguage(this.getDataLanguageByCode(localStorage.getItem('dataLanguage')));
    const data = await fetchDataEntryOverview(
      this.params.trialId,
      {
        sv_id: this.params.svId,
        visit_id: this.params.visitId,
        site_id: this.params.siteId,
        optional_dim_ids: optionalDimIds,
        optional_traits_ids: optionalTraitIds,
        exclude_mobile: this.params.excludeMobile,
        language_code: this.selectedDataLanguage() ? this.selectedDataLanguage().code : null,
      },
      unitFilters
    );

    this.trialName(data.trial_name);
    this.editModel(new DataEntryEditModel(this.params, data, new OverviewSparseData(data)));

    onDone && (await onDone());
    this.loading(false);

    this.overviewTableView.clearStructure();
    // wait for table to be rendered
    this.editModel().subscribe(this);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const plotId = urlSearchParams.get('plot_id');
    if (plotId) this.scrollToPlot(plotId);
  }

  applyLanguageSettings() {
    localStorage.setItem(
      'dataLanguage',
      this.selectedDataLanguage() ? this.selectedDataLanguage().code : null
    );
    this.reload();
  }

  onDataEntryEditModelChanged(edit: DataEntryEditModel, changeOriginator: string): void {
    this.overviewTableView.updateDataContents(
      this.editModel().data,
      this.editModel().sparseData,
      false,
      this.editModel()
    );

    // Disable the change language button if the value was edited either from
    // the top bar or from cell
    if (changeOriginator === 'bar' || (changeOriginator === '' && Object.keys(edit.changed).length > 0)) {
      this.applyLanguageSettingsBtnEnable(false);
    }

    if (changeOriginator == 'extraSave') {
      this.reload();
    }
  }

  onDataEntryEditModelSaved(edit: DataEntryEditModel): void {
    this.applyLanguageSettingsBtnEnable(true);
  }

  onToggleMobile = () => {
    const params = new URLSearchParams(window.location.href);
    params.has('exclude_mobile') ? params.delete('exclude_mobile') : params.set('exclude_mobile', 'true');
    const sanitizedURL: string = sanitizeUrl(params.toString());
    window.location.href = decodeURIComponent(sanitizedURL);
  };
  onSelectOptional = async () => {
    const optional = await showSelectOptional(this.params.trialId, this.selectedOptional);
    if (deepEquals(optional, this.selectedOptional)) {
      return;
    }

    this.selectedOptional = optional;

    this.loading(true);
    await this.editModel().saveNow();
    await this.reload();
  };

  onScanQR = () => {
    openQRCodeScanner((uuid) => this.scrollToPlot(uuid));
  };

  onSelectOptionalTraits = async () => {
    const optional = await showSelectOptionalTraits(this.params.trialId, this.selectedOptionalTraits);
    if (deepEquals(optional, this.selectedOptionalTraits)) {
      return;
    }

    this.selectedOptionalTraits = optional;
    this.loading(true);
    await this.editModel().saveNow();
    await this.reload();
  };

  onImport = () => {
    page(
      session.toTenantPath(
        `/trials/${this.params.trialId}/import_observations/?data_entry=1&sv_id=${this.params.svId}&site_id=${this.params.siteId}&visit_id=${this.params.visitId}`
      )
    );
  };

  goBack = () => {
    page(session.toTenantPath(`/data_entry/visits/${this.params.trialId}/`));
  };

  scrollToPlot(plotId: string): void {
    const rows = this.editModel().data.rows;
    const index = rows.indexOf(rows.find((r) => r.plot_id === plotId));

    if (index === -1) {
      openPlotNotFoundRedirect(plotId);
      return;
    } else {
      // closing qr code popup if plotId is found
      app.formsStackController.pop();
    }

    setTimeout(() => {
      const scrollableElement = document.querySelector('div.overview-table-container');
      scrollableElement.scrollTop = index * 30;
      scrollableElement.scrollLeft = 0;
    }, 50);

    setTimeout(() => {
      const plotRow = document.querySelector(`tr[data-plot-id="${plotId}"]`);
      plotRow.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      setTimeout(() => scrollToFirstEmptyChild(document.querySelector(`tr[data-plot-id="${plotId}"]`)), 50);
    }, 200);
  }

  onObservation(rowIdx: number, colIdx: number): void {
    this.editModel().select(rowIdx, colIdx);
    this.overviewTableView.focusInput();
  }

  onMove(direction: DataEntryMoveDirection): void {
    this.editModel().move(direction);
    this.overviewTableView.focusInput();
  }

  getValueUnitId(): number | null {
    const measurementMetaId = this.editModel().data.mm_ids[
      this.editModel().selectedCol()
    ] as unknown as number;
    return this.measurementMetasUnits[measurementMetaId] || null;
  }

  onEditInline(value: string): void {
    const valueUnitId = this.getValueUnitId();
    this.editModel().setValue(value, '', valueUnitId);
  }

  onBarValue(value: {}, originator: string): void {
    const valueUnitId = this.getValueUnitId();
    this.editModel().setValue(value, originator, valueUnitId);
  }

  onEditExtras = async () => {
    const title = i18n.t('Pictures and comments')();
    await openDataEntryEditPictures(
      title,
      this.editModel(),
      this.editModel().extraPictureModel(),
      parseInt(this.editModel().data.mm_ids[this.editModel().selectedCol()]),
      i18n.t('Add pictures and documents')()
    );
  };

  onPictureClick(url: string) {
    if (url) {
      viewImages([url]);
    }
  }

  onEditMultiPictures = async () => {
    if (this.editModel().selectedType() !== 'multi_pictures') {
      return;
    }

    const value = this.editModel().selectedValue() as MultiPicturesData[];
    await openDataEntryEditPictures(
      this.editModel().selectedTitle(),
      this.editModel(),
      new MultiPicturesModel(value),
      parseInt(this.editModel().data.mm_ids[this.editModel().selectedCol()]),
      i18n.t('Add pictures')()
    );
  };
}

export const dataEntryEdit = {
  name: 'data-entry-edit',
  viewModel: DataEntryEdit,
  template,
};

ko.components.register(dataEntryEdit.name, dataEntryEdit);
