import page from 'page';
import { session } from '../session';
import { app } from '../app';
import { requestWithStatus, requestRawWithStatus, requestWithStatusRetry } from './base_request';

export interface ValidationResult<T extends object = {}> {
  isValid: boolean;
  entityId: string;
  errors: any;
  originalResponse?: T;
}

export interface RemoveResult {
  did_delete: boolean;
  dependant_records: { entity_type: string; entities: string[] }[];
}

export interface ListRequestParams {
  offset?: number;
  limit?: number;
  name_prefix?: string;
  role?: 'viewer' | 'editor' | 'head';
  search?: string;
}
export interface UserListRequestParams {
  offset?: number;
  limit?: number;
  name_prefix?: string;
  country?: string;
  role?: 'viewer' | 'editor' | 'head';
}

function onErrorRetry<T>(req: () => Promise<T>): Promise<T> {
  return req().catch((obs: (cb: (retry: boolean) => void) => void) => {
    if (!obs) {
      return Promise.reject(null);
    }

    return new Promise((accept, reject) => {
      let re = (obs: (cb: (retry: boolean) => void) => void) => {
        if (!obs) {
          return Promise.reject(null);
        }

        return new Promise(() => {
          obs((retry) => {
            if (retry) {
              req().then(accept, re);
            } else {
              reject(new Error('request'));
            }
          });
        });
      };

      obs((retry) => {
        if (retry) {
          req().then(accept, re);
        } else {
          reject(new Error('request'));
        }
      });
    });
  });
}

function handleErrorResponse(res: { status: number; result: {} }) {
  if (res.status == 401) {
    if (res.result && (<any>res.result).detail === 'expired') {
      page.redirect(session.toTenantPath('/subscription/'));
    } else {
      app.showLoggedOut(true);
    }
  } else if (res.status == 404) {
    app.showEntityNotFound(true);
  } else if (res.status !== 403) {
    return Promise.reject(app.showServerError);
  }

  return Promise.reject(null);
}

export interface RequestOptions {
  injectTenant: boolean;
  skipToken?: boolean;
  version?: number;
  skipErrorResponseHandling?: boolean;
  onHeadersReceived?: (headers: string) => void;
}

export function request<ResultType>(
  method: string,
  path: string,
  params?: {},
  options?: RequestOptions,
  timeout?: number
): Promise<ResultType> {
  if (!options || options.injectTenant) {
    path = injectTenantInURL(path, options?.version ?? 1);
  }
  function handleResponse(res: any, options: RequestOptions) {
    if (res.headers && options?.onHeadersReceived) {
      options.onHeadersReceived(res.headers);
    }
    if (res.status == 200 || res.status == 201 || res.status == 204) {
      return res.result;
    } else {
      if (options?.skipErrorResponseHandling) {
        return res.result;
      } else {
        return handleErrorResponse(res);
      }
    }
  }
  return onErrorRetry(() => {
    if (options?.skipToken) {
      return requestWithStatus<ResultType>(method, path, undefined, params, timeout).then(
        (res) => {
          return handleResponse(res, options);
        },
        () => Promise.reject(app.showNetworkError)
      );
    } else {
      return session
        .getToken()
        .then((token) => requestWithStatus<ResultType>(method, path, token, params, timeout))
        .then(
          (res) => {
            return handleResponse(res, options);
          },
          () => Promise.reject(app.showNetworkError)
        );
    }
  });
}

export function requestBackgroundRetry<ResultType>(
  method: string,
  path: string,
  params?: {},
  options?: { injectTenant: boolean },
  timeout?: number
): Promise<ResultType> {
  if (!options || options.injectTenant) {
    path = injectTenantInURL(path);
  }

  return onErrorRetry(() =>
    session
      .getToken()
      .then((token) => requestWithStatusRetry<ResultType>(method, path, token, params, timeout))
      .then(
        (res) => {
          if (res.status == 200 || res.status == 201) {
            return res.result;
          } else {
            return handleErrorResponse(res);
          }
        },
        () => Promise.reject(app.showNetworkError)
      )
  );
}

export function requestRaw(
  method: string,
  path: string,
  params?: {},
  options?: { injectTenant: boolean; version?: number }
): Promise<Blob> {
  if (!options || options.injectTenant) {
    path = injectTenantInURL(path, options?.version ?? 1);
  }

  return onErrorRetry(() =>
    session
      .getToken()
      .then((token) => requestRawWithStatus(method, path, token, params))
      .then(
        (res) => {
          if (res.status == 200) {
            return res.data;
          } else {
            return handleErrorResponse({ status: res.status, result: {} });
          }
        },
        () => Promise.reject(app.showNetworkError)
      )
  );
}

/**
 * Make a request and return a ValidationResult object.
 * @param {str} method : HTTP method
 * @param {str} path : URL path
 * @param {object} params : Request body
 * @template {object} T : type of the original response. If provided, the original
 * response will be returned in the `originalResponse` field of the result.
 * @returns : A promise that resolves to a ValidationResult object.
 */
export function requestWithValidation<T extends object = {}>(
  method: string,
  path: string,
  params?: {},
  version?: number
): Promise<ValidationResult<T>> {
  return requestAnyWithValidation(method, path, params, version).then((val) => {
    if (val.status == 200 || val.status == 201) {
      return { isValid: true, entityId: val.result.id, errors: {}, originalResponse: val.result as T };
    } else {
      return { isValid: false, entityId: null, errors: val.result };
    }
  });
}

export function requestAnyWithValidation(
  method: string,
  path: string,
  params?: {},
  version?: number
): Promise<{ status: number; result: any }> {
  return onErrorRetry(() =>
    session
      .getToken()
      .then((token) => requestWithStatus<any>(method, injectTenantInURL(path, version), token, params))
      .then(
        (val) => {
          if (val.status == 200 || val.status == 201 || val.status == 400) {
            return val;
          }

          return handleErrorResponse(val);
        },
        () => Promise.reject(app.showNetworkError)
      )
  );
}

function handleRemoveResponse(response: { status: number; result: any }): RemoveResult | Promise<never> {
  if (response.status == 200 || response.status == 204) {
    return { did_delete: true, dependant_records: [] };
  }

  if (response.status == 400) {
    return { did_delete: false, dependant_records: response.result as any };
  }

  return handleErrorResponse(response);
}

export function requestRemoveEntity(path: string): Promise<RemoveResult> {
  return onErrorRetry(() =>
    session
      .getToken()
      .then((token) => requestWithStatus('DELETE', injectTenantInURL(path), token, undefined))
      .then(handleRemoveResponse, () => Promise.reject(app.showNetworkError))
  );
}

export function requestBulkRemoveEntities(
  path: string,
  options?: {
    version?: number;
  }
): Promise<RemoveResult> {
  return onErrorRetry(() =>
    session
      .getToken()
      .then((token) => requestWithStatus('DELETE', injectTenantInURL(path, options?.version), token))
      .then(handleRemoveResponse, () => Promise.reject(app.showNetworkError))
  );
}

export function listRequest<Data>(
  baseURL: string,
  params: ListRequestParams,
  options?: RequestOptions
): Promise<Data[]> {
  return requestWithGetParams(baseURL, params, options);
}

export function requestWithGetParams<Data>(
  baseURL: string,
  params: {},
  options?: RequestOptions
): Promise<Data> {
  let url = baseURL.indexOf('?') === -1 ? baseURL + '?' : baseURL;

  return request('GET', url + listParamsToQueryString(params), undefined, options);
}

export function listParamsToQueryString(params: { [key: string]: Object }) {
  let queryString = '';
  let add = (key: string, value: {}) => {
    if (value !== null && value !== undefined && value !== '') {
      queryString += '&' + key + '=' + encodeURIComponent(value.toString());
    }
  };

  for (let key in params) {
    if (!params.hasOwnProperty(key)) {
      continue;
    }

    let value = params[key];
    if (value instanceof Array) {
      for (let item of value) {
        add(key, item);
      }
    } else {
      add(key, value);
    }
  }

  return queryString;
}

export function injectTenantInURL(url: string, version: number = 1) {
  let tenant = session.tenant();
  let prefix = version === 1 ? '/api/' : '/api/v2/';
  const tenantPart = version === 1 ? 't/' : 'tenants/';

  if (tenant && url.indexOf(prefix) === 0) {
    return prefix + tenantPart + tenant.slug + '/' + url.substr(prefix.length);
  } else {
    return url;
  }
}

export interface GetOptions {
  tenantId?: string;
  publicList?: boolean;
  getParams?: {};
}

export function makeList<TRet, TParam = {}>(endpointSegment: string) {
  return (
    params: ListRequestParams & TParam,
    options?: GetOptions,
    requestOptions?: RequestOptions
  ): Promise<TRet[]> => {
    if (options && options.tenantId && options.publicList) {
      return listRequest('/api/t/' + options.tenantId + '/' + endpointSegment + '/list_public/', params, {
        injectTenant: false,
        skipToken: options.publicList,
        ...requestOptions,
      });
    } else if (options && options.tenantId) {
      return listRequest('/api/t/' + options.tenantId + '/' + endpointSegment + '/', params, {
        injectTenant: false,
        ...requestOptions,
      });
    } else {
      return listRequest('/api/' + endpointSegment + '/', params, {
        injectTenant: true,
        ...requestOptions,
      });
    }
  };
}

export function makeRetrieve<T>(endpointSegment: string) {
  return (id: string, options?: GetOptions): Promise<T> => {
    if (options && options.tenantId && options.publicList) {
      return request(
        'GET',
        '/api/t/' + options.tenantId + '/' + endpointSegment + '/' + id + '/public/',
        undefined,
        { injectTenant: false }
      );
    } else if (options && options.tenantId) {
      return request(
        'GET',
        '/api/t/' + options.tenantId + '/' + endpointSegment + '/' + id + '/',
        undefined,
        { injectTenant: false }
      );
    } else {
      let url = `/api/${endpointSegment}/${id}/`;
      if (options?.getParams) {
        url += '?' + new URLSearchParams(options.getParams).toString();
      }
      return request('GET', url);
    }
  };
}

export function makeSave<T extends { id?: string }>(endpointSegment: string) {
  return (data: T, options?: GetOptions): Promise<ValidationResult> => {
    let endpoint = '/api/' + endpointSegment + '/';
    let method = 'POST';
    if (data.id) {
      endpoint += data.id + '/';
      method = 'PUT';
    }
    if (options?.getParams) {
      endpoint += '?' + new URLSearchParams(options.getParams).toString();
    }
    return requestWithValidation(method, endpoint, data);
  };
}

export function makeRemove(endpointSegment: string) {
  return (id: string): Promise<RemoveResult> =>
    requestRemoveEntity('/api/' + endpointSegment + '/' + id + '/');
}

export interface API<T, TParam = {}, TDetail = T> {
  list(
    params: ListRequestParams & TParam & { type?: string },
    options?: GetOptions,
    requestOptions?: RequestOptions
  ): Promise<T[]>;
  retrieve(id: string, options?: GetOptions): Promise<TDetail>;
  save(data: TDetail, options?: GetOptions): Promise<ValidationResult>;
  remove(id: string): Promise<RemoveResult>;
}

export function makeDefaultApi<T extends { id?: string }, TParam = {}, TDetail = T>(
  endpointSegment: string
): API<T, TParam, TDetail> {
  return {
    list: makeList<T, TParam>(endpointSegment),
    retrieve: makeRetrieve<TDetail>(endpointSegment),
    save: makeSave<TDetail>(endpointSegment),
    remove: makeRemove(endpointSegment),
  };
}

/**
 * Parses the Content-Range header to extract the start, end, and total count values.
 *
 * The Content-Range header is expected to be in the format "items start-end/total",
 * where "start" and "end" are the indices of the first and last items in the range,
 * and "total" is the total number of items.
 *
 * @param {string} headers - The response headers as a string, with each header separated by a newline character.
 * @returns {{ start: number; end: number; total: number | null }} An object containing the start, end, and total count values extracted from the Content-Range header.
 * @throws {Error} Throws an error if the Content-Range header is not found or if the header format is incorrect.
 */
export function getContentRange(headers: string): { start: number; end: number; total: number | null } {
  const contentRangeHeader = headers
    .split('\r\n')
    .find((header) => header.toLowerCase().startsWith('content-range:'));
  if (!contentRangeHeader) {
    throw new Error('Content-Range header not found');
  }

  const contentRangeValue = contentRangeHeader.split(': ')[1];
  const [, range, total] = contentRangeValue.split(/(?:items\s)?(\d+-\d+)\/(\d+|\*)/);
  const [start, end] = range.split('-').map(Number);

  return {
    start: start,
    end: end,
    total: total === '*' ? null : parseInt(total, 10),
  };
}
