import * as ko from 'knockout';
import { select } from 'd3-selection';
import { min, max, range } from 'd3-array';
import { scaleLinear, scaleOrdinal } from 'd3-scale';
import { format as d3format } from 'd3-format';
import { axisLeft, axisBottom } from 'd3-axis';

import * as dashboardApi from '../api/dashboard';
import { tryFormatDate } from '../utils';
import { AxisDomain } from 'd3';

const schemeCategory20c = [
  '#3182bd',
  '#6baed6',
  '#9ecae1',
  '#c6dbef',
  '#e6550d',
  '#fd8d3c',
  '#fdae6b',
  '#fdd0a2',
  '#31a354',
  '#74c476',
  '#a1d99b',
  '#c7e9c0',
  '#756bb1',
  '#9e9ac8',
  '#bcbddc',
  '#dadaeb',
  '#636363',
  '#969696',
  '#bdbdbd',
  '#d9d9d9',
];

const BORDER_COLOR = '#D0D0D0';
const THRESHOLD_COLOR = '#f44336';

type Selection<T> = d3.Selection<
  Element | d3.EnterElement | Document | Window,
  T,
  Element | d3.EnterElement | Document | Window,
  {}
>;

export interface ChartConfig {
  scale: number;
  xTitle?: string;
  yTitle?: string;
  threshold?: number; // not supported by radar charts
  data: dashboardApi.ChartData[];
  onClick?: (data: dashboardApi.ChartData) => void; // not supported by radar charts
}

ko.bindingHandlers['chart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<ChartConfig>) => {
    update(select(element), valueAccessor);
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<ChartConfig>) => {
    element.innerHTML = '';
    update(select(element), valueAccessor);
  },
};

function update(svg: Selection<{}>, valueAccessor: () => KnockoutObservable<ChartConfig>) {
  let config = ko.unwrap(valueAccessor());
  let data = config.data;
  let geometry: Geometry = {
    height: 300 * config.scale,
    spacing: 20 * config.scale,
    paddingTop: 20 * config.scale,
    elemWidth: 30 * config.scale,
    paddedElemWidth: (30 + 20 * 2) * config.scale,
    pointRadius: 4,
    errorWidth: 16 * config.scale,
  };

  svg.append('g').attr('class', 'bar');
  svg.append('g').attr('class', 'radar');

  let bar = svg.select('g.bar');
  let radar = svg.select('g.radar');

  (dashboardApi.isRadarDataArray(data) ? bar : radar).selectAll('*').remove();

  if (dashboardApi.isBoxPlotDataArray(data)) {
    render(bar, config, new BoxPlotRenderer(), geometry);
  } else if (dashboardApi.isAverageDataArray(data)) {
    render(bar, config, new AverageRenderer(), geometry);
  } else if (dashboardApi.isBarDataArray(data)) {
    render(bar, config, new BarRenderer(), geometry);
  } else if (dashboardApi.isScatterDataArray(data)) {
    render(bar, config, new ScatterRenderer(), geometry);
  } else if (dashboardApi.isRadarDataArray(data)) {
    renderRadar(radar, data, geometry);
  }

  fitSVG(svg, data, geometry);
}

function fitSVG(svg: Selection<{}>, data: dashboardApi.ChartData[], geometry: Geometry) {
  // resize the SVG so it fits all the content
  let bbox = (<SVGAElement>svg.node()).getBBox();
  if (data.length > 0) {
    svg
      .attr('width', bbox.x + bbox.width + geometry.paddingTop)
      .attr('height', bbox.y + bbox.height + geometry.paddingTop);
  } else {
    svg.attr('width', 0).attr('height', 0);
  }
}

interface Renderer<T> {
  minY(_: T): number;
  maxY(_: T): number;

  x?(_: T): number;

  formatXAxisLabel(_: T): string;
  key(_: T): string;
  renderPoint(
    data: dashboardApi.ChartData[],
    enter: Selection<T>,
    merge: Selection<T>,
    scaleY: d3.ScaleLinear<number, number>,
    scaleX: d3.ScaleLinear<number, number>,
    geometry: Geometry
  ): void;
}

interface Geometry {
  height: number;
  spacing: number;
  paddingTop: number;
  elemWidth: number;
  paddedElemWidth: number;
  pointRadius: number;
  errorWidth: number;
}

function groupOffset(data: dashboardApi.ChartData, geometry: Geometry): number {
  if (dashboardApi.isBarData(data) && hasGroupIdx(data)) {
    return (data.group_idx - 1) * geometry.spacing * 2 + geometry.spacing;
  } else {
    return 0;
  }
}

function getElemWidth(data: dashboardApi.ChartData[] | dashboardApi.ChartData, geometry: Geometry): number {
  let item = getFirstElem(data);
  if (item && hasGroupIdx(item)) {
    return geometry.elemWidth;
  } else {
    return geometry.paddedElemWidth;
  }
}

function getTotalWidth(data: dashboardApi.ChartData[], geometry: Geometry): number {
  if (dashboardApi.isScatterDataArray(data)) {
    return geometry.height;
  }

  if (dashboardApi.isBarDataArray(data)) {
    let nGroups = max(data, (d) => d.group_idx) || 0;
    return data.length * getElemWidth(data, geometry) + nGroups * geometry.spacing * 2;
  } else {
    return data.length * getElemWidth(data, geometry);
  }
}

function getPadding(data: dashboardApi.ChartData[] | dashboardApi.ChartData, geometry: Geometry): number {
  let item = getFirstElem(data);
  return hasGroupIdx(item) ? 0 : geometry.spacing;
}

function hasGroupIdx(data: dashboardApi.ChartData): boolean {
  return dashboardApi.isBarData(data) && data.group_idx !== undefined && data.group_idx !== null;
}

function getFirstElem(data: dashboardApi.ChartData[] | dashboardApi.ChartData): dashboardApi.ChartData {
  if (data instanceof Array) {
    return data.length > 0 ? data[0] : undefined;
  } else {
    return data;
  }
}

function render(
  container: Selection<{}>,
  config: ChartConfig,
  renderer: Renderer<dashboardApi.ChartData>,
  geometry: Geometry
) {
  let data = config.data;
  let height = geometry.height;
  let elemWidth = getElemWidth(data, geometry);
  let totalWidth = getTotalWidth(data, geometry);
  let spacing = geometry.spacing;
  let paddingTop = geometry.paddingTop;

  let minY = min(data, renderer.minY);
  let maxY = max(data, renderer.maxY);
  // ensure the Y scale is not empty (happens when there's only 1 value)
  if (Math.abs(minY - maxY) < 0.0000001) {
    minY--;
    maxY++;
  }
  let scaleY = scaleLinear().domain([minY, maxY]).range([height, 0]);

  let linearScaleX: d3.ScaleLinear<number, number> = null;
  let scaleX: d3.AxisScale<AxisDomain>;
  if (renderer.x) {
    let minX = min(data, renderer.x);
    let maxX = max(data, renderer.x);
    // ensure the X scale is not empty (happens when there's only 1 value)
    if (Math.abs(minX - maxX) < 0.0000001) {
      minX--;
      maxX++;
    }
    linearScaleX = scaleX = scaleLinear().domain([minX, maxX]).range([0, totalWidth]);
  } else {
    let range = [0]
      .concat(data.map((d, index) => groupOffset(d, geometry) + index * elemWidth + elemWidth / 2))
      .concat([totalWidth]);
    let domain = [''].concat(data.map(renderer.formatXAxisLabel)).concat(['']);
    scaleX = scaleOrdinal(range).domain(domain);
  }

  // Y axis

  container.selectAll('g.y-axis').data([null]).enter().append('g').attr('class', 'y-axis');

  let yAxis = container.select('.y-axis');
  yAxis.call(axisLeft(scaleY).tickSize(-totalWidth));
  yAxis.selectAll('text').attr('x', -10).attr('fill', 'rgba(0, 0, 0, 0.87)');
  yAxis.selectAll('line').attr('stroke', BORDER_COLOR);
  yAxis.selectAll('path').attr('stroke', BORDER_COLOR);
  yAxis.selectAll('.tick line').attr('stroke-dasharray', '10 10').attr('shape-rendering', 'crispEdges');
  yAxis.select('.domain').remove();

  // X axis

  container.selectAll('g.x-axis').data([null]).enter().append('g').attr('class', 'x-axis');
  let xAxis = container.select('.x-axis').call(axisBottom(scaleX));
  xAxis
    .selectAll('text')
    .attr('x', 6)
    .attr('y', 12)
    .attr('fill', 'rgba(0, 0, 0, 0.87)')
    .attr('transform', 'rotate(30)')
    .style('text-anchor', 'start');
  xAxis.selectAll('line').attr('stroke', BORDER_COLOR);
  xAxis.selectAll('path').attr('stroke', BORDER_COLOR);

  // main container

  let boxUpdate = container.selectAll('g.point').data(data, renderer.key);
  let boxEnter = boxUpdate.enter().append('g').attr('class', 'point');
  let boxMerge = boxUpdate.merge(boxEnter);
  boxUpdate.exit().remove();

  renderer.renderPoint(data, boxEnter, boxMerge, scaleY, linearScaleX, geometry);

  // ensure the axis labels fit within the SVG
  let yBBox = (<SVGAElement>yAxis.node()).getBBox();
  let yAxisWidth = yBBox.width - totalWidth + (config.yTitle ? 28 : 0);
  yAxis.attr('transform', 'translate(' + yAxisWidth + ',' + paddingTop + ')');
  boxMerge.attr('transform', 'translate(' + yAxisWidth + ',' + paddingTop + ')');
  xAxis.attr('transform', 'translate(' + yAxisWidth + ', ' + (height + paddingTop + spacing) + ')');

  // threshold line
  if (config.threshold != null) {
    boxEnter.append('line').attr('class', 'threshold').attr('stroke', THRESHOLD_COLOR);
    boxMerge
      .select('line.threshold')
      .attr('x1', 0)
      .attr('y1', scaleY(config.threshold))
      .attr('x2', totalWidth)
      .attr('y2', scaleY(config.threshold));
  }

  // X axis title

  let xBBox = (<SVGAElement>xAxis.node()).getBBox();
  let xTitleUpdate = container.selectAll('text.x-title').data(config.xTitle ? [config.xTitle] : []);
  xTitleUpdate
    .enter()
    .append('text')
    .attr('class', 'x-title')
    .attr('font-size', 14)
    .attr('dy', 14 * 1.5)
    .attr(
      'transform',
      'translate(' +
        (yAxisWidth + totalWidth / 2) +
        ',' +
        (height + paddingTop + spacing + xBBox.height) +
        ')'
    )
    .style('text-anchor', 'middle')
    .text((d) => d);
  xTitleUpdate.exit().remove();

  // Y axis title

  let yTitleUpdate = container.selectAll('text.y-title').data(config.yTitle ? [config.yTitle] : []);
  yTitleUpdate
    .enter()
    .append('text')
    .attr('class', 'y-title')
    .attr('transform', 'rotate(-90)')
    .attr('font-size', 14)
    .attr('x', -(paddingTop + height / 2))
    .attr('y', 14)
    .style('text-anchor', 'middle')
    .text((d) => d);
  yTitleUpdate.exit().remove();

  // events

  boxEnter.on('mouseover', function (d, index) {
    select(this).selectAll('text.label').attr('visibility', 'visible');
  });
  boxEnter.on('mouseleave', function () {
    select(this).selectAll('text.label').attr('visibility', 'hidden');
  });
  boxEnter.on('click', (d, index) => config.onClick?.(data[index]));
}

function addLabel<T extends dashboardApi.ChartData>(
  data: dashboardApi.ChartData[],
  enter: Selection<T>,
  merge: Selection<T>,
  scaleY: d3.ScaleLinear<number, number>,
  name: string,
  value: (d: T) => number,
  reverse: boolean,
  geometry: Geometry
) {
  let format = d3format('.3');
  let elemWidth = getElemWidth(data, geometry);

  enter
    .append('text')
    .attr('class', 'label ' + name + '-label')
    .attr('font-size', 12)
    .attr('fill', APP_CONFIG.ACCENT_COLOR);
  merge
    .select('text.' + name + '-label')
    .attr('x', x(reverse ? geometry.spacing : elemWidth - geometry.spacing, geometry))
    .attr('y', (d) => scaleY(value(d)))
    .attr('dx', reverse ? '-0.5em' : '0.5em')
    .attr('dy', '0.4em')
    .attr('text-anchor', reverse ? 'end' : 'start')
    .attr('visibility', 'hidden')
    .text((d) => format(value(d)));
}

function formatXAxisLabel(names: string[]) {
  return names
    .map(tryFormatDate)
    .filter((value) => !!value)
    .join(', ');
}

class BoxPlotRenderer implements Renderer<dashboardApi.BoxPlotData> {
  minY(d: dashboardApi.BoxPlotData) {
    return d.outliers.length > 0 ? Math.min(d.outliers[0], d.bottom_whisker) : d.bottom_whisker;
  }

  maxY(d: dashboardApi.BoxPlotData) {
    return d.outliers.length > 0
      ? Math.max(d.outliers[d.outliers.length - 1], d.top_whisker)
      : d.top_whisker;
  }

  key(d: dashboardApi.BoxPlotData) {
    return 'box-plot' + d.names.join('-');
  }

  formatXAxisLabel(d: dashboardApi.BoxPlotData) {
    return formatXAxisLabel(d.names);
  }

  renderPoint(
    data: dashboardApi.ChartData[],
    enter: Selection<dashboardApi.BoxPlotData>,
    merge: Selection<dashboardApi.BoxPlotData>,
    scaleY: d3.ScaleLinear<number, number>,
    scaleX: d3.ScaleLinear<number, number>,
    geometry: Geometry
  ) {
    let elemWidth = geometry.paddedElemWidth;
    let spacing = geometry.spacing;
    let pointRadius = geometry.pointRadius;

    // center line

    enter
      .append('line')
      .attr('class', 'center')
      .attr('stroke', APP_CONFIG.PRIMARY_COLOR)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '4 4');
    merge
      .select('line.center')
      .attr('x1', x(elemWidth / 2, geometry))
      .attr('y1', (d) => scaleY(d.bottom_whisker))
      .attr('x2', x(elemWidth / 2, geometry))
      .attr('y2', (d) => scaleY(d.top_whisker));

    // IQR box

    enter
      .append('rect')
      .attr('class', 'box')
      .attr('stroke', APP_CONFIG.PRIMARY_COLOR_LIGHT)
      .attr('fill', APP_CONFIG.PRIMARY_COLOR_LIGHT);
    merge
      .select('rect.box')
      .attr('x', x(spacing, geometry))
      .attr('y', (d) => scaleY(d.box_top))
      .attr('width', elemWidth - spacing * 2)
      .attr('height', (d) => scaleY(d.box_bottom) - scaleY(d.box_top));

    // median

    enter.append('line').attr('class', 'median').attr('stroke', APP_CONFIG.PRIMARY_COLOR);
    merge
      .select('line.median')
      .attr('x1', x(spacing, geometry))
      .attr('y1', (d) => scaleY(d.median))
      .attr('x2', x(elemWidth - spacing, geometry))
      .attr('y2', (d) => scaleY(d.median));

    // whiskers

    enter.append('line').attr('class', 'whisker whisker-top').attr('stroke', APP_CONFIG.PRIMARY_COLOR);
    merge
      .select('line.whisker-top')
      .attr('x1', x(spacing, geometry))
      .attr('y1', (d) => scaleY(d.bottom_whisker))
      .attr('x2', x(elemWidth - spacing, geometry))
      .attr('y2', (d) => scaleY(d.bottom_whisker));

    enter.append('line').attr('class', 'whisker whisker-bottom').attr('stroke', APP_CONFIG.PRIMARY_COLOR);
    merge
      .select('line.whisker-bottom')
      .attr('x1', x(spacing, geometry))
      .attr('y1', (d) => scaleY(d.top_whisker))
      .attr('x2', x(elemWidth - spacing, geometry))
      .attr('y2', (d) => scaleY(d.top_whisker));

    // outliers

    enter.append('g').attr('class', 'outliers');
    merge.select('g.outliers').each(function (d, index) {
      let update = select(this).selectAll('g.outlier').data(d.outliers);
      let enter = update.enter().append('g').attr('class', 'outlier');
      let merge = update.merge(enter);
      update.exit().remove();

      // outlier point

      enter
        .append('circle')
        .attr('cx', snap(index * elemWidth + elemWidth / 2))
        .attr('r', pointRadius)
        .attr('stroke', APP_CONFIG.PRIMARY_COLOR)
        .attr('stroke-width', 2)
        .attr('fill', 'transparent');
      merge.select('circle').attr('cy', scaleY);

      // outlier label

      enter
        .append('text')
        .attr('class', 'label')
        .attr('visibility', 'hidden')
        .attr('font-size', 12)
        .attr('fill', APP_CONFIG.ACCENT_COLOR);
      merge
        .select('text')
        .attr('x', (_, outlierIndex) =>
          snap(index * elemWidth + elemWidth / 2 + (outlierIndex % 2 == 0 ? -pointRadius : pointRadius))
        )
        .attr('y', scaleY)
        .attr('dx', (_, outlierIndex) => (outlierIndex % 2 == 0 ? '-0.5em' : '0.5em'))
        .attr('dy', '0.4em')
        .attr('text-anchor', (_, outlierIndex) => (outlierIndex % 2 == 0 ? 'end' : 'start'))
        .text((d) => d);
    });

    let add = (name: string, value: (d: dashboardApi.BoxPlotData) => number, reverse: boolean) => {
      addLabel(data, enter, merge, scaleY, name, value, reverse, geometry);
    };

    add('top-whisker', (d) => d.top_whisker, false);
    add('box-top', (d) => d.box_top, true);
    add('median', (d) => d.median, false);
    add('box-bottom', (d) => d.box_bottom, true);
    add('bottom-whisker', (d) => d.bottom_whisker, false);
  }
}

class AverageRenderer implements Renderer<dashboardApi.AverageData> {
  minY(d: dashboardApi.AverageData) {
    return d.average - d.stddev;
  }

  maxY(d: dashboardApi.AverageData) {
    return d.average + d.stddev;
  }

  key(d: dashboardApi.AverageData) {
    return 'average' + d.names.join('-');
  }

  formatXAxisLabel(d: dashboardApi.AverageData) {
    return formatXAxisLabel(d.names);
  }

  renderPoint(
    data: dashboardApi.ChartData[],
    enter: Selection<dashboardApi.AverageData>,
    merge: Selection<dashboardApi.AverageData>,
    scaleY: d3.ScaleLinear<number, number>,
    scaleX: d3.ScaleLinear<number, number>,
    geometry: Geometry
  ) {
    let elemWidth = geometry.paddedElemWidth;
    let errorWidth = geometry.errorWidth;
    let pointRadius = geometry.pointRadius;

    // error line

    enter
      .append('line')
      .attr('class', 'error error-vertical')
      .attr('stroke', APP_CONFIG.PRIMARY_COLOR_LIGHT)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '4 4');
    merge
      .select('line.error-vertical')
      .attr('x1', x(elemWidth / 2, geometry))
      .attr('y1', (d) => scaleY(d.average - d.stddev))
      .attr('x2', x(elemWidth / 2, geometry))
      .attr('y2', (d) => scaleY(d.average + d.stddev));

    // error line - bottom

    enter.append('line').attr('class', 'error error-bottom').attr('stroke', APP_CONFIG.PRIMARY_COLOR_LIGHT);
    merge
      .select('line.error-bottom')
      .attr('x1', x(elemWidth / 2 - errorWidth / 2, geometry))
      .attr('y1', (d) => scaleY(d.average - d.stddev))
      .attr('x2', x(elemWidth / 2 + errorWidth / 2, geometry))
      .attr('y2', (d) => scaleY(d.average - d.stddev));

    // error line - top

    enter.append('line').attr('class', 'error error-top').attr('stroke', APP_CONFIG.PRIMARY_COLOR_LIGHT);
    merge
      .select('line.error-top')
      .attr('x1', x(elemWidth / 2 - errorWidth / 2, geometry))
      .attr('y1', (d) => scaleY(d.average + d.stddev))
      .attr('x2', x(elemWidth / 2 + errorWidth / 2, geometry))
      .attr('y2', (d) => scaleY(d.average + d.stddev));

    // average point

    enter
      .append('circle')
      .attr('class', 'average-point')
      .attr('r', pointRadius)
      .attr('stroke', APP_CONFIG.PRIMARY_COLOR)
      .attr('stroke-width', 2)
      .attr('fill', 'transparent');
    merge
      .select('circle.average-point')
      .attr('cx', x(elemWidth / 2, geometry))
      .attr('cy', (d) => scaleY(d.average));

    let add = (name: string, value: (d: dashboardApi.AverageData) => number, reverse: boolean) => {
      addLabel(data, enter, merge, scaleY, name, value, reverse, geometry);
    };

    add('error-top', (d) => d.average + d.stddev, true);
    add('average', (d) => d.average, false);
    add('error-bottom', (d) => d.average - d.stddev, true);
  }
}

class BarRenderer implements Renderer<dashboardApi.BarData> {
  private colors: { [key: string]: string } = {};
  private nextColorIdx = 0;

  color = (d: dashboardApi.BarData): string => {
    if (!hasGroupIdx(d)) {
      return APP_CONFIG.PRIMARY_COLOR_LIGHT;
    }

    let key = d.names.slice(1).join(',');
    if (!this.colors[key]) {
      this.nextColorIdx = (this.nextColorIdx + 1) % schemeCategory20c.length;
      this.colors[key] = schemeCategory20c[this.nextColorIdx];
    }

    return this.colors[key];
  };

  minY(d: dashboardApi.BarData) {
    return 0;
  }

  maxY(d: dashboardApi.BarData) {
    return d.value;
  }

  key(d: dashboardApi.BarData) {
    return 'bar' + d.names.join('-');
  }

  formatXAxisLabel(d: dashboardApi.BarData) {
    return formatXAxisLabel(d.names);
  }

  renderPoint(
    data: dashboardApi.ChartData[],
    enter: Selection<dashboardApi.BarData>,
    merge: Selection<dashboardApi.BarData>,
    scaleY: d3.ScaleLinear<number, number>,
    scaleX: d3.ScaleLinear<number, number>,
    geometry: Geometry
  ) {
    let format = d3format('.3');
    let elemWidth = getElemWidth(data, geometry);
    let padding = getPadding(data, geometry);

    enter.append('rect');
    merge
      .select('rect')
      .attr('stroke', this.color)
      .attr('fill', this.color)
      .attr('x', x(padding, geometry))
      .attr('y', (d) => scaleY(d.value))
      .attr('width', elemWidth - padding * 2)
      .attr('height', (d) => scaleY(0) - scaleY(d.value));

    enter.append('text').attr('class', 'rank-label').attr('text-anchor', 'middle').attr('font-size', 12);
    merge
      .select('text.rank-label')
      .attr('x', x(elemWidth / 2, geometry))
      .attr('y', (d) => scaleY(d.value))
      .attr('dy', '1em')
      .attr('visibility', (d) => (d.label ? 'visible' : 'hidden'))
      .text((d) => d.label);

    enter
      .append('text')
      .attr('class', 'label')
      .attr('text-anchor', 'middle')
      .attr('font-size', 12)
      .attr('fill', APP_CONFIG.ACCENT_COLOR);
    merge
      .select('text.label')
      .attr('x', x(elemWidth / 2, geometry))
      .attr('y', (d) => scaleY(d.value))
      .attr('dy', '-0.5em')
      .attr('visibility', 'hidden')
      .text((d) => format(d.value));
  }
}

class ScatterRenderer implements Renderer<dashboardApi.ScatterData> {
  minY(d: dashboardApi.ScatterData) {
    return d.y;
  }

  maxY(d: dashboardApi.ScatterData) {
    return d.y;
  }

  x(d: dashboardApi.ScatterData) {
    return d.x;
  }

  key(d: dashboardApi.ScatterData) {
    return 'scatter' + d.x + d.y;
  }

  formatXAxisLabel(d: dashboardApi.ScatterData) {
    return '';
  }

  renderPoint(
    data: dashboardApi.ChartData[],
    enter: Selection<dashboardApi.ScatterData>,
    merge: Selection<dashboardApi.ScatterData>,
    scaleY: d3.ScaleLinear<number, number>,
    scaleX: d3.ScaleLinear<number, number>,
    geometry: Geometry
  ) {
    let pointRadius = geometry.pointRadius;

    enter
      .append('circle')
      .attr('class', 'scatter-point')
      .attr('r', pointRadius)
      .attr('stroke', APP_CONFIG.PRIMARY_COLOR)
      .attr('stroke-width', 2)
      .attr('fill', 'transparent');
    merge
      .select('circle.scatter-point')
      .attr('cx', (d) => scaleX(d.x))
      .attr('cy', (d) => scaleY(d.y));

    let format = d3format('.3');

    enter
      .append('text')
      .attr('class', 'label ' + name + '-label')
      .attr('font-size', 12)
      .attr('fill', APP_CONFIG.ACCENT_COLOR);
    merge
      .select('text.' + name + '-label')
      .attr('x', (d) => scaleX(d.x))
      .attr('y', (d) => scaleY(d.y))
      .attr('dx', '0.5em')
      .attr('dy', '0.4em')
      .attr('text-anchor', 'start')
      .attr('visibility', 'hidden')
      .text((d) => format(d.x) + ', ' + format(d.y));
  }
}

function x(offset: number, geometry: Geometry) {
  return (d: dashboardApi.ChartData, index: number) => {
    return snap(groupOffset(d, geometry) + index * getElemWidth(d, geometry) + offset);
  };
}

function renderRadar(svg: Selection<{}>, data: dashboardApi.RadarData[], geometry: Geometry) {
  let size = geometry.height;
  let centerX = geometry.spacing * 2 + size / 2;
  let centerY = geometry.spacing * 2 + size / 2;
  let radius = size / 2;
  let externalRadius = radius + geometry.spacing;
  let step = (Math.PI * 2) / data[0].stats.length; // NOTE: data is never empty

  let x = (factor: number, i: number) => {
    return snap(centerX + factor * Math.cos(i * step - Math.PI / 2));
  };
  let y = (factor: number, i: number) => {
    return snap(centerY + factor * Math.sin(i * step - Math.PI / 2));
  };
  let color = (_: {}, i: number): string => {
    return schemeCategory20c[i % schemeCategory20c.length];
  };
  let polygonPoints = (d: dashboardApi.RadarData, j: number) => {
    return d.stats.map((stat, i) => {
      let point = d.points[stat.id];
      let value = point ? point.value : 0;
      let normValue = point ? point.norm_value : 0;
      let xCoord = x(normValue * radius, i);
      let yCoord = y(normValue * radius, i);

      return { x: xCoord, y: yCoord, color: color(d, j), value };
    });
  };

  let axisUpdate = svg.selectAll('line.axis').data(data[0].stats);
  let axisEnter = axisUpdate.enter().append('line').attr('class', 'axis').attr('stroke', BORDER_COLOR);
  axisUpdate.exit().remove();

  axisUpdate
    .merge(axisEnter)
    .attr('x1', snap(centerX))
    .attr('y1', snap(centerY))
    .attr('x2', (_, i) => x(externalRadius, i))
    .attr('y2', (_, i) => y(externalRadius, i));

  let axisLabelUpdate = svg.selectAll('text.axis').data(data[0].stats);
  let axisLabelEnter = axisLabelUpdate.enter().append('text').attr('class', 'axis');
  axisLabelUpdate.exit().remove();

  axisLabelUpdate
    .merge(axisLabelEnter)
    .attr('text-anchor', (_, i) => (x(externalRadius, i) <= centerX ? 'start' : 'end'))
    .attr('dx', (_, i) => (x(externalRadius, i) <= centerX ? '-2em' : '2em'))
    .attr('dy', (_, i) => (y(externalRadius, i) <= centerY ? '-0.5em' : '1.5em'))
    .attr('x', (_, i) => x(externalRadius, i))
    .attr('y', (_, i) => y(externalRadius, i))
    .text((d) => d.name);

  let gridUpdate = svg.selectAll('circle.radar-grid').data(range(0.1, 1.1, 0.1));
  let gridEnter = gridUpdate
    .enter()
    .append('circle')
    .attr('class', 'radar-grid')
    .attr('fill', 'none')
    .attr('stroke', BORDER_COLOR)
    .attr('stroke-dasharray', '10 10');
  gridUpdate.exit().remove();

  gridUpdate
    .merge(gridEnter)
    .attr('cx', centerX)
    .attr('cy', centerY)
    .attr('r', (d) => d * radius);

  let polygonUpdate = svg.selectAll('g.stat').data(data);
  let polygonEnter = polygonUpdate.enter().append('g').attr('class', 'stat');
  polygonEnter.append('polygon').attr('fill-opacity', '0.2');
  let polygonMerge = polygonUpdate.merge(polygonEnter);
  polygonUpdate.exit().remove();

  polygonMerge
    .select('polygon')
    .attr('points', (d, i) =>
      polygonPoints(d, i)
        .map(({ x, y }) => x.toString() + ',' + y.toString())
        .join(' ')
    )
    .attr('fill', color)
    .attr('stroke', color);

  let selectStat = (index: number) => svg.selectAll('g.stat').filter((_, i) => i === index);
  let statFillOpacity = (opacity: string, textVisibility: string) => {
    return function (_: {}, i: number) {
      let g = selectStat(i);

      g.select('polygon').attr('fill-opacity', opacity);
      g.selectAll('text').attr('visibility', textVisibility);
    };
  };
  polygonEnter.on('mouseover', statFillOpacity('0.5', 'visible'));
  polygonEnter.on('mouseleave', statFillOpacity('0.2', 'hidden'));

  let dotsUpdate = polygonMerge.selectAll('circle').data(polygonPoints);
  let dotsEnter = dotsUpdate.enter().append('circle').attr('r', 5);
  let dotsMerge = dotsUpdate.merge(dotsEnter);
  dotsUpdate.exit().remove();

  dotsMerge
    .attr('cx', (d) => d.x)
    .attr('cy', (d) => d.y)
    .attr('fill', (d) => d.color)
    .attr('stroke', (d) => d.color);

  let format = d3format('.3');

  let valsUpdate = polygonMerge.selectAll('text').data(polygonPoints);
  let valsEnter = valsUpdate.enter().append('text').attr('text-anchor', 'middle');
  valsUpdate.exit().remove();

  valsUpdate
    .merge(valsEnter)
    .attr('dy', (d) => (d.y < centerY ? '-0.5em' : '1.5em'))
    .attr('x', (d) => d.x)
    .attr('y', (d) => d.y)
    .attr('visibility', 'hidden')
    .text((d) => format(d.value));

  let legendUpdate = svg.selectAll('g.legend').data(data);
  let legendEnter = legendUpdate.enter().append('g').attr('class', 'legend');
  let legendMerge = legendUpdate.merge(legendEnter);
  legendUpdate.exit().remove();

  legendEnter.append('rect').attr('width', 50).attr('height', 5);
  legendEnter.append('text');

  legendMerge.attr(
    'transform',
    (d, i) => 'translate(' + snap(geometry.spacing * 2 + 100 + size) + ',' + snap(i * 30) + ')'
  );
  legendMerge.select('rect').attr('x', snap(0)).attr('y', snap(8)).attr('fill', color);
  legendMerge
    .select('text')
    .attr('x', snap(50 + 10))
    .attr('y', snap(15))
    .text((d) => d.names.join(', '));

  let hidden: { [key: number]: boolean } = {};

  legendEnter.on('click', function (d, i) {
    let h = (hidden[i] = !hidden[i]);

    select(this).attr('opacity', h ? '0.5' : '1');
    selectStat(i).attr('visibility', h ? 'hidden' : 'visible');
  });
  legendEnter.on('mouseover', statFillOpacity('0.5', 'visible'));
  legendEnter.on('mouseleave', statFillOpacity('0.2', 'hidden'));
}

function snap(x: number) {
  if ((x | 0) === x) {
    return x + 0.5;
  } else {
    return x;
  }
}
