import { RemoveResult, listParamsToQueryString, makeDefaultApi, requestBulkRemoveEntities } from './request';
import { DimensionData } from './dimensions';

export interface TrialTypeData extends DimensionData {
  allow_disable_sites: boolean;
}

export const { list, retrieve, save, remove } = makeDefaultApi<TrialTypeData>('trial_types');

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/trial_types/bulk_remove/${queryParameters}`);
}
