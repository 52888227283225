import { UserData } from './api/users';
import { Trial } from './models/trial';
import { TrialState } from './models/TrialState';
import { session } from './session';

export function canCreateTemplate(userData: { id?: string; role: string }) {
  return isAtLeastTemplateManager(userData);
}

export function canEditTrial(userData: { id?: string; role: string }, trial: Trial) {
  return trialStateAllowsEditingAny(trial) && trialEditableByUser(userData, trial);
}

export function trialStateAllowsEditingAny(trial: Trial) {
  if (trial.template && trial.state() === TrialState.Approved) {
    return false;
  }

  if (
    !trial.template &&
    [TrialState.DataValidated, TrialState.Archived, TrialState.Canceled, TrialState.Completed].includes(
      trial.state()
    )
  ) {
    return false;
  }

  return true;
}

export function canEditTrialLimited(userData: { id?: string; role: string }, trial: Trial) {
  // Allows limited editing options for restricted managers.
  return (
    trialStateAllowsEditingAny(trial) &&
    (canEditTrial(userData, trial) || (!trial.template && userData.role === 'restricted_manager'))
  );
}

export function canReorderTrialLimitTo(userData: { id?: string; role: string }, trial: Trial): boolean {
  return canEditTrialLimited(userData, trial) && trial.isDraft();
}

export function canEnterObservationsForTrial(
  userData: { id?: string; role: string },
  trial: Trial
): boolean {
  return canEditTrialLimited(userData, trial) && trial.state() === TrialState.Active;
}

export function canAddSites(userData: { id?: string; role: string }, trial: Trial): boolean {
  return canEditTrialLimited(userData, trial);
}

export function canEditTrialLayout(userData: { id?: string; role: string }, trial: Trial): boolean {
  return canEditTrialLimited(userData, trial);
}

export function canExportTrial(trial: Trial): boolean {
  return session.isAtLeastEditor() && !trial.template && trial.isNotDraft();
}

export function canEditLibraryScheduledVisitDays(
  userData: { id?: string; role: string },
  trial: Trial
): boolean {
  return canEditTrialLimited(userData, trial);
}

export function allowViewLimitToDimensionNameAndAttributes() {
  return !session.isRestrictedManager();
}

export function canMakeTrialDraft(userData: UserData, trial: Trial): boolean {
  return (
    (trial.state() === TrialState.Preview || trial.state() === TrialState.Approved) &&
    trialEditableByUser(userData, trial)
  );
}

export function trialEditableByUser(userData: { id?: string; role: string }, trial: Trial): boolean {
  if (userData.role === 'read_only_admin' || (!trial.template && userData.role === 'restricted_manager')) {
    return false;
  }

  if (!trial.template) {
    // otherwise, when it's visible it can be edited
    return true;
  }

  if (!userData) {
    return false;
  }

  if (userData.role === 'admin') {
    return true;
  }

  if (trial.template && userData.role === 'template_editor') {
    return trial.owners().some((owner) => owner.id === userData.id);
  }

  return false;
}

export function canEditCrop() {
  return session.isFieldtrials();
}

export function canEditMeasurementType(userData: { role: string }) {
  return isAtLeastTemplateManager(userData);
}

export function canEditAttributeChoiceList() {
  return isAtLeastTemplateManager(session.tenant());
}

export function canEditCropVariety(userData: { role: string }) {
  return session.isFieldtrials() && isAtLeastTemplateManager(userData);
}

export function canViewCropVariety(userData: { role: string }) {
  return session.isFieldtrials() && (isAtLeastTemplateManager(userData) || session.isReadOnlyAdmin());
}

export function canEditBreeder(userData: { role: string }) {
  return canEditCropVariety(userData);
}

export function canViewBreeder(userData: { role: string }) {
  return canEditCropVariety(userData) || session.isReadOnlyAdmin();
}

export function canViewOrganizations() {
  return session.isAtLeastEditor() || session.isReadOnlyAdmin();
}

export function canViewTrialAnalysis() {
  return session.isAtLeastEditor() || session.isReadOnlyAdmin();
}

export function canEditDimension() {
  return session.isAtLeastEditor() || session.isRestrictedManager();
}

export function canEditMMLibrary() {
  return session.isAtLeastEditor();
}

export function canEditTraitCategory(userData: { role: string }) {
  return isAtLeastTemplateManager(userData);
}

export function canEditTPP() {
  return session.tenant().tpp_enabled && session.isAtLeastEditor();
}

export function canViewTPP() {
  return session.tenant().tpp_enabled && (session.isAtLeastEditor() || session.isReadOnlyAdmin());
}

export function canEditGroup() {
  return session.isAtLeastEditor() || session.isRestrictedManager();
}

export function canEditUsers() {
  return session.isAdmin() || session.isManager() || session.isRestrictedManager() || session.isEditor();
}

export function canInviteUsers() {
  return session.isAdmin() || session.isManager() || session.isRestrictedManager();
}

export function canEditDocumentTags() {
  return session.isAtLeastEditor() || session.isRestrictedManager();
}

export function canEditScheduledVisits() {
  return session.isAtLeastEditor();
}

export function canEditTraitTags() {
  return session.isAtLeastEditor();
}

export function canEditSites() {
  return session.isAtLeastEditor() || session.isRestrictedManager();
}

export function canEditCrops() {
  return session.isFieldtrials() && session.isAtLeastTemplateEditor();
}

export function canEditUnits() {
  return session.isAtLeastEditor();
}

export function canMergeSyncReview() {
  return session.isAtLeastEditor();
}

export function canEditTrialReport() {
  return session.isAtLeastEditor();
}

export function canEditMarketSegments() {
  return session.isAtLeastEditor();
}

export function canEditRegistrationRegions() {
  return session.isAtLeastEditor();
}

export function canEditRegistrationPhases() {
  return session.isAtLeastEditor();
}

export function canEditRegistrations() {
  return session.isFieldtrials() && session.isAtLeastEditor();
}

function isAtLeastTemplateManager(userData: { role: string }) {
  return (
    userData &&
    (userData.role === 'admin' ||
      (session.tenant() &&
        session.tenant().admin_only_data_editing_mode === false &&
        userData.role === 'template_editor'))
  );
}

export function canCreateCountry() {
  return (session.isFieldtrials() && session.isAtLeastEditor()) || (session.isS2bim() && session.isAdmin());
}

export function canCreatePartner() {
  return session.isAtLeastEditor();
}

export function canCreateCustomer() {
  return session.isAtLeastEditor();
}

export function canViewChangelog(entityType: string) {
  return (
    session.isS2bim() &&
    (entityType !== 'country' || (entityType === 'country' && session.isAtLeastReadOnlyAdmin()))
  );
}

export function reportsEnabled() {
  return (
    APP_CONFIG.ENABLE_REPORTS || (session.tenant() && ['niab_3', 'test'].indexOf(session.tenant().slug) >= 0)
  );
}

export function weatherEnabled() {
  return session.tenant() && session.tenant().weather_enabled;
}

export function advancedWeatherEnabled() {
  return session.tenant() && session.tenant().advanced_weather_enabled;
}
