import * as ko from 'knockout';

import { DimensionMeta } from './dimension_meta';
import { Dimension } from './dimension';
import { CountryData } from '../api/countries';
import { RegionData } from '../api/regions';
import { SiteData } from '../api/sites';
import { session } from '../session';
import { AgroRegionData } from '../api/agro_regions';
import { NameData } from '../api/names';
import { GeoJSON } from '../api/datasets';
import { Point } from '../ko_bindings/map';
import pointOnFeature from '@turf/point-on-feature';
import distance from '@turf/distance';

export class Site extends Dimension {
  LOCATION_WARNING_DISTANCE_THRESHOLD_KM = 5;
  country = ko.observable<CountryData>(null).extend({
    serverError: true,
  });
  region = ko.observable<RegionData>(null).extend({
    serverError: true,
  });
  agroRegion = ko.observable<AgroRegionData>(null);
  partner = ko.observable<NameData>(null);
  customer = ko.observable<NameData>(null);
  siteArea = ko.observable<GeoJSON>(null);
  gpsLocation = ko.observable<Point>();
  wasMapModalClosed = ko.observable(false);
  wasGpsLocationWarningClosed = ko.observable(false);
  gpsLocationPointBasedOnSiteArea = ko.pureComputed<Point | null>(() => {
    if (this.siteArea()) {
      const point = pointOnFeature(this.siteArea());
      return {
        lat: point.geometry.coordinates[1],
        lng: point.geometry.coordinates[0],
      };
    }
    return null;
  }, this);

  shouldShowLocationWarning = ko.pureComputed(() => {
    if (!this.siteArea() || !this.gpsLocation()) {
      return false;
    }
    const siteArea = this.siteArea().geometry.coordinates[0] as [number, number][];
    if (
      this.gpsLocationPointBasedOnSiteArea() &&
      this.gpsLocation() &&
      this.siteArea().geometry.coordinates[0] !== undefined &&
      siteArea.length > 0 &&
      siteArea.every((coord) => {
        return (
          distance(
            [this.gpsLocation().lng, this.gpsLocation().lat],
            [coord[0], coord[1]],
            { units: 'kilometers' }
          ) > this.LOCATION_WARNING_DISTANCE_THRESHOLD_KM
        );
      })
    ) {
      return true;
    }
    return false;
  });
  editUrl = ko.pureComputed(() => {
    return '/sites/' + this.id() + '/';
  });
  constructor(data?: SiteData, dimensionMeta?: DimensionMeta) {
    super(data, dimensionMeta);

    let tenant = session.tenant();
    if (tenant && tenant.country_and_region_mandatory_for_site) {
      this.country = this.country.extend({ required: true });
      this.region = this.region.extend({ required: true });
    }

    if (data) {
      this.country(data.country);
      this.region(data.region);
      this.agroRegion(data.agro_region);
      this.partner(data.partner);
      this.customer(data.customer);
      this.siteArea(data.site_area);
      this.gpsLocation(data.gps_location);
    }
    this.gpsLocationPointBasedOnSiteArea.subscribe((newValue) => {
      if (newValue && !this.gpsLocation()) {
        this.gpsLocation(this.gpsLocationPointBasedOnSiteArea());
      }
    });
    this.gpsLocation.subscribe(() => {
      this.wasMapModalClosed(false);
      this.wasGpsLocationWarningClosed(false);
    });
    this.siteArea.subscribe(() => {
      this.wasMapModalClosed(false);
      this.wasGpsLocationWarningClosed(false);
    });
  }

  toData(): SiteData {
    return {
      country: this.country(),
      region: this.region(),
      agro_region: this.agroRegion(),
      partner: this.partner(),
      customer: this.customer(),
      site_area: this.siteArea(),
      gps_location: this.gpsLocation(),
      ...super.toData(),
    };
  }
}
