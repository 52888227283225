import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams, getContentRange } from '../api/request';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListLoaderDelegate, ListLoader, ItemSelectionTracker } from '../components/list_loader';
import { AttributeFilters, DimensionMeta } from '../models/dimension_meta';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as trialTypesApi from '../api/trial_types';
import * as usersApi from '../api/users';
import { canEditDimension } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import { Action } from '../components/basic_widgets';
import { getArchiveAction } from './dimension_records';
import { TrialType } from '../models/trial_type';
import { removeDialog } from '../components/remove_dialog';
import { updateLocationWithQueryString } from '../utils';
import { FilterDelegate } from '../components/list_filters';
import { deflateSingle } from '../api/serialization';

let template = require('raw-loader!../../templates/trial_types.html').default;

interface DefaultFilters {
  name_search?: string;
  allow_disable_sites?: string;
}

type TrialTypeRecordsFilters = DefaultFilters & AttributeFilters;

class TrialTypesScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<trialTypesApi.TrialTypeData, TrialType>
{
  loader: ListLoader<trialTypesApi.TrialTypeData, TrialType>;
  dimensionMeta = ko.observable<DimensionMeta>(null);

  canEdit = false;

  listActions = ko.observableArray<ListHeaderAction>();

  private nameFilter = ko.observable('').extend({ throttle: 300 });
  private allowToDisableSitesFilter = ko.observable<{ id: string; name: string }>();
  searchPlaceholder = i18n.t('Search records')();

  private allowToDisableSitesChoices = [
    { id: '', name: i18n.t('All')() },
    { id: 'true', name: i18n.t('Yes')() },
    { id: 'false', name: i18n.t('No')() },
  ];
  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Allow to disable sites')(),
      choices: this.allowToDisableSitesChoices,
      value: this.allowToDisableSitesFilter,
    },
  ];

  allowItemsPerLoadDisplay = true;
  allowEditFilters = true;

  selectionTracker: ItemSelectionTracker<TrialType>;
  selectedIds = ko.pureComputed(() =>
    this.selectionTracker.selectedItems().map((trial_type) => trial_type.id())
  );
  bulkRemoveTooltipTitle = i18n.t('Delete selected types')();

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: TrialTypeRecordsFilters }) {
    super();

    this.nameFilter(params.filters.name_search || '');
    this.allowToDisableSitesFilter(
      params.filters.allow_disable_sites
        ? this.allowToDisableSitesChoices.find((c) => c.id === params.filters.allow_disable_sites)
        : null
    );

    let mePromise = usersApi.me();
    let dmPromise = dimensionMetasApi.retrieve(dimensionMetasApi.TRIAL_TYPE_SLUG);
    let promise = Promise.all([mePromise, dmPromise]).then(([_, dmData]) => {
      this.canEdit = canEditDimension();
      this.dimensionMeta(new DimensionMeta(dmData));
      this.newFilters.push(...this.dimensionMeta().getAttributeFilters(params.filters));

      if (this.canEdit) {
        this.listActions.push({
          title: i18n.t('Customize')(),
          icon: 'settings',
          href: '/dimensions/' + dimensionMetasApi.TRIAL_TYPE_SLUG + '/',
        });
        this.listActions.push({
          title: i18n.t('Import trial types')(),
          icon: 'file_upload',
          href: '/dimensions/' + dimensionMetasApi.TRIAL_TYPE_SLUG + '/import/',
        });
      }
    });
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<trialTypesApi.TrialTypeData, TrialType>) {
    this.loader = loader;
    this.selectionTracker = new ItemSelectionTracker(this.loader.items);
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
  }

  onRefresh(trialTypes: TrialType[]): void {
    const selectedIds = new Set(this.selectedIds());
    const updatedSelected = trialTypes.filter((c) => selectedIds.has(c.id()));
    this.selectionTracker.selectedItems(updatedSelected);
  }

  fetch(params: ListRequestParams) {
    const filters = this.getFilters();
    updateLocationWithQueryString(filters);
    return trialTypesApi.list({ ...params, ...filters }, undefined, {
      injectTenant: true,
      onHeadersReceived: (headers) => {
        this.selectionTracker.total(getContentRange(headers).total);
      },
    });
  }

  private getFilters() {
    return {
      name_search: this.nameFilter(),
      allow_disable_sites: deflateSingle(this.allowToDisableSitesFilter()),
      ...this.dimensionMeta().getAttributeFilterValuesBySlug(),
    };
  }

  instantiate(data: trialTypesApi.TrialTypeData) {
    return new TrialType(data, this.dimensionMeta());
  }

  remove(id: string) {
    return trialTypesApi.remove(id);
  }

  canRemove(trialType: TrialType) {
    return this.canEdit;
  }

  confirmBulkRemove = () => {
    removeDialog(
      i18n.t('Trial types')().toLocaleLowerCase(),
      [],
      () =>
        trialTypesApi.bulkRemove({
          ...this.getFilters(),
          ...(this.selectionTracker.allSelected() ? {} : { ids: this.selectedIds() }),
          delete_limit: this.selectionTracker.selectedCount(),
        }),
      this.selectionTracker.selectedCount()
    )
      .then(() => {
        this.loader.refresh();
        this.selectionTracker.clear();
      })
      .catch(() => {});
  };

  getActions(trialType: TrialType): Action[] {
    return getArchiveAction(trialType, this);
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let trialTypes = {
  name: 'trial-types',
  viewModel: TrialTypesScreen,
  template: template,
};

ko.components.register(trialTypes.name, trialTypes);
