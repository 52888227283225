import * as ko from 'knockout';

import { ValidationResult } from '../api/request';
import { BaseForm } from '../screens/base_form';
import { DimensionMeta } from '../models/dimension_meta';
import { Dimension, ScheduledApplication } from '../models/dimension';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import * as usersApi from '../api/users';
import { Deferred } from '../utils/deferred';
import { translate } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { canEditDimension } from '../permissions';
import i18n from '../i18n';
import { FormNestedEntitiesConfiguration } from './form_nested_entities';
import { getCropSearchConfig } from '../components/configs/search_configs';
import { session } from '../session';

let template = require('raw-loader!../../templates/components/base_dimension_record_edit.html').default;

export interface BaseDimensionRecordEditDelegate<
  TData extends dimensionsApi.DimensionData,
  T extends Dimension
> {
  dimensionMetaId: string;
  dimensionId: string;
  dimension: KnockoutObservable<T>;

  disableApplications?: boolean;
  allowAnonymize: boolean;
  allowLinkingCrops: boolean;
  anyoneCanEdit: boolean;
  fetch(dimensionMetaId: string, id: string): Promise<TData>;
  instantiate(data: TData, dimensionMeta: DimensionMeta): T;
  saveRequest(data: TData): Promise<ValidationResult>;
  addAnother?(data: TData, dm: DimensionMeta): void;

  onBeforeSave?(form: BaseForm<TData>): void;
  onRemoteValidation?(form: BaseForm<TData>, result: ValidationResult): void;

  anonymizedCodeText?: string;
}

class BaseDimensionRecordEdit<
  TData extends dimensionsApi.DimensionData,
  T extends Dimension
> extends BaseForm<TData> {
  private delegate: BaseDimensionRecordEditDelegate<TData, T>;

  dimensionMeta = ko.observable<DimensionMeta>(null);

  name = ko.pureComputed(() => {
    if (!this.dimensionMeta()) {
      return '';
    }

    return translate(this.dimensionMeta().nameJson());
  });
  canEdit = ko.observable(false);
  anonymizedCodeText: string;
  onNameOverrideChange?: (name: string) => void;
  nameOverrideFeatureEnabled = session.tenant() && session.tenant().name_override_enabled;

  applications = ko.observableArray<ScheduledApplication>();

  cropSearchConfig = ko.pureComputed(() => {
    return getCropSearchConfig(this.delegate.dimension().crops);
  });

  applicationsConfig: FormNestedEntitiesConfiguration<ScheduledApplication> = {
    title: i18n.t(['scheduled_applications_colon', 'Scheduled applications:']),
    addTitle: i18n.t('Add scheduled application'),
    missingTitle: i18n.t('No input selected'),

    entities: ko.computed(() => this.applications() ?? []),

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible
    disabled: () => false,
    disable: () => {},

    canRemove: () => true,

    add: () => {
      let scApp = new ScheduledApplication();
      this.applications.push(scApp);
      return scApp;
    },

    remove: (entity) => {
      entity.dispose();
      this.applications.remove(entity);
    },

    hasErrors: (entity) => {
      return entity.hasErrors();
    },

    showErrors: (entity) => {
      entity.errors.showAllMessages();
    },

    actions: [],

    getSummaryName: (entity) => {
      let name = '';

      const inputDimension = entity.inputDimension();
      if (inputDimension) {
        name = translate(inputDimension.name_json);

        const dosage = parseFloat(entity.dosage());
        if (!isNaN(dosage)) {
          name += ' ' + dosage.toLocaleString() + ' ';
          name += entity.unit()?.name ?? '';
        }
        const days = parseInt(entity.daysOffset(), 10);
        const mm = entity.baseDate();
        const after = mm ? translate(mm.name_json) : i18n.t('planting/reference date')();
        if (!isNaN(days)) {
          name += `, ${days} ${i18n.t('days after')()} ${after}`;
        }
      }

      return name;
    },
  };

  editText = ko.pureComputed(() => i18n.t('Edit {{ name }}', { name: this.name() })());
  createText = ko.pureComputed(() => i18n.t('Create {{ name }}', { name: this.name() })());
  nameOverride = ko.observable<string>('');

  constructor(
    params: {
      delegate: BaseDimensionRecordEditDelegate<TData, T>;
      onNameOverrideChange?: (name: string) => void;
      nameOverride?: string;
      result?: Deferred<TData>;
    },
    private componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.delegate = params.delegate;
    this.onNameOverrideChange = params.onNameOverrideChange;
    this.nameOverride(params.nameOverride);

    this.anonymizedCodeText =
      params.delegate.anonymizedCodeText || i18n.t('Code to use when partially anonymized')();

    let mePromise = usersApi.me();
    let dimensionMetaPromise = dimensionMetasApi.retrieve(this.delegate.dimensionMetaId);
    let dimensionPromise = this.delegate.dimensionId
      ? this.delegate.fetch(this.delegate.dimensionMetaId, this.delegate.dimensionId)
      : undefined;

    let promise = Promise.all([mePromise, dimensionMetaPromise, dimensionPromise]).then(
      ([userData, dimensionMetaData, dimensionData]) => {
        this.canEdit(this.delegate.anyoneCanEdit || canEditDimension());
        this.dimensionMeta(new DimensionMeta(dimensionMetaData));
        this.delegate.dimension(this.delegate.instantiate(dimensionData, this.dimensionMeta()));

        if (dimensionData?.applications) {
          const applications = dimensionData.applications.slice();
          applications.sort((a, b) => {
            const aDays = parseInt((a.estimated_days_after_planting ?? a.days_offset)?.toString(), 10);
            const bDays = parseInt((b.estimated_days_after_planting ?? b.days_offset)?.toString(), 10);

            if (aDays == null && bDays == null) {
              return 0;
            }
            if (aDays == null) {
              return -1;
            }
            if (bDays == null) {
              return 1;
            }

            return aDays - bDays;
          });
          this.applications(applications.map((data) => new ScheduledApplication(data)));
        }
      }
    );
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  dispose() {
    this.applications().forEach((a) => a.dispose());
  }

  canAddAnother = ko.pureComputed(() => {
    return !this.delegate.dimension().id() && !!this.delegate.addAnother;
  });

  save = () => {
    if (this.onNameOverrideChange) {
      this.onNameOverrideChange(this.nameOverride());
    }

    this.doSave();
  };

  saveAndAddAnother = () => {
    this.doSave((data) => {
      if (this.delegate.addAnother) {
        this.delegate.addAnother(data, this.dimensionMeta());
        this.focusFirst(this.componentInfo.element);
      }
    });
  };

  private doSave(onClose?: (data: TData) => void) {
    if (!this.delegate.dimension()) {
      return;
    }

    for (let attribute of this.delegate.dimension().attributes()) {
      attribute.value.serverError(null);
    }

    if (this.delegate.onBeforeSave) {
      this.delegate.onBeforeSave(this);
    }

    let scAppError = false;
    for (let scApp of this.applications()) {
      if (scApp.hasErrors()) {
        scApp.showErrors();
        scAppError = true;
      }
    }

    if (!scAppError && this.validateLocal(this.delegate.dimension)) {
      let data = {
        applications: this.applications().map((app) => app.toData()),
        ...this.delegate.dimension().toData(),
      };
      this.executeSaveRequest(this.delegate.saveRequest(<TData>data)).then((validation) => {
        this.onRemoteValidation(<TData>data, this.delegate.dimension(), validation, onClose);

        if (this.delegate.onRemoteValidation) {
          this.delegate.onRemoteValidation(this, validation);
        }

        if (!validation.isValid) {
          for (let attribute of this.delegate.dimension().attributes()) {
            let errors = validation.errors[attribute.getAttrName()];

            if (errors) {
              this.setServerError(attribute.value, errors);
            }
          }
        }
      });
    }
  }
}

ko.components.register('base-dimension-record-edit', {
  viewModel: createWithComponent(BaseDimensionRecordEdit),
  template: template,
});
